//Breakpoints
@mixin respond-to($media) {
  @if $media == phones {
    @media only screen and (max-width: 575px) { @content; }
  }
  @else if $media == handhelds {
    @media only screen and (min-width: 576px) { @content; }
  }
  @else if $media == wide-handhelds {
    @media only screen and (min-width: 768px) { @content; }
  }
  @else if $media == tablets {
    @media only screen and (min-width: 992px) { @content; }
  }
  @else if $media == desktop {
    @media (min-width: 1200px) { @content; }
  }
  @else if $media == desktop-xl {
    @media (min-width: 1560px) { @content; }
  }
}
//placeholder
@mixin optional-at-root($sel){
  @at-root #{if(not &, $sel, selector-append(&, $sel))}{
    @content
  }
}
@mixin placeholder{
  @include optional-at-root('::-webkit-input-placeholder'){
    @content
  }
  @include optional-at-root(':-moz-placeholder'){
    @content
  }
  @include optional-at-root('::-moz-placeholder'){
    @content
  }
  @include optional-at-root(':-ms-input-placeholder'){
    @content
  }
}

//reset ul tag
@mixin reset($type){
  $resets: (
    ul: (
      list-style: none,
      margin: 0,
      padding: 0,
    )
  );
  @each $prop, $value in map-get($resets, $type) {
    @if type-of($value) == 'map' {
      & > #{$prop} {
        @each $sub-prop, $sub-value in $value {
          #{$sub-prop}: $sub-value;
        }
      }
    } @else {
      #{$prop}: $value;
    }
  }
}
// @include reset(ul);


// transition
@mixin transition{
  transition: all .3s ease;
}

@mixin backdrop {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
                  to bottom,
                  rgba($default-color, 0.3) 0%,
                  rgba($default-color, 0.3) 70%,
                  rgba($default-color, 0) 100%
  );
}