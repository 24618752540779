.seller-photo-section {
  padding: 30px 0;
  @include respond-to(wide-handhelds) {
    padding: 104px 0;
  }
}

.seller-photos-list {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond-to(wide-handhelds) {
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }

  img {
    width: 400px;
    height: 303px;
    margin: 5px;
  }
}
.seller-photo__item {
  position: relative;
  flex: 0 0 100%;

  @include respond-to(wide-handhelds) {
    flex: 0 0 32.8%;
  }
  &:nth-of-type(2) {
    @include respond-to(wide-handhelds) {
      z-index: 2;
    }
  }
  & + & {
    margin-top: 15px;
    @include respond-to(wide-handhelds) {
      margin-top: 0;
    }
  }
}

.seller-products-section {
  padding: 30px 0;
  @include respond-to(wide-handhelds) {
    padding: 80px 0 70px;
  }
}

.seller-products__content {
  @include respond-to(wide-handhelds) {
    width: 85%;
    margin: 0 auto;
    align-items: center;
  }
}
.seller-products__text {
  text-align: center;

  .title {
    font-size: 40px;
    line-height: 1.2;
    font-weight: $font-bold;
    margin: 0 0 20px;
    @include respond-to(wide-handhelds) {
      font-size: 48px;
    }
  }
  .subtitle {
    font-size: 26px;
    line-height: 1.2;
    margin: 0 0 20px;
    @include respond-to(wide-handhelds) {
      font-size: 30px;
      margin: 0 0 40px;
    }
  }
  .btn {
    display: block;
    max-width: 360px;
    margin: 0 auto;
    font-size: 14px;
    height: 60px;
    line-height: 60px;
  }
}

.promoMessage {
  font-size: 18px;
}
