$lead-color: #b91828;

.blog-placeholder {
  .blog-placeholder-image {
    background-image: url(/assets/images/blog_placeholder.jpg);
    background-size: cover;
    height: 100vh;

    .blog-placeholder-label {
      font-size: 28px;
      letter-spacing: 1px;
      color: white;
      padding: 80px;
    }
  }
}

.blog-section {
  margin-top: 10vh;
  margin-bottom: 40vh;
}

.container {
  .paginationjs-next {
    font-weight: 800;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 12px;
    margin-right: 12px;
    font-size: 21px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
    }
  }

  .paginationjs-prev {
    font-weight: 800;
    padding-top: 0;
    margin-left: 12px;
    margin-right: 12px;
    padding-bottom: 0;
    font-size: 21px;
    cursor: pointer;

    &.disabled {
      opacity: 0.5;
    }
  }

  .paginationjs-page {
    margin-top: 4px;
  }

  .stories-block {
    @include respond-to(phones) {
      width: auto !important;
      margin-bottom: 45px;
    }
  }

  .inspiration-block {
    @include respond-to(phones) {
      margin-bottom: 40px;
    }
  }

  .post-slider {
    padding: 30px;

    .post-slider-item {
      min-width: 50px;
      min-height: 250px;
      margin: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      text-decoration: none;

      img {
        min-height: 222px;
        object-fit: cover;
        max-height: 222px;
        width: 100% !important;
      }
    }

    .slick-slide {
      text-align: center;
    }
  }

  .row-divider {
    border: 0.25px dashed rgba(0, 0, 0, 0.25);
  }

  .blog-section {
    .container {
      text-align: center;
    }
  }

  .blog-post-meta {
    width: 70%;
    margin: 0 auto;
    margin-bottom: 18px;

    .meta-date {
      font-weight: 300;
      margin-bottom: 20px;
      font-family: 'Lato';
    }

    .meta-description {
      padding-top: 24px;
      font-size: 16px;
    }
  }

  .blog-post-content p {
    margin-bottom: 10px !important;

    span {
      font-family: 'Freigeist-WideRegular' !important;
    }
  }

  .blog-post-content p:last-child img {
    object-fit: contain;
    margin-top: -80px;
    margin-bottom: -100px;

    @include respond-to(phones) {
      height: 250px;
      margin-top: -40px;
      margin-bottom: -40px;
    }
  }

  .blog-post-content p:last-child img {
    object-fit: contain;
    margin-top: -80px;
    margin-bottom: -100px;

    @include respond-to(phones) {
      height: 250px;
      margin-top: -40px;
      margin-bottom: -40px;
    }
  }

  .blog-post-content p:last-child iframe {
    width: 100%;

    @include respond-to(phones) {
      height: 250px;
    }
  }

  .blog-post-footer {
    margin-top: 42px;
    text-align: center;

    .related-posts {
      margin-top: 42px;

      .related-posts-label {
        text-transform: uppercase;
        text-align: left;
      }

      .related-stories {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .blog-post-nav {
      margin-top: 42px;
      text-align: center;

      .nav-row {
        @include respond-to(phones) {
          display: flex;
          flex-direction: column;
        }
        margin-top: 18px;
        margin-bottom: 18px;
      }

      .nav-row-item {
        margin-right: 24px;
        margin-left: 24px;
        text-decoration: none;
        color: $lead-color;
        font-weight: 600;
        font-size: 18px;
        text-transform: uppercase;
      }
    }
  }

  .blog-post-footer-button {
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    background-color: $lead-color;
    padding-right: 70px;
    color: white;
    padding-left: 70px;
    padding-top: 18px;
    padding-bottom: 18px;
    text-transform: uppercase;
  }

  .see-more-area {
    text-align: center;
    margin-top: 32px;

    .see-more-blogs {
      border-radius: 4px;
      text-align: center;
      text-decoration: none;
      background-color: $secondary-color;
      padding-right: 72px;
      color: white;
      padding-left: 72px;
      padding-top: 6px;
      padding-bottom: 6px;
      text-decoration: none;
    }
  }

  .post-item-title {
    padding-top: 20px;
    font-size: 14px;
    font-weight: 500;
  }

  .post-item-date {
    font-size: 10px;
    padding-top: 8px;
    font-weight: 300;
    padding-bottom: 20px;
    font-family: 'Lato';
  }

  .blog-divider {
    border: 0;
    border-top: 2.5px solid $lead-color;
  }

  .sub-label {
    display: flex;
  }

  .sub-cat-heading-green {
    color: $lead-color;
    font-weight: 600;
  }

  .sub-cat-heading {
    text-transform: uppercase;
    font-weight: 600;
    margin-right: 4px;
  }

  .sub-cat-heading-add {
    margin-right: 4px;
  }

  .sub-cats-content {
    margin-left: 10%;
    margin-right: 10%;
  }

  .blog-list-container {
    margin-left: 3%;
    margin-right: 3%;
  }

  .main-label {
    padding-top: 42px;
    margin-bottom: 18px;
    text-align: center;
    font-size: 32px;
    letter-spacing: 2px;
    color: #b81927;
    margin-bottom: 64px;
    text-transform: lowercase;
  }

  .post-all-list {
    @include respond-to(phones) {
      display: flex;
      flex-direction: column;
    }

    @include respond-to(desktop) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  .post-block {
    @include respond-to(phones) {
      margin: 0 auto;
      margin-bottom: 3%;
      margin-top: 3%;
      width: 100%;
      min-height: 200px;
      text-decoration: none;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    @include respond-to(desktop) {
      width: 21.33333%;
      margin-right: 1.5%;
      margin-left: 1.5%;
      margin-bottom: 1.5%;
      min-height: 200px;
      margin-top: 1.5%;
      text-decoration: none;
      text-align: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  .sub-main-label {
    text-align: center;
    letter-spacing: 2px;
    font-size: 21px;
    margin-bottom: 24px;
    color: rgb(80, 80, 80);
  }

  .paginationjs-pages {
    text-align: center;

    .posts-page-navigation {
      display: inline-block;
      list-style: none;
    }

    .posts-page-navigation > li {
      color: black;
      float: left;
      padding: 4px 8px;
      text-decoration: none;

      &.active {
        color: $lead-color;

        border-radius: 50%;
        border: 1px solid $lead-color;
      }
    }

    .posts-page-navigation a {
      text-decoration: none;
    }
  }

  .blog-main-nav {
    @include respond-to(phones) {
      display: flex;
      flex-direction: column;
    }

    @include respond-to(handhelds) {
      display: flex;
      flex-direction: column;
    }

    @include respond-to(desktop) {
      display: flex;
      justify-content: center;
      flex-direction: initial;
    }

    .nav-link {
      @include respond-to(phones) {
        margin: 10px;
      }

      text-decoration: none;

      .nav-item {
        min-width: 225px;
        min-height: 325px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background: #fff;
        text-align: center;
        border-radius: 10px;
        margin-left: 15px;
        margin-right: 15px;

        .nav-circle {
          position: relative;

          .blog-nav-img-text {
            text-shadow: 5px 1px 4px black, 0 0 1em;
            position: absolute;
            color: white;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 20px;
            font-weight: 600;
            text-transform: lowercase;
          }

          .blog-nav-img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            @include respond-to(phones) {
              width: 100%;
              min-height: auto;
            }

            @include respond-to(handhelds) {
              width: 100%;
              min-height: auto;
            }

            width: 338px;
            height: 241px;
            object-fit: cover;

            &:hover {
              filter: brightness(50%);
            }
          }
        }
      }
    }
  }
}
