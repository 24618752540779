.upload-group--banner {
  flex: 0 0 100%;
  max-width: 100%;
}
.upload-product-form {
  .input-field--switch {
    label {
      margin: 0;
    }
  }
}

.promo-switch {
  display: flex;
  justify-content: flex-end;
}
