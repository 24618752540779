.regions-list-section {
  padding: 50px 0 15px;

  .content__title {
    font-weight: normal;
    text-transform: lowercase;

    @include respond-to(wide-handhelds) {
      font-size: 24px;
    }
  }

  .regions-box {
    @include respond-to(tablets) {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(5, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      margin: 0;

      .region__item--1 {
        grid-area: 1 / 1 / 3 / 3;
      }

      .region__item--2 {
        grid-area: 1 / 3 / 2 / 5;
      }

      .region__item--3 {
        grid-area: 1 / 5 / 4 / 6;
      }

      .region__item--4 {
        grid-area: 2 / 3 / 4 / 5;
      }

      .region__item--5 {
        grid-area: 3 / 1 / 4 / 3;
      }

      .region__item--full {
        grid-area: 4 / 1 / 6 / 6;
      }
    }

    &--france {
      @include respond-to(tablets) {
        grid-template-rows: repeat(6, 1fr);

        .region__item--1 {
          grid-area: 1 / 1 / 3 / 3;
        }

        .region__item--2 {
          grid-area: 1 / 3 / 2 / 5;
        }

        .region__item--3 {
          grid-area: 1 / 5 / 4 / 6;
        }

        .region__item--4 {
          grid-area: 2 / 3 / 4 / 5;
        }

        .region__item--5 {
          grid-area: 3 / 1 / 4 / 3;
        }

        .region__item--6 {
          grid-area: 4 / 1 / 6 / 2;
        }

        .region__item--7 {
          grid-area: 4 / 2 / 5 / 4;
        }

        .region__item--8 {
          grid-area: 5 / 2 / 6 / 4;
        }

        .region__item--9 {
          grid-area: 4 / 4 / 6 / 6;
        }

        .region__item--10 {
          grid-area: 6 / 1 / 8 / 4;
        }

        .region__item--11 {
          grid-area: 6 / 4 / 8 / 6;
        }

        .region__item--full {
          grid-area: 8 / 1 / 10 / 6;
        }
      }
    }

    &--germany {
      @include respond-to(tablets) {
        grid-template-rows: repeat(6, 1fr);

        .region__item--1 {
          grid-area: 1 / 1 / 3 / 3;
        }

        .region__item--2 {
          grid-area: 1 / 3 / 2 / 5;
        }

        .region__item--3 {
          grid-area: 1 / 5 / 4 / 6;
        }

        .region__item--4 {
          grid-area: 2 / 3 / 4 / 5;
        }

        .region__item--5 {
          grid-area: 3 / 1 / 4 / 3;
        }

        .region__item--6 {
          grid-area: 4 / 1 / 6 / 2;
        }

        .region__item--7 {
          grid-area: 4 / 2 / 5 / 4;
        }

        .region__item--8 {
          grid-area: 5 / 2 / 6 / 4;
        }

        .region__item--9 {
          grid-area: 4 / 4 / 6 / 6;
        }

        .region__item--full {
          grid-area: 6 / 1 / 8 / 6;
        }
      }
    }

    &--spain {
      @include respond-to(tablets) {
        grid-template-rows: repeat(9, 1fr);

        .region__item--1 {
          grid-area: 1 / 1 / 3 / 3;
        }

        .region__item--2 {
          grid-area: 1 / 3 / 2 / 5;
        }

        .region__item--3 {
          grid-area: 1 / 5 / 4 / 6;
        }

        .region__item--4 {
          grid-area: 2 / 3 / 4 / 5;
        }

        .region__item--5 {
          grid-area: 3 / 1 / 4 / 3;
        }

        .region__item--6 {
          grid-area: 4 / 1 / 6 / 2;
        }

        .region__item--7 {
          grid-area: 4 / 2 / 5 / 4;
        }

        .region__item--8 {
          grid-area: 5 / 2 / 6 / 4;
        }

        .region__item--9 {
          grid-area: 4 / 4 / 6 / 6;
        }

        .region__item--10 {
          grid-area: 6 / 1 / 8 / 3;
        }

        .region__item--11 {
          grid-area: 6 / 3 / 8 / 4;
        }

        .region__item--12 {
          grid-area: 6 / 4 / 8 / 6;
        }

        .region__item--null {
          grid-area: 8 / 1 / 10 / 3;
        }

        .region__item--full {
          grid-area: 8 / 3 / 10 / 6;
        }


      }
    }

    &--italy {
      @include respond-to(tablets) {
        grid-template-rows: repeat(11, 1fr);

        .region__item--1 {
          grid-area: 1 / 1 / 3 / 3;
        }

        .region__item--2 {
          grid-area: 1 / 3 / 2 / 5;
        }

        .region__item--3 {
          grid-area: 1 / 5 / 4 / 6;
        }

        .region__item--4 {
          grid-area: 2 / 3 / 4 / 5;
        }

        .region__item--5 {
          grid-area: 3 / 1 / 4 / 3;
        }

        .region__item--6 {
          grid-area: 4 / 1 / 7 / 2;
        }

        .region__item--7 {
          grid-area: 4 / 2 / 6 / 4;
        }

        .region__item--8 {
          grid-area: 6 / 2 / 7 / 4;
        }

        .region__item--9 {
          grid-area: 4 / 4 / 5 / 6;
        }

        .region__item--10 {
          grid-area: 5 / 4 / 7 / 6;
        }

        .region__item--11 {
          grid-area: 7 / 1 / 8 / 3;
        }

        .region__item--12 {
          grid-area: 8 / 1 / 10 / 3;
        }

        .region__item--13 {
          grid-area: 7 / 3 / 10 / 4;
        }

        .region__item--14 {
          grid-area: 7 / 4 / 9 / 6;
        }

        .region__item--15 {
          grid-area: 9 / 4 / 10 / 6;
        }

        .region__item--full {
          grid-area: 10 / 1 / 12 / 6;
        }
      }
    }
  }

  .region__item {
    @include respond-to(tablets) {
      margin: 0;
      padding: 0;
    }
  }

  .region__inner {
    text-shadow: 5px 1px 4px black, 0 0 1em;
    height: 100%;
    border-radius: 10px;
  }
}