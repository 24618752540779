.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: background 0.3s ease;
  background: transparent;
  font-family: 'Freigeist-WideRegular';

  &--fixed {
    background: #fffdfa;
    color: $default-color;

    @include respond-to(desktop) {
      background: #fffdfa;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 45px;

    @include respond-to(tablets) {
      min-height: 70px;
      padding: 0 80px;
    }

    @include respond-to(desktop) {
      padding: 0 0;
    }
  }
}

.nav__login {
  background: #211e4a;
  border-radius: 20px;
  width: 174px;
  height: 37px;
  text-align: center;
  font-size: 13px;
}

.nav__login-text {
  color: white !important;
  line-height: 34px !important;
}

.nav__login-link {
  text-transform: uppercase;
}

.header__logo {
  flex: 0 0 30%;
  font-size: 18px;

  @include respond-to(tablets) {
    font-size: 30px;
  }

  @include respond-to(phones) {
    left: 0 !important;
    font-size: initial;
    flex: none;
    padding-left: 0;
  }

  a {
    display: flex;
    text-decoration: none;
    text-align: center;
  }

  img {
    height: 50px;

    @include respond-to(phones) {
      height: 40px;
    }
  }
}

.welcome-to-winejump {
  color: white;
  font-size: 20px;
  font-weight: 100;
  padding-left: 20px;
  max-width: 75vw;
}

.header__logo--center {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.header__logo-mobile {
  padding-top: 16px;
  padding-left: 37px;
}

.header__bottom-mobile {
  display: flex;
  justify-content: space-between;
  padding-block-start: 15%;
}

.header__login-button {
  font-size: 20px !important;
  color: #211e4a !important;
  justify-content: center !important;
  width: 50vw !important;
}

.seller-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__nav-list {
  @include reset(ul);
  display: flex;
  align-items: center;
  margin: 0 -7px;

  @include respond-to(desktop) {
    margin: 0 -20px;
  }

  .header__nav-item--main {
    color: #211e4a;
    display: none;

    @include respond-to(desktop) {
      display: block;
    }

    &>a {
      white-space: nowrap;
      text-transform: uppercase;
    }
  }
}

.header__nav-item {
  position: relative;
  font-size: 12px;
  transition: all 0.15s ease;

  &:not(:last-of-type) {
    opacity: 0;
    visibility: hidden;
  }

  .header--fixed & {
    opacity: 1;
    visibility: visible;
  }

  @include respond-to(wide-handhelds) {
    font-size: 16px;
    line-height: 50px;
  }

  &>a,
  &>span {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 10px;
    text-decoration: none;

    @include respond-to(tablets) {
      padding: 0 20px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 10px;
      margin: 0 0 -15px;
      background: $secondary-color;
      opacity: 0;
      visibility: hidden;
      @include transition;
    }

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  &--cart {
    svg {
      fill: $default-color;
      width: 24px;
      height: 24px;
    }

    .cart-amount {
      font-size: 12px;
      width: 32px;

      @include respond-to(desktop) {
        width: 32px !important;
      }

      @include respond-to(desktop-xl) {
        width: 32px !important;
      }

      @include respond-to(wide-handhelds) {
        display: inline-block;
        padding: 0;
        font-size: 12px;
      }
    }
  }

  &--signup {
    color: $secondary-color;
    font-weight: $font-bold;
    text-align: right;

    @include respond-to(handhelds) {
      text-align: left;
    }
  }

  &--login {
    font-size: 0;
    transition: none;

    @include respond-to(wide-handhelds) {
      font-size: inherit;
      text-transform: uppercase;
      font-weight: 700;
      line-height: 1;

      a {
        flex-direction: column;
      }

      small {
        font-weight: 400;
        font-size: 65%;
      }
    }

    @include respond-to(tablets) {
      font-size: 24px;
    }

    .header--fixed & {
      color: $default-color;

      span {
        color: $primary-btn-bg-color;
        opacity: 0.8;
      }
    }

    svg {
      fill: $default-color;
      display: block;

      @include respond-to(wide-handhelds) {
        display: none;
      }
    }
  }

  &--search {
    a {

      &:hover,
      &:focus {
        &:before {
          display: none;
        }
      }
    }
  }

  i {
    font-style: normal;
  }
}

.header-profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc;

  @include respond-to(desktop) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    width: 40px;
    height: 40px;
  }
}

.header__nav-list--menu-desktop {
  @include respond-to(tablets) {
    display: none;
  }

  @include respond-to(desktop) {
    display: flex;
  }
}

.header__nav-list--controls {
  margin-left: auto;

  @include respond-to(desktop) {
    margin-left: 0;
  }
}

.header__nav-list--controls {

  .header__nav-item--language,
  .header__nav-item--seller-menu {
    display: none;

    @include respond-to(desktop) {
      display: list-item;
    }
  }
}

.header__nav-item--language {
  margin-bottom: 50px;

  @include respond-to(desktop) {
    margin-bottom: 0;
  }
}

.header__nav--menu-mobile {
  @include respond-to(tablets) {
    position: relative;
  }

  @include respond-to(desktop) {
    display: none;
  }
}

.toggle--hamburger {
  display: block;
  padding-left: 10px;

  @include respond-to(tablets) {
    padding-left: 20px;
  }
}

.icon--toggle-hamburger {
  display: block;
  width: 30px;
  fill: black;

  .top-line,
  .bottom-line {
    transition: all 0.3s linear;
  }

  &.open {
    .top-line {
      transform: rotate(45deg) translate(2px, -9px);
      fill: white;
    }

    .bottom-line {
      transform: rotate(-45deg) translate(-24px, -2px);
      fill: white;
    }
  }
}

.header__nav-item--seller-menu {
  ul {
    background-color: #211e4a;

    @include respond-to(desktop) {
      background-color: #fff;
    }
  }

  .dropdown__item {
    a {
      color: #fff !important;

      @include respond-to(phones) {
        text-align: right;
      }

      @include respond-to(desktop) {
        color: $default-color !important;
      }
    }
  }
}