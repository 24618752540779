.login-container {
  position: relative;
  border-bottom: 1px solid $border-color;
  overflow: hidden;

  @include respond-to(tablets) {
    &:before {
      content: '';
      position: absolute;
      left: -55%;
      top: 0;
      width: 100%;
      height: 100%;
      transform: skew(-30deg);
      background-color: $primary-color;
      z-index: 2;
    }
    &:after {
      @include backdrop;
    }
  }
}

.testimonial {
  margin-bottom: 13rem;
}

.box-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include respond-to(tablets) {
    flex-direction: row;
  }
}

.box {
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-height: 400px;
  max-width: 576px;
  margin: 0 auto;
  background: #fff;

  @include respond-to(tablets) {
    max-width: none;
    width: 48%;
    margin: 0;
    max-height: 400px;
  }
}

.box-1 {
  margin-bottom: 10rem;

  @include respond-to(tablets) {
    margin-bottom: 0;
  }
}

.box-header {
  display: flex;
  position: relative;
  margin-top: 7px;
  color: #211e4a;
  padding-left: 8px;
}

.box-header--img {
  height: 76px;
  width: 76px;
}

.box-header--img-2 {
  position: absolute;
  width: 31px;
  bottom: 18px;
  left: 30px;
}

.box-header--img-2-red {
  position: absolute;
  width: 51px;
  bottom: 16px;
  left: 20px;
}

.box-header--title {
  padding-left: 30px;
  font-size: 30px;
  text-transform: uppercase;

  @include respond-to(phones) {
    font-size: 17px;
    padding-left: 15px;
  }

  p {
    margin-bottom: 0;
    @include respond-to(phones) {
      margin-top: 45px;
    }
  }
}

.box-header--text {
  padding: 13px;
  color: white;
  background: #211e4a;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 8px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  hr {
    margin: 14px 0;
  }
}

.box-header--text::-webkit-scrollbar-track {
  padding: 2px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 4px;
}

.box-header--text::-webkit-scrollbar {
  width: 7px;
}

.box-header--text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e6e3e3;
}

.box-header--text-content {
  p:last-child {
    font-size: 12px;
    font-style: italic;
  }
}

.box-header--text-title {
  font-size: 20px;
  text-transform: uppercase;

  @include respond-to(phones) {
    font-size: 17px;
  }
}

.login-section {
  margin: 7rem 0 6rem 0;
}

.login-right__bg {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  //width: 100%;
  //height: 100%;
  //object-fit: cover;
  @include respond-to(tablets) {
    display: block;
  }
}

.login__col {
  &--form {
    flex-grow: 1;
    position: relative;
    padding: 0;
    z-index: 2;
    text-align: left;
    margin: 45px 0 25px 0;

    @include respond-to(tablets) {
      display: flex;
      align-items: center;
      flex-basis: 33%;
      margin: 0;
    }

    .login__form-content {
      @include respond-to(tablets) {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
      }
    }

    .login__title {
      text-align: center;
    }
  }

  &--image {
    position: relative;
    z-index: 1;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include respond-to(tablets) {
        display: none;
      }
    }
  }
}

.profile-absence {
  font-size: 18px;
  margin: 60px auto 20px auto;
  text-align: center;
}

.login__title {
  font-size: 36px;
  line-height: 1.2;
  font-weight: $font-bold;
  margin-bottom: 25px;
  text-align: center;
}
.login-form {
  .input-field {
    margin-bottom: 30px;
  }
  label {
    color: $text-color;
  }
}
.login__form-content {
  .form__link {
    padding: 30px 0 0;
    color: $default-color;
    text-transform: uppercase;
    text-align: center;
  }
}

.login__image-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  color: #fff;
  font-size: 18px;
  line-height: 1.333333;
  @include respond-to(tablets) {
    align-items: flex-end;
    text-align: right;
    padding: 0;
  }

  h2 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px;
    @include respond-to(desktop) {
      font-size: 48px;
    }
  }

  p {
    margin: 0 0 30px;
  }
}

.skew-items-section--login {
  .skew__item-info {
    &:before {
      transform: skew(-30deg);
    }
  }

  .skew__item-info {
    @include respond-to(desktop) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
