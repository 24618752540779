#smallBoxes {
  margin-left: 9px;
}

#cross {
  &:hover {
    cursor: pointer;
  }
}

form.confirm-status-modal {
  svg {
    padding-top: 10px;
    width: 10px;
    margin-left: 5px;
  }
  select {
    max-width: 150px;
    height: 47px;
  }

  .input-field {
    height: 40px;

    &.error {
      &:before {
        top: 50px;
        left: 150px;
      }
    }
  }

  #timeError {
    display: none;
    margin-left: 15px;
    &.error {
      color: #ff0000;
      display: block;
    }
  }
}

.order-table-btn {
  display: inline-flex;
  height: auto !important;
  padding: 10px 32px;
  align-items: center;
  justify-content: center;
  line-height: 1.5 !important;
}

.pickup {
  max-width: 170px;
}

.reorder-form {
  display: flex;
  justify-content: center;
}
