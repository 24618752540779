.wine-trip-title {
  margin-bottom: 15px;
  font-size: 24px;
  text-align: center;
  text-transform: lowercase;
}

.center-menu-trips {
  text-align: center;
  margin: auto;

  .trip-row {
    display: flex;
    justify-content: center;

    @include respond-to(phones) {
      display: flex;
      flex-direction: column;
    }

    .trip-element {
      text-align: center;
      width: 700px;
      height: 350px;
      background-size: cover;
      margin: 10px;
      text-decoration: none;
      border-radius: 10px;

      .trip-element-label {
        text-shadow: 5px 1px 4px black, 0 0 1em;
        font-size: 42px;
        color: #fff;
        font-weight: 600;
        text-transform: capitalize;
        vertical-align: middle;
        line-height: 350px;
        text-align: center;
      }

      &#trip-france {
        background-image: url(/assets/images/trip-france.jpeg);
      }

      &#trip-italy {
        background-image: url(/assets/images/trip-italy.jpeg);
      }

      &#trip-germany {
        background-image: url(/assets/images/trip-germany.jpeg);
      }

      &#trip-spain {
        background-image: url(/assets/images/trip-spain.jpeg);
      }

      @include respond-to(phones) {
        width: auto;
        height: auto;
      }
    }
  }
}
