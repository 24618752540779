.region-image-section {
  padding: 15px 0;
  font-size: 14px;
  line-height: 1.3333333;

  @include respond-to(wide-handhelds) {
    font-size: 16px;
    padding: 30px 0;
  }
  @include respond-to(desktop) {
    font-size: 18px;
    padding: 80px 0;
  }

  .row {
    &:nth-of-type(odd) {
      .region--photo {
        @include respond-to(wide-handhelds) {
          order: 1;
          padding-right: 0;
        }
      }

      .region--text {
        @include respond-to(wide-handhelds) {
          order: 2;
          padding-right: 30px;
        }
        @include respond-to(tablets) {
          padding-left: 73px;
        }
      }
    }

    &:nth-of-type(even) {
      .region--photo {
        @include respond-to(wide-handhelds) {
          order: 2;
          padding-left: 0;
        }
      }

      .region--text {
        @include respond-to(wide-handhelds) {
          order: 1;
          padding-left: 30px;
          text-align: right;
        }
        @include respond-to(tablets) {
          padding-right: 73px;
        }
      }
    }
  }
}

.region--photo {
  order: 1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.region--text {
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;

  p {
    margin: 0 0 7px;
    @include respond-to(wide-handhelds) {
      margin: 0 0 15px;
    }
    @include respond-to(tablets) {
      margin: 0 0 25px;
    }
  }
}

// .estates-section {
//   .skew__item-photo {
//     height: 100%;
//     @include respond-to(wide-handhelds) {
//       max-height: 217px;
//     }
//     @include respond-to(tablets) {
//       max-height: 353px;
//     }
//   }
// }

//.region-estate-desc {
//  height: 25px;
//  overflow: hidden;
//  word-break:break-word;
//}

//.skew__item-photo {
//  max-height: 250px;
//}
