@charset "UTF-8";
/*Variables*/
.container {
  max-width: 100%;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative; }
  @media (min-width: 1200px) {
    .container {
      max-width: 90%; } }
  @media (min-width: 1560px) {
    .container {
      max-width: 90%; } }

.container-fluid {
  padding: 0 15px; }

.row {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-right: -15px;
  margin-left: -15px; }
  .row--align-right {
    justify-content: flex-end; }
  .row--align-between {
    justify-content: space-between; }

[class*='col-'] {
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex: 0 0 100%;
  max-width: 100%; }
  .col + .col {
    margin-top: 15px; }
    @media only screen and (min-width: 768px) {
      .col + .col {
        margin: 0; } }

@media only screen and (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media only screen and (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media only screen and (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Lato Light"), local("Lato-Light"), url(https://fonts.gstatic.com/s/lato/v15/S6u9w4BMUTPHh7USSwaPGR_p.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local("Lato Light"), local("Lato-Light"), url(https://fonts.gstatic.com/s/lato/v15/S6u9w4BMUTPHh7USSwiPGQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v15/S6uyw4BMUTPHjxAwXjeu.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Lato Regular"), local("Lato-Regular"), url(https://fonts.gstatic.com/s/lato/v15/S6uyw4BMUTPHjx4wXg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Lato Bold"), local("Lato-Bold"), url(https://fonts.gstatic.com/s/lato/v15/S6u9w4BMUTPHh6UVSwaPGR_p.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local("Lato Bold"), local("Lato-Bold"), url(httpsfonts.gstatic.com/s/lato/v15/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

@font-face {
  font-family: 'Freigeist-Regular';
  src: url("fonts/freigeist-regular.woff2") format("woff2"), url("fonts/freigeist-regular.woff") format("woff"); }

@font-face {
  font-family: 'Freigeist-WideMedium';
  src: url("fonts/freigeist-medium.woff2") format("woff2"), url("fonts/freigeist-medium.woff") format("woff"); }

@font-face {
  font-family: 'Freigeist-WideRegular';
  src: url("fonts/freigeist-wide.woff2") format("woff2"), url("fonts/freigeist-wide.woff") format("woff"); }

body {
  font-family: "Lato", sans-serif;
  font-size: 14px;
  font-weight: 400;
  max-width: 100%; }

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1; }
  .wrapper main {
    flex: 1 0 auto;
    position: relative;
    z-index: 1;
    padding-top: 50px; }
    @media only screen and (min-width: 768px) {
      .wrapper main {
        padding-top: 71px; } }
  .wrapper.main-page main {
    padding-top: 0; }

a {
  transition: all .3s ease;
  color: inherit; }

img {
  max-width: 100%; }

b,
strong {
  font-weight: 700; }

button {
  outline: none; }

button:focus {
  background: transparent; }

.clearfix:before,
.clearfix:after {
  display: block;
  content: ' '; }

.irs-to:after {
  display: inline-block; }

.clearfix:after {
  clear: both; }

body.noscroll,
body.overflow {
  overflow: hidden; }

noscript {
  background: #fff;
  font-size: 14px;
  width: 100%;
  z-index: 9999999;
  text-align: center; }
  noscript a {
    color: red;
    text-decoration: underline; }
    noscript a:hover {
      text-decoration: none; }

.input-button-size-2 {
  margin-left: -39px !important;
  margin-right: 0px !important; }

form fieldset {
  padding-top: 30px;
  border-color: #f0ffff; }
  form fieldset + fieldset {
    margin-top: 30px; }

form legend {
  color: #e6333d; }

input,
textarea {
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  background: #fff; }

input[type='search'] {
  -webkit-appearance: none; }

.hidden {
  display: none !important; }

.unvisible {
  visibility: hidden !important; }

.section-title {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  text-align: center;
  margin: 0 auto 30px; }
  @media only screen and (min-width: 992px) {
    .section-title {
      margin: 0 auto 72px;
      font-size: 36px; } }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.align-center {
  text-align: center; }

h1,
h2,
h3,
h4,
h5 {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: normal;
  line-height: 1.285714286; }

h1 {
  font-size: 30px; }

h2 {
  font-size: 28px; }

h3 {
  font-size: 26px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 20px; }

.header-banner-info {
  position: relative;
  left: 0;
  top: 51px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #c8c8c8;
  color: #e6333d;
  text-align: center;
  z-index: 9; }
  @media only screen and (min-width: 768px) {
    .header-banner-info {
      top: 81px; } }

.page-overlay {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 57, 57, 0.5);
  display: none; }

.fieldset-category--error {
  position: relative;
  border: solid 1px #d0011b; }
  .fieldset-category--error:before {
    content: attr(data-error);
    position: absolute;
    top: -12px;
    right: 0;
    color: #d0011b;
    font-size: 12px;
    line-height: 14px; }

.input-field {
  margin: 0 0 14px;
  text-align: left;
  position: relative; }
  .input-field:before {
    content: attr(data-error);
    position: absolute;
    top: -12px;
    right: 0;
    color: #d0011b;
    font-size: 12px;
    line-height: 14px;
    opacity: 0;
    visibility: hidden; }
  .input-field.col:before {
    right: 15px; }
  .input-field.error {
    --error-top: 86px; }
    .input-field.error:before {
      visibility: visible;
      opacity: 1;
      top: var(--error-top); }
    .input-field.error input,
    .input-field.error textarea,
    .input-field.error select,
    .input-field.error span.select2 {
      border: solid 1px #d0011b; }
      .input-field.error input::-webkit-input-placeholder, .input-field.error textarea::-webkit-input-placeholder, .input-field.error select::-webkit-input-placeholder, .input-field.error span.select2::-webkit-input-placeholder {
        color: #000; }
      .input-field.error input:-moz-placeholder, .input-field.error textarea:-moz-placeholder, .input-field.error select:-moz-placeholder, .input-field.error span.select2:-moz-placeholder {
        color: #000; }
      .input-field.error input::-moz-placeholder, .input-field.error textarea::-moz-placeholder, .input-field.error select::-moz-placeholder, .input-field.error span.select2::-moz-placeholder {
        color: #000; }
      .input-field.error input:-ms-input-placeholder, .input-field.error textarea:-ms-input-placeholder, .input-field.error select:-ms-input-placeholder, .input-field.error span.select2:-ms-input-placeholder {
        color: #000; }
  .input-field--image.error:before {
    top: 25px; }
    @media only screen and (min-width: 768px) {
      .input-field--image.error:before {
        top: 0; } }
  .input-field--image.error .upload-group {
    margin-top: 45px; }
    @media only screen and (min-width: 768px) {
      .input-field--image.error .upload-group {
        margin-top: 0; } }
  .input-field--checkbox.error:before {
    position: relative;
    display: block;
    text-align: right; }
    @media only screen and (min-width: 992px) {
      .input-field--checkbox.error:before {
        position: absolute;
        top: -13px; } }
    @media (min-width: 1200px) {
      .input-field--checkbox.error:before {
        top: -15px; } }
  .input-field--checkbox.error .checkbox label:before {
    border-color: #e6333d; }
  .input-field--textarea.error:before {
    top: 290px; }
  .input-field.align-right {
    text-align: right; }
  .input-field__wrapper {
    display: flex;
    align-items: stretch; }
  .input-field div.error-message {
    color: #d0011b;
    font-size: 12px;
    line-height: 14px; }
  .input-field label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 8px; }
  .input-field input,
  .input-field textarea,
  .input-field select {
    height: 47px;
    border-radius: 2px;
    background-color: #fcfcfc;
    border: solid 1px #d3d3d3;
    line-height: 47px;
    font-size: 16px;
    font-family: "Lato", sans-serif;
    padding: 0 20px;
    width: 100% !important; }
    .input-field input::-webkit-input-placeholder, .input-field textarea::-webkit-input-placeholder, .input-field select::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 18px;
      font-family: "Lato", sans-serif;
      font-weight: 300; }
    .input-field input:-moz-placeholder, .input-field textarea:-moz-placeholder, .input-field select:-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 18px;
      font-family: "Lato", sans-serif;
      font-weight: 300; }
    .input-field input::-moz-placeholder, .input-field textarea::-moz-placeholder, .input-field select::-moz-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 18px;
      font-family: "Lato", sans-serif;
      font-weight: 300; }
    .input-field input:-ms-input-placeholder, .input-field textarea:-ms-input-placeholder, .input-field select:-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.5);
      font-size: 18px;
      font-family: "Lato", sans-serif;
      font-weight: 300; }
  .input-field textarea {
    line-height: normal;
    padding: 20px;
    height: 248px;
    resize: none; }

.item-description:before {
  top: 160px !important; }

.item-price:before {
  top: 100px !important; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.text-muted {
  color: #636c72; }
  .text-muted span {
    color: #e6333d; }

.input-subtext {
  padding-left: 5px; }

select {
  height: 40px;
  padding: 10px 15px;
  min-width: 150px;
  font-size: 14px;
  background-color: #fff;
  background-image: url("../assets/images/dropdown-icon.svg");
  background-position: 100% center;
  background-repeat: no-repeat;
  border-color: #999;
  -webkit-appearance: none;
  -moz-appearance: none; }
  select:hover {
    cursor: pointer; }

:root {
  --custom-color: #999; }

.mandatory-field {
  --custom-color: #e6333d; }

.checkbox {
  position: relative; }
  .checkbox label {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 25px;
    min-height: 30px;
    font-size: 12px;
    color: var(--custom-color);
    text-transform: uppercase;
    user-select: none;
    cursor: pointer; }
    .checkbox label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 9px;
      background-color: #fff;
      border: 1px solid #999;
      border-radius: 4px;
      cursor: pointer; }
  .checkbox [type='checkbox'] {
    position: absolute;
    opacity: 0; }
    .checkbox [type='checkbox']:checked + label {
      color: #000; }
      .checkbox [type='checkbox']:checked + label:before {
        background: #e6333d;
        border-color: #e6333d; }
      .checkbox [type='checkbox']:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        left: 7px;
        width: 6px;
        height: 11px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        margin: -2px 0 0 -1px; }

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px; }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
    .switch input:checked + .slider {
      background-color: #00b050; }
      .switch input:checked + .slider:before {
        transform: translateX(26px); }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: all .3s ease; }
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    border-radius: 50%;
    transition: all .3s ease; }

.slick-initialized .slick-slide {
  outline: none; }

.btn-holder {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .btn-holder--right {
    justify-content: flex-end; }
  .btn-holder--center {
    justify-content: center; }

.btn {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  padding: 0 25px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border: 0;
  font-family: 'Freigeist-WideRegular';
  transition: all .3s ease; }
  .btn:hover, .btn:focus {
    cursor: pointer;
    color: #000; }
  .btn--seller-discount {
    height: 20px;
    line-height: 0;
    padding: 2px 7px 0 7px; }
  .btn--default {
    color: #fff;
    background: #e6333d;
    border-radius: 20px;
    text-transform: uppercase; }
    .btn--default:hover, .btn--default:focus {
      color: #fff;
      opacity: 0.8;
      background: #e6333d; }
  .btn--center {
    margin: 0 auto; }
  .btn.shop-button {
    color: #000;
    background: #fff;
    border-radius: 20px;
    text-transform: uppercase;
    position: relative;
    margin-top: -250px;
    font-size: 24px;
    font-weight: bold; }
    .btn.shop-button:hover, .btn.shop-button:focus {
      color: #000;
      opacity: 0.8;
      background: #fff; }
  .btn--bordered {
    color: #e6333d;
    border: 1px solid #e6333d;
    border-radius: 20px;
    background: transparent; }
    .btn--bordered:hover, .btn--bordered:focus {
      background: #e6333d;
      color: #fff; }
  .btn--light {
    color: #fff;
    border: 1px solid #fff;
    background: transparent; }
    .btn--light:hover, .btn--light:focus {
      background: #fff;
      color: #000; }
  .btn--dark {
    background: #252254;
    color: #fff;
    border-radius: 20px; }
    .btn--dark:hover, .btn--dark:focus {
      color: #fff;
      background: #808080; }
  .btn--black {
    background: #000;
    color: #fff;
    border-radius: 20px; }
    .btn--black:hover, .btn--black:focus {
      color: #fff;
      background: #808080; }
  .btn--white {
    background: #fff;
    color: #000;
    border-radius: 20px; }
  .btn--close-modal {
    position: absolute;
    right: 15px;
    top: 15px;
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #f2f4ef;
    border-radius: 50%;
    cursor: pointer; }
    .btn--close-modal svg {
      width: 15px;
      height: 15px;
      fill: #808080;
      transition: all .3s ease; }
    .btn--close-modal:hover svg, .btn--close-modal:focus svg {
      fill: #000; }
  @media only screen and (min-width: 992px) {
    .btn--login {
      line-height: 40px;
      height: 40px;
      padding: 0 25px; } }
  .btn--lg {
    border-radius: 20px;
    font-size: 14px;
    text-transform: uppercase; }
    @media (min-width: 1200px) {
      .btn--lg {
        border-radius: 20px;
        max-width: 282px;
        width: 100%; } }
  .btn--sm {
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    padding: 0 5px; }
    @media only screen and (min-width: 992px) {
      .btn--sm {
        padding: 0 10px;
        font-size: 12px; } }
    @media (min-width: 1200px) {
      .btn--sm {
        padding: 0 7px; } }
    @media (min-width: 1560px) {
      .btn--sm {
        padding: 0 10px;
        font-size: 12px; } }
  .btn--primary {
    color: #fff;
    background: rgba(0, 176, 80, 0.8);
    text-transform: uppercase;
    border-radius: 20px; }
    .btn--primary.btn--lg {
      font-size: 26px; }
      @media (min-width: 1200px) {
        .btn--primary.btn--lg {
          font-size: 32px; } }
    .btn--primary:hover, .btn--primary:focus {
      color: #fff;
      background: #00b050; }
  .btn-postal-code-find {
    display: flex;
    height: 47px;
    align-self: flex-end;
    align-items: center;
    margin-left: 10px; }

.btn--bordered {
  border-radius: 20px; }

.add-to-cart-btn {
  border-radius: 20px; }

.banner-container {
  display: flex; }

.dropdown-toggle {
  position: relative;
  padding: 0 20px; }
  .dropdown-toggle.nav-toggle:after {
    display: none; }

.dropdown {
  display: none;
  position: absolute;
  left: 0;
  top: 122%;
  background: #fff;
  overflow-y: initial;
  min-width: 220px;
  transition: all .3s ease;
  z-index: 10; }
  .dropdown--lang {
    min-width: 100%; }
  .dropdown--navigation {
    right: auto;
    left: 0;
    max-width: auto;
    top: 80px; }
  .dropdown .btn {
    display: block;
    text-align: center;
    font-size: 16px; }

.dropdown__list {
  list-style: none;
  margin: 0;
  padding: 0; }

.buyer_dropdown_item {
  height: 40px; }
  .buyer_dropdown_item:hover, .buyer_dropdown_item:focus {
    background: rgba(128, 128, 128, 0.3);
    cursor: pointer; }
  .buyer_dropdown_item a {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    line-height: normal;
    user-select: none;
    transition: all .3s ease; }

.dropdown__item-text {
  color: white;
  font-size: 20px;
  font-weight: 100;
  padding-left: 20px; }

.dropdown__item a,
.dropdown__item span {
  display: block;
  padding: 0px 10px;
  height: 40px;
  width: max-content;
  min-width: 100%;
  line-height: 40px;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  transition: all .3s ease; }
  .dropdown__item a:hover, .dropdown__item a:focus,
  .dropdown__item span:hover,
  .dropdown__item span:focus {
    background: rgba(128, 128, 128, 0.3); }

.dropdown--menu {
  left: 0;
  top: 0;
  right: 0;
  overflow-y: auto;
  height: 100vh;
  background: #211e4a; }
  @media only screen and (min-width: 992px) {
    .dropdown--menu {
      position: fixed; } }
  .dropdown--menu .dropdown__item {
    margin-bottom: 10px; }
    .dropdown--menu .dropdown__item:last-child {
      margin-bottom: 0; }
  .dropdown--menu .dropdown__item-margin {
    margin-bottom: 35px; }
  .dropdown--menu .dropdown__item-li {
    width: fit-content;
    background: #a9c92f;
    display: inline-block;
    margin-left: 20px; }
  .dropdown--menu .lang-selector {
    text-align: right !important;
    padding-right: 40px;
    font-size: 20px !important;
    color: black !important; }
  .dropdown--menu .dropdown__list--lang {
    padding-top: 0;
    display: contents; }
  .dropdown--menu .dropdown__list--lang a {
    color: #fff; }
  .dropdown--menu .dropdown__item-a {
    font-size: 35px; }
  .dropdown--menu a {
    text-transform: capitalize;
    text-align: left;
    padding: 0 20px;
    font-weight: 100;
    color: #fff; }
  .dropdown--menu .header__nav-item--language + .dropdown__item {
    margin-top: 30px; }
    @media only screen and (max-width: 575px) {
      .dropdown--menu .header__nav-item--language + .dropdown__item {
        display: none; } }
  .dropdown--menu .dropdown {
    position: static; }
  .dropdown--menu .dropdown--lang {
    background-color: #211e4a; }
    .dropdown--menu .dropdown--lang a {
      text-transform: uppercase;
      color: #fff !important; }
      @media (min-width: 1200px) {
        .dropdown--menu .dropdown--lang a {
          color: #000; } }
    @media (min-width: 1200px) {
      .dropdown--menu .dropdown--lang {
        background-color: #fff; } }
  .dropdown--menu .dropdown-toggle.language-menu,
  .dropdown--menu .dropdown-toggle.toggle--seller-menu {
    padding-right: 40px;
    padding-left: 20px;
    font-size: 20px;
    line-height: 40px;
    justify-content: flex-end;
    color: #fff; }
    .dropdown--menu .dropdown-toggle.language-menu::before,
    .dropdown--menu .dropdown-toggle.toggle--seller-menu::before {
      content: none; }
    .dropdown--menu .dropdown-toggle.language-menu::after,
    .dropdown--menu .dropdown-toggle.toggle--seller-menu::after {
      right: 20px; }
  .dropdown--menu .dropdown-toggle.toggle--seller-menu {
    text-transform: none; }

.dropdown--profile {
  top: 36px;
  left: auto;
  right: 0;
  min-width: 200px; }
  @media only screen and (min-width: 992px) {
    .dropdown--profile {
      top: 49px; } }
  @media (min-width: 1200px) {
    .dropdown--profile {
      top: 61px; } }
  .dropdown--profile .dropdown__item {
    margin-bottom: 10px; }
    .dropdown--profile .dropdown__item:last-child {
      margin-bottom: 0; }
    @media (min-width: 1200px) {
      .dropdown--profile .dropdown__item {
        margin-bottom: 0; } }

.pagination-container {
  text-align: center;
  width: 100%; }

.pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
  list-style: none; }

.page-item.active {
  color: #b91828;
  border-radius: 50%;
  border: 1px solid #b91828; }

.page-nav-item {
  font-size: 18px;
  margin-left: 8px;
  margin-right: 8px; }
  .page-nav-item.disabled {
    opacity: 0.5; }

.page-item {
  width: 32px;
  line-height: 30px;
  border-radius: 50%;
  border: 1px solid white;
  float: left; }
  .page-item.item {
    margin-top: 4px; }

.page-link {
  text-decoration: none;
  cursor: pointer; }
  .page-link--inactive {
    cursor: auto; }

.tabs {
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0 0 -1px;
  list-style: none;
  display: flex; }

.tab-link {
  flex-grow: 1;
  height: 45px;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  line-height: 45px;
  text-transform: uppercase;
  border: 1px solid transparent;
  cursor: pointer; }
  @media only screen and (min-width: 768px) {
    .tab-link {
      font-size: 14px; } }
  .tab-link.current {
    border: 1px solid #ccc;
    border-bottom: 1px solid #fff;
    color: #000; }
  .tab-link.has-error {
    animation-name: error-background-flashing;
    animation-duration: 0.5s;
    animation-iteration-count: 3;
    color: #e6333d; }

@-webkit-keyframes error-background-flashing {
  from {
    background-color: rgba(230, 51, 61, 0);
    border-top-color: #e6333d;
    border-right-color: #e6333d;
    border-left-color: #e6333d; }
  to {
    background-color: rgba(230, 51, 61, 0.3);
    border-top-color: #e6333d;
    border-right-color: #e6333d;
    border-left-color: #e6333d; } }

.tab-content {
  display: none;
  border: 1px solid #ccc;
  padding: 30px 10px; }
  .tab-content.current {
    display: block; }

.main-banner {
  min-height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff; }
  @media only screen and (min-width: 768px) {
    .main-banner {
      min-height: 420px; } }
  @media (min-width: 1560px) {
    .main-banner {
      min-height: 500px; } }
  .main-banner:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%); }

.main-banner__content {
  position: relative;
  padding: 0 15px;
  z-index: 1;
  text-align: center; }

.main-banner__title {
  text-shadow: 5px 1px 4px black, 0 0 1em;
  font-size: 34px;
  line-height: 1.2;
  font-weight: 700; }
  @media only screen and (min-width: 768px) {
    .main-banner__title {
      font-size: 48px; } }
  @media (min-width: 1200px) {
    .main-banner__title {
      font-size: 72px; } }

.main-banner__subtitle {
  font-size: 16px;
  line-height: 1.2;
  margin-top: 10px; }
  @media only screen and (min-width: 768px) {
    .main-banner__subtitle {
      font-size: 16px; } }
  .main-banner__subtitle blockquote {
    max-width: 60%;
    font-size: 22px;
    margin: 0 auto 45px;
    font-style: italic; }
    .main-banner__subtitle blockquote footer {
      padding: 15px 0;
      font-size: 16px; }

.main-banner__text {
  display: block;
  padding: 15px 0;
  font-size: 16px; }

.intro-text-section {
  font-size: 16px;
  line-height: 1.33333;
  padding: 30px 0 23px; }
  @media only screen and (min-width: 768px) {
    .intro-text-section {
      font-size: 16px; } }
  @media (min-width: 1200px) {
    .intro-text-section {
      font-size: 16px;
      padding: 70px 0 10px; } }
  .intro-text-section .region-h2 {
    color: #b81927;
    font-size: 24px; }
  .intro-text-section .region-h2__bottom {
    color: #606060;
    font-size: 24px;
    margin-top: 30px; }
  .intro-text-section .p-regions {
    margin-bottom: 0; }
  .intro-text-section .read-more__button {
    text-align: center;
    margin-bottom: 10px; }
  .intro-text-section .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    margin: 0 0 15px; }
    @media only screen and (min-width: 768px) {
      .intro-text-section .title {
        font-size: 24px;
        margin: 0 0 30px; } }
  .intro-text-section--left .title {
    text-align: left; }
  .intro-text-section p {
    margin: 0 0 7px; }
    @media only screen and (min-width: 992px) {
      .intro-text-section p {
        margin: 0 0 25px; } }
  .intro-text-section .blockquote-box {
    display: flex;
    justify-content: flex-end;
    text-align: right; }
  .intro-text-section blockquote {
    font-style: italic;
    line-height: 1.6;
    margin: 0 0 15px; }
    @media only screen and (min-width: 768px) {
      .intro-text-section blockquote {
        max-width: 50%; } }
    @media only screen and (min-width: 992px) {
      .intro-text-section blockquote {
        max-width: 34%;
        font-size: 22px;
        margin: 0 0 45px; } }
    .intro-text-section blockquote footer {
      padding: 15px 0;
      font-size: 16px; }
  .intro-text-section a {
    color: #e6333d;
    text-decoration: none; }
    .intro-text-section a:hover, .intro-text-section a:focus {
      text-decoration: underline; }
  .intro-text-section .input-field {
    font-size: 14px; }
  .intro-text-section pre {
    font-family: 'Freigeist-WideRegular'; }

.intro-description {
  font-family: 'Freigeist-WideMedium'; }

.icons-list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 30px; }
  .icons-list li {
    display: flex;
    align-items: center; }
    .icons-list li + li {
      margin-top: 15px; }
    .icons-list li img {
      flex: 0 0 80px;
      max-width: 80px; }
    .icons-list li span {
      display: block;
      margin-left: 25px; }

.bg-grey {
  background: #f2f4ef; }

.section-banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 20px; }
  .section-banner-content .title {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0 0 10px;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .section-banner-content .title {
        font-size: 34px;
        margin: 0 0 20px; } }
  .section-banner-content p {
    margin: 0 0 20px; }

.skew-items-section {
  padding: 65px 0 70px; }

.skew-items-list {
  list-style: none;
  margin: 0;
  padding: 0; }

.estates-section.skew-items-section .skew__item {
  height: 326px; }

.estates-section.skew-items-section .skew__item-photo {
  height: 326px; }

.estates-section.skew-items-section .estate-title-mobile {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 32px;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .estates-section.skew-items-section .estate-title-mobile {
      display: none; } }
  .estates-section.skew-items-section .estate-title-mobile .estate-link-div-mobile .estate-link-mobile {
    text-decoration: none; }

@media only screen and (min-width: 768px) {
  .estates-section.skew-items-section .skew__item-info:before {
    left: -25%;
    transform: skew(-15deg); } }

.estates-section.skew-items-section a {
  height: 266px;
  overflow: hidden; }

.skew__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden; }
  .skew__item + .skew__item {
    margin-top: 30px; }
    @media only screen and (min-width: 992px) {
      .skew__item + .skew__item {
        margin-top: 74px; } }

.skew__item-photo-box {
  position: relative;
  flex-basis: 100%;
  background-color: #f2f4ef; }
  @media only screen and (min-width: 768px) {
    .skew__item-photo-box {
      flex-basis: 55%;
      background-color: transparent; } }
  @media only screen and (min-width: 992px) {
    .skew__item-photo-box {
      flex-basis: 55%; } }

.skew__item-placeholder {
  position: absolute;
  width: 140px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: rgba(0, 0, 0, 0.1); }
  @media only screen and (min-width: 768px) {
    .skew__item-placeholder {
      transform: translate(-80%, -50%); } }
  @media (min-width: 1200px) {
    .skew__item-placeholder {
      display: none; } }

.skew__item-photo {
  position: relative;
  flex: 0 0 100%;
  z-index: 1; }
  @media only screen and (min-width: 768px) {
    .skew__item-photo {
      flex: 0 0 55%; } }
  @media only screen and (min-width: 992px) {
    .skew__item-photo {
      flex: 0 0 55%; } }
  .skew__item-photo img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.skew__item-info {
  position: relative;
  width: 45%;
  z-index: 2;
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  background: #f2f4ef;
  text-align: left; }
  @media only screen and (min-width: 768px) {
    .skew__item-info {
      padding: 15px 8px 15px 0;
      flex: 0 0 45%;
      text-align: right;
      align-items: flex-end; } }
  @media only screen and (min-width: 992px) {
    .skew__item-info {
      padding: 15px 60px 15px 0;
      flex: 0 0 45%; } }
  @media only screen and (min-width: 768px) {
    .skew__item-info:before {
      content: '';
      position: absolute;
      left: -20%;
      top: 0;
      width: 100%;
      height: calc(100% + 1px);
      transform: skew(-15deg);
      background: inherit;
      z-index: -1; } }
  .skew__item-info a {
    display: block;
    width: 100%;
    text-decoration: none; }
  .skew__item-info .title {
    font-weight: 400;
    line-height: 1;
    color: #e6333d;
    margin: 0 0 15px;
    word-wrap: break-word; }
    @media only screen and (min-width: 768px) {
      .skew__item-info .title {
        font-size: 28px; } }
    @media only screen and (min-width: 992px) {
      .skew__item-info .title {
        font-size: 36px;
        margin: 0 0 30px; } }
  .skew__item-info p {
    margin: 0 0 30px;
    word-wrap: break-word; }
    @media only screen and (min-width: 992px) {
      .skew__item-info p {
        font-size: 18px; } }

.page-title {
  font-size: 22px;
  color: #555;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 40px;
  padding-bottom: 4px;
  text-align: center; }

.breadcrumbs {
  margin-left: -10px;
  padding: 15px 0; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center; }
    .breadcrumbs ul li {
      color: #b81928;
      font-size: 14px; }
      .breadcrumbs ul li.active {
        color: #b81928; }
      .breadcrumbs ul li + li {
        position: relative; }
        .breadcrumbs ul li + li:before {
          content: '|';
          display: inline-block;
          vertical-align: middle;
          color: #b81928; }
      .breadcrumbs ul li a,
      .breadcrumbs ul li span {
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px;
        text-transform: uppercase;
        text-decoration: none;
        color: #b81928; }

.slick-arrow {
  position: absolute;
  top: 50%;
  width: 45px;
  height: 45px;
  transform: translate3d(0, -50%, 0);
  font-size: 0;
  background-color: transparent;
  border: 0;
  opacity: 0.5;
  z-index: 10;
  transition: all .3s ease; }
  @media only screen and (min-width: 768px) {
    .slick-arrow {
      width: 60px;
      height: 60px; } }
  .slick-arrow svg {
    fill: #000;
    width: 100%;
    height: 100%; }
  .slick-arrow:hover, .slick-arrow:focus {
    cursor: pointer;
    outline: none;
    opacity: 1; }
  .slick-arrow.prev-arrow {
    left: 0; }
    @media only screen and (min-width: 768px) {
      .slick-arrow.prev-arrow {
        left: 15px; } }
  .slick-arrow.next-arrow {
    right: 0; }
    @media only screen and (min-width: 768px) {
      .slick-arrow.next-arrow {
        right: 15px; } }

.page-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 57, 57, 0.5);
  display: none; }

.fancybox-content .btn + .btn {
  margin-left: 15px; }

.fancybox-content .btn--dark {
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-transform: uppercase; }

.content-section a:not(.btn):hover, .content-section a:not(.btn):focus {
  color: #e6333d; }

.info-modal {
  text-align: center;
  font-size: 16px;
  display: block;
  max-width: 1550px; }
  .info-modal.fancybox-content {
    padding: 15px 30px 30px; }
    @media only screen and (max-width: 575px) {
      .info-modal.fancybox-content {
        padding: 15px 22px; } }

.delivery-popup {
  width: 550px; }
  @media only screen and (max-width: 575px) {
    .delivery-popup {
      width: 308px; } }

.error-modal {
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0, -30px, 0);
  max-width: 350px;
  width: 100%;
  padding: 15px;
  background: rgba(230, 51, 61, 0.9);
  color: #fff;
  border-bottom-left-radius: 4px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.4s ease; }
  .error-modal.show {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0); }

.error-content {
  display: flex;
  align-items: flex-start; }
  .error-content svg {
    width: 36px;
    height: 36px;
    fill: #fff; }
  .error-content p {
    margin: 0 0 7px; }

.error__text {
  padding: 0 15px; }
  .error__text h2 {
    line-height: 1;
    margin-bottom: 7px; }

.description {
  white-space: pre-line; }

.description-blog {
  white-space: unset;
  margin: 20px;
  font-family: 'Freigeist-WideRegular'; }

.g-recaptcha {
  margin: 10px 37px; }

.special-list-item {
  color: #e6333d !important;
  text-decoration: none !important; }

.guestSpecials {
  color: #e6333d !important; }

.seller-discount-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  font-size: 24px;
  font-weight: bold; }

.seller-message-stub {
  padding-top: 60px; }

.special-filter {
  font-size: 14px !important;
  font-weight: 700 !important; }

.pointer {
  cursor: pointer; }

.delivery-flag {
  height: 32px;
  width: 32px; }

.wine-filter {
  display: flex;
  justify-content: flex-end;
  align-items: baseline; }

.top-filter {
  margin-bottom: 5px; }

.delivery-country-label {
  width: 150px;
  display: flex;
  align-items: center;
  height: 58px;
  cursor: pointer;
  padding-left: 10px; }
  @media only screen and (max-width: 575px) {
    .delivery-country-label {
      padding-left: 0px; } }

.delivery-info {
  margin-top: 20px;
  max-width: 408px; }

.sing-up-link:focus {
  outline: none;
  box-shadow: none; }

.fancybox-content {
  font-family: 'Freigeist-WideRegular'; }

.inline-cont {
  display: inline-flex; }

* {
  box-sizing: border-box; }

html {
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0;
  overflow-x: hidden; }

html, body {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }

main {
  display: block; }

small {
  font-size: 80%; }

img {
  border-style: none;
  max-width: 100%; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button {
  display: none; }

h1, h2, h3, h4, h5 {
  margin: 0; }

.profile-section {
  padding: 60px 0; }
  .profile-section--order-details .page-title {
    text-align: left; }

.profile-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  text-align: center;
  margin: 0 0 15px; }

.input-field__dob {
  max-width: 200px; }

.profile-subtitle {
  font-size: 14px;
  font-weight: 300;
  color: #999;
  text-align: center;
  margin: 0 0 40px; }

.promo-select-subtext {
  color: #999;
  margin-left: 5px; }

.profile-form .input-field {
  margin: 0 0 25px; }
  .profile-form .input-field label {
    text-transform: uppercase;
    margin: 0 0 15px; }
  .profile-form .input-field h4 {
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 15px; }

.profile-form .upload-group label {
  margin: 0;
  text-transform: none;
  font-weight: 700;
  background: rgba(153, 153, 153, 0.15);
  cursor: pointer; }
  @media only screen and (min-width: 768px) {
    .profile-form .upload-group label {
      min-height: 130px;
      font-size: 14px; } }
  @media only screen and (min-width: 992px) {
    .profile-form .upload-group label {
      min-height: 217px;
      font-size: 15px; } }

@media only screen and (min-width: 992px) {
  .profile-form .upload-group--banner label {
    min-height: 315px; } }

.profile-form .upload-group--profile {
  max-width: 150px; }
  .profile-form .upload-group--profile label {
    min-height: 150px; }

.profile-form .btn-holder {
  padding: 30px 0;
  gap: 5px; }

.profile-form .btn--dark {
  font-size: 10px;
  line-height: 40px;
  height: 40px;
  border-radius: 20px;
  text-transform: uppercase; }
  @media only screen and (min-width: 576px) {
    .profile-form .btn--dark {
      font-size: 14px; } }

.profile-form .btn--default {
  font-size: 10px; }
  @media only screen and (min-width: 576px) {
    .profile-form .btn--default {
      font-size: 14px; } }

.image-upload-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column; }
  @media only screen and (min-width: 768px) {
    .image-upload-box {
      flex-direction: row; } }

.upload-group {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid #d3d3d3; }
  .upload-group + .upload-group {
    margin: 15px 0 0; }
    @media only screen and (min-width: 768px) {
      .upload-group + .upload-group {
        margin: 0 0 0 15px; } }
  .upload-group.has-error {
    border: solid 1px #d0011b; }
  .upload-group label {
    display: block;
    position: relative;
    z-index: 2;
    min-height: 200px;
    margin: 0;
    background: #fff; }
  .upload-group [type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden; }
  .upload-group span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.preview-thumb {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: #fff; }
  .preview-thumb.show-preview {
    opacity: 1;
    visibility: visible;
    z-index: 3; }
  .preview-thumb .imageThumb {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .preview-thumb:hover .remove-preview {
    opacity: 1;
    visibility: visible; }

.remove-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease; }
  .remove-preview span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    cursor: pointer; }
  .remove-preview svg {
    width: 20px;
    height: 20px;
    fill: #e6333d; }

.tab-content .profile-title {
  text-align: left; }

.profile-delivery-form {
  margin-bottom: 30px; }

.delivery-address-box {
  display: flex;
  flex-wrap: wrap; }
  .delivery-address-box .profile-title {
    max-width: 100%;
    flex: 0 0 100%; }

.saved-address {
  flex: 0 0 23%;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  margin-right: 15px;
  margin-bottom: 15px; }
  .saved-address .details {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px; }

.delete-address {
  width: 40px;
  height: 40px;
  background: rgba(230, 51, 61, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all .3s ease; }
  .delete-address:hover {
    opacity: 0.8; }
  .delete-address svg {
    width: 18px;
    height: 18px;
    fill: #fff; }

.profile-items-search {
  background: #f2f4ef;
  padding: 30px 0; }
  .profile-items-search .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; }
    @media only screen and (min-width: 576px) {
      .profile-items-search .container {
        flex-direction: row; } }
  .profile-items-search .btn svg {
    fill: #fff;
    width: 20px;
    height: 16px;
    display: inline-block;
    vertical-align: middle; }

.items-search-form {
  width: 100%;
  position: relative;
  margin-bottom: 15px; }
  @media only screen and (min-width: 576px) {
    .items-search-form {
      margin-bottom: 0;
      max-width: 250px; } }
  .items-search-form .input-field {
    margin: 0; }
    .items-search-form .input-field input {
      padding-right: 45px; }

.items-search__btn {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 40px;
  height: calc(100% - 2px);
  background: #fff;
  border: 0;
  cursor: pointer; }

.profile-items-section,
.profile-orders-section {
  padding: 30px 0; }

.items-table {
  display: block; }
  @media only screen and (min-width: 768px) {
    .items-table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0; } }
  .items-table--orders .items-table__body .items__row:nth-of-type(odd) {
    background: #f2f4ef; }

.items-table__body {
  display: block; }
  @media only screen and (min-width: 768px) {
    .items-table__body {
      display: table-row-group; } }

.items-table__head {
  display: none; }
  @media only screen and (min-width: 768px) {
    .items-table__head {
      display: table-header-group;
      border-bottom: 1px solid #d3d3d3; } }
  .items-table__head .table__item {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px; }
    @media only screen and (min-width: 992px) {
      .items-table__head .table__item {
        font-size: 14px; } }

.items__row {
  display: block; }
  @media only screen and (min-width: 768px) {
    .items__row {
      display: table-row;
      height: 58px;
      border-bottom: 1px solid #d3d3d3; } }

.table__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.625em; }
  @media only screen and (min-width: 768px) {
    .table__item {
      display: table-cell; } }
  @media only screen and (min-width: 768px) {
    .table__item:not(:first-of-type) {
      text-align: center; } }
  .table__item:before {
    content: attr(data-label);
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 7px;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase; }
    @media only screen and (min-width: 768px) {
      .table__item:before {
        display: none; } }
  .table__item .btn {
    font-size: 12px;
    height: 30px;
    line-height: 30px; }

.item__details {
  display: flex; }
  @media only screen and (min-width: 768px) {
    .item__details {
      align-items: center; } }
  .item__details img {
    width: 130px; }

.item__title {
  padding-top: 10px;
  padding-left: 15px;
  font-size: 14px; }
  @media only screen and (min-width: 768px) {
    .item__title {
      padding-top: 0; } }

.item-actions-list {
  display: flex;
  align-items: center; }
  @media only screen and (min-width: 768px) {
    .item-actions-list {
      flex-direction: column;
      justify-content: center; } }
  .item-actions-list a {
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    margin: 0 15px 0 0; }
    @media only screen and (min-width: 768px) {
      .item-actions-list a {
        margin: 0; } }
    .item-actions-list a svg {
      display: inline-block;
      vertical-align: middle;
      transition: all .3s ease; }

.btn--edit:hover svg, .btn--edit:focus svg {
  fill: #000; }

.btn--edit svg {
  fill: #999;
  width: 26px;
  height: 26px; }

.btn--remove svg {
  fill: #e6333d;
  width: 20px;
  height: 20px; }

.order-details__row {
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (min-width: 768px) {
    .order-details__row {
      margin-bottom: 30px; } }

.order-details__item {
  flex: 0 0 100%;
  margin-bottom: 10px; }
  @media only screen and (min-width: 768px) {
    .order-details__item {
      flex: 0 0 33.333333%;
      word-break: break-word;
      padding: 0 5px;
      margin: 0; } }
  .order-details__item--total {
    background: #f2f4ef;
    border-radius: 6px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px; }
    .order-details__item--total .order-item__title {
      color: #000; }

.order-item__title {
  color: #e6333d;
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700; }

.order-total__table {
  padding: 15px 0; }

.order-total__row {
  display: flex;
  align-items: center; }
  .order-total__row + .order-total__row {
    margin-top: 5px; }
  .order-total__row .label {
    min-width: 155px; }

.order-item__table .items-table {
  margin-bottom: 30px; }

.btn--download svg {
  transform: rotate(180deg);
  transform-origin: 50% 45%;
  fill: #fff;
  width: 20px;
  height: 16px;
  display: inline-block;
  vertical-align: middle; }

.orders-status {
  padding: 10px 0 30px;
  max-width: 50%; }
  .orders-status .radiobtn {
    position: relative; }
  .orders-status [type='radio']:checked,
  .orders-status [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px; }
  .orders-status [type='radio']:checked + label,
  .orders-status [type='radio']:not(:checked) + label {
    position: relative;
    display: block;
    padding: 0 15px 0 30px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-transform: uppercase;
    color: #808080;
    cursor: pointer; }
  .orders-status [type='radio']:checked + label:before,
  .orders-status [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 7px;
    top: 50%;
    margin-top: -6px;
    width: 12px;
    height: 6px;
    border: 0;
    border-left: 1px solid #808080;
    border-bottom: 1px solid #808080;
    transform: rotate(-45deg); }
  .orders-status [type='radio']:checked + label {
    background: #e6333d;
    color: #fff; }
  .orders-status [type='radio']:checked + label:before {
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff; }

.confirm-status-modal .fancybox-close-small {
  display: none; }

.modal-title {
  text-align: center;
  margin-bottom: 15px; }

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: background 0.3s ease;
  background: transparent;
  font-family: 'Freigeist-WideRegular'; }
  .header--fixed {
    background: #fffdfa;
    color: #000; }
    @media (min-width: 1200px) {
      .header--fixed {
        background: #fffdfa; } }
  .header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 45px; }
    @media only screen and (min-width: 992px) {
      .header .container {
        min-height: 70px;
        padding: 0 80px; } }
    @media (min-width: 1200px) {
      .header .container {
        padding: 0 0; } }

.nav__login {
  background: #211e4a;
  border-radius: 20px;
  width: 174px;
  height: 37px;
  text-align: center;
  font-size: 13px; }

.nav__login-text {
  color: white !important;
  line-height: 34px !important; }

.nav__login-link {
  text-transform: uppercase; }

.header__logo {
  flex: 0 0 30%;
  font-size: 18px; }
  @media only screen and (min-width: 992px) {
    .header__logo {
      font-size: 30px; } }
  @media only screen and (max-width: 575px) {
    .header__logo {
      left: 0 !important;
      font-size: initial;
      flex: none;
      padding-left: 0; } }
  .header__logo a {
    display: flex;
    text-decoration: none;
    text-align: center; }
  .header__logo img {
    height: 50px; }
    @media only screen and (max-width: 575px) {
      .header__logo img {
        height: 40px; } }

.welcome-to-winejump {
  color: white;
  font-size: 20px;
  font-weight: 100;
  padding-left: 20px;
  max-width: 75vw; }

.header__logo--center {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }

.header__logo-mobile {
  padding-top: 16px;
  padding-left: 37px; }

.header__bottom-mobile {
  display: flex;
  justify-content: space-between;
  padding-block-start: 15%; }

.header__login-button {
  font-size: 20px !important;
  color: #211e4a !important;
  justify-content: center !important;
  width: 50vw !important; }

.seller-header {
  display: flex;
  flex-direction: column;
  align-items: center; }

.header__nav-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  margin: 0 -7px; }
  @media (min-width: 1200px) {
    .header__nav-list {
      margin: 0 -20px; } }
  .header__nav-list .header__nav-item--main {
    color: #211e4a;
    display: none; }
    @media (min-width: 1200px) {
      .header__nav-list .header__nav-item--main {
        display: block; } }
    .header__nav-list .header__nav-item--main > a {
      white-space: nowrap;
      text-transform: uppercase; }

.header__nav-item {
  position: relative;
  font-size: 12px;
  transition: all 0.15s ease; }
  .header__nav-item:not(:last-of-type) {
    opacity: 0;
    visibility: hidden; }
  .header--fixed .header__nav-item {
    opacity: 1;
    visibility: visible; }
  @media only screen and (min-width: 768px) {
    .header__nav-item {
      font-size: 16px;
      line-height: 50px; } }
  .header__nav-item > a,
  .header__nav-item > span {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding: 0 10px;
    text-decoration: none; }
    @media only screen and (min-width: 992px) {
      .header__nav-item > a,
      .header__nav-item > span {
        padding: 0 20px; } }
    .header__nav-item > a:before,
    .header__nav-item > span:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 10px;
      margin: 0 0 -15px;
      background: #e6333d;
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease; }
    .header__nav-item > a:hover, .header__nav-item > a:focus,
    .header__nav-item > span:hover,
    .header__nav-item > span:focus {
      cursor: pointer; }
  .header__nav-item--cart svg {
    fill: #000;
    width: 24px;
    height: 24px; }
  .header__nav-item--cart .cart-amount {
    font-size: 12px;
    width: 32px; }
    @media (min-width: 1200px) {
      .header__nav-item--cart .cart-amount {
        width: 32px !important; } }
    @media (min-width: 1560px) {
      .header__nav-item--cart .cart-amount {
        width: 32px !important; } }
    @media only screen and (min-width: 768px) {
      .header__nav-item--cart .cart-amount {
        display: inline-block;
        padding: 0;
        font-size: 12px; } }
  .header__nav-item--signup {
    color: #e6333d;
    font-weight: 700;
    text-align: right; }
    @media only screen and (min-width: 576px) {
      .header__nav-item--signup {
        text-align: left; } }
  .header__nav-item--login {
    font-size: 0;
    transition: none; }
    @media only screen and (min-width: 768px) {
      .header__nav-item--login {
        font-size: inherit;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1; }
        .header__nav-item--login a {
          flex-direction: column; }
        .header__nav-item--login small {
          font-weight: 400;
          font-size: 65%; } }
    @media only screen and (min-width: 992px) {
      .header__nav-item--login {
        font-size: 24px; } }
    .header--fixed .header__nav-item--login {
      color: #000; }
      .header--fixed .header__nav-item--login span {
        color: #00b050;
        opacity: 0.8; }
    .header__nav-item--login svg {
      fill: #000;
      display: block; }
      @media only screen and (min-width: 768px) {
        .header__nav-item--login svg {
          display: none; } }
  .header__nav-item--search a:hover:before, .header__nav-item--search a:focus:before {
    display: none; }
  .header__nav-item i {
    font-style: normal; }

.header-profile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #ccc; }
  @media (min-width: 1200px) {
    .header-profile-image {
      width: 50px;
      height: 50px; } }
  .header-profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .header-profile-image svg {
    width: 40px;
    height: 40px; }

@media only screen and (min-width: 992px) {
  .header__nav-list--menu-desktop {
    display: none; } }

@media (min-width: 1200px) {
  .header__nav-list--menu-desktop {
    display: flex; } }

.header__nav-list--controls {
  margin-left: auto; }
  @media (min-width: 1200px) {
    .header__nav-list--controls {
      margin-left: 0; } }

.header__nav-list--controls .header__nav-item--language,
.header__nav-list--controls .header__nav-item--seller-menu {
  display: none; }
  @media (min-width: 1200px) {
    .header__nav-list--controls .header__nav-item--language,
    .header__nav-list--controls .header__nav-item--seller-menu {
      display: list-item; } }

.header__nav-item--language {
  margin-bottom: 50px; }
  @media (min-width: 1200px) {
    .header__nav-item--language {
      margin-bottom: 0; } }

@media only screen and (min-width: 992px) {
  .header__nav--menu-mobile {
    position: relative; } }

@media (min-width: 1200px) {
  .header__nav--menu-mobile {
    display: none; } }

.toggle--hamburger {
  display: block;
  padding-left: 10px; }
  @media only screen and (min-width: 992px) {
    .toggle--hamburger {
      padding-left: 20px; } }

.icon--toggle-hamburger {
  display: block;
  width: 30px;
  fill: black; }
  .icon--toggle-hamburger .top-line,
  .icon--toggle-hamburger .bottom-line {
    transition: all 0.3s linear; }
  .icon--toggle-hamburger.open .top-line {
    transform: rotate(45deg) translate(2px, -9px);
    fill: white; }
  .icon--toggle-hamburger.open .bottom-line {
    transform: rotate(-45deg) translate(-24px, -2px);
    fill: white; }

.header__nav-item--seller-menu ul {
  background-color: #211e4a; }
  @media (min-width: 1200px) {
    .header__nav-item--seller-menu ul {
      background-color: #fff; } }

.header__nav-item--seller-menu .dropdown__item a {
  color: #fff !important; }
  @media only screen and (max-width: 575px) {
    .header__nav-item--seller-menu .dropdown__item a {
      text-align: right; } }
  @media (min-width: 1200px) {
    .header__nav-item--seller-menu .dropdown__item a {
      color: #000 !important; } }

.header-searchbox {
  position: fixed;
  left: 0;
  top: 46px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  background: white;
  border-bottom: 1px solid #c8c8c8;
  z-index: 100;
  display: none; }
  @media only screen and (min-width: 992px) {
    .header-searchbox {
      top: 71px; } }
  .header-searchbox .main-search__form {
    width: 90%; }
    @media only screen and (min-width: 992px) {
      .header-searchbox .main-search__form {
        width: 60%;
        margin: 0 auto; } }
    .header-searchbox .main-search__form .input-field {
      margin: 0; }
  .header-searchbox .icon-close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer; }
    .header-searchbox .icon-close svg {
      fill: #e6333d; }

.footer {
  padding-top: 50px;
  padding-bottom: 25px;
  color: #211e4a;
  background: #fffdfa;
  font-family: 'Freigeist-WideRegular'; }

.bottom-wrapper {
  display: inline-block; }

.bottom-wrapper a {
  text-decoration: none; }

.bottom-wrapper--about {
  padding-top: 18px; }

.bottom-wrapper--lang {
  margin-left: 135px; }
  @media only screen and (max-width: 575px) {
    .bottom-wrapper--lang {
      margin-left: 25vw; } }

.bottom-wrapper--locations {
  padding-bottom: 20px; }

.bottom-wrapper--lang ul {
  list-style-type: none; }

.bottom-wrapper--left {
  display: flex;
  text-transform: capitalize; }

.bottom-wrapper--left ul {
  list-style-type: none;
  line-height: 1.6; }

.bottom-wrapper--right {
  float: right;
  margin-right: 7vw;
  margin-bottom: 10px; }
  @media only screen and (max-width: 575px) {
    .bottom-wrapper--right {
      text-align: center;
      width: 100%;
      margin: 0 auto; } }
  .bottom-wrapper--right a {
    text-decoration: none; }

.credit-cards {
  margin-top: 20px; }

.credit-cards ul {
  list-style-type: none;
  display: flex;
  padding-left: 0; }
  @media only screen and (max-width: 575px) {
    .credit-cards ul {
      padding-left: 9px;
      justify-content: space-evenly; } }

.credit-cards li {
  padding-right: 10px; }
  .credit-cards li:last-child {
    padding: 0; }

.terms {
  margin-top: 20px; }
  .terms ul {
    line-height: 1.6; }

.terms li {
  padding-right: 10px; }
  .terms li:last-child {
    padding: 0; }

.terms-list {
  display: flex;
  margin: 0 auto; }
  @media only screen and (max-width: 575px) {
    .terms-list {
      flex-direction: column; } }
  .terms-list a {
    text-decoration: none; }

.terms ul {
  list-style-type: none;
  padding-left: 0; }

.footer-logo {
  text-align: center;
  margin-bottom: 150px; }
  .footer-logo img {
    height: 27px; }

.footer-navigation {
  text-align: left; }
  @media only screen and (min-width: 768px) {
    .footer-navigation {
      text-align: center;
      margin-bottom: 25px; } }
  .footer-navigation ul {
    font-size: 0;
    list-style: none;
    margin: 0;
    padding: 0; }
    @media (min-width: 1200px) {
      .footer-navigation ul {
        display: flex;
        justify-content: center; } }
    .footer-navigation ul > li {
      text-align: center;
      margin-bottom: 20px; }
      @media (min-width: 1200px) {
        .footer-navigation ul > li {
          margin-bottom: 0; } }
      .footer-navigation ul > li > a {
        padding: 0 20px;
        text-transform: uppercase;
        color: #4d4d4d;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.05em;
        text-decoration: none; }

.btn-holder--footer a {
  display: block;
  padding: 0;
  text-transform: uppercase;
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-decoration: none; }
  @media only screen and (min-width: 768px) {
    .btn-holder--footer a {
      padding: 0 20px; } }

.footer-bottom {
  padding-top: 25px; }
  .footer-bottom h6 {
    color: #494949;
    font-size: 16px;
    font-weight: 700;
    margin: 0; }
  .footer-bottom p {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300; }

.message-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  z-index: 100; }
  .message-bottom .btn {
    background: #333;
    padding: 0 15px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: 'slick';
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: black;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: black;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 0; }
  [dir='rtl'] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir='rtl'] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: 0; }
  [dir='rtl'] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir='rtl'] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/**
Ion.RangeSlider, 2.3.0
© Denis Ineshin, 2010 - 2018, IonDen.com
Build date: 2018-12-12 00:07:25
*/
.irs {
  position: relative;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px; }

.irs-line {
  position: relative;
  display: block;
  overflow: hidden;
  outline: none !important; }

.irs-bar {
  position: absolute;
  display: block;
  left: 0;
  width: 0; }

.irs-shadow {
  position: absolute;
  display: none;
  left: 0;
  width: 0; }

.irs-handle {
  position: absolute;
  display: block;
  box-sizing: border-box;
  cursor: default;
  z-index: 1; }

.irs-handle.type_last {
  z-index: 2; }

.irs-min,
.irs-max {
  position: absolute;
  display: block;
  cursor: default; }

.irs-min {
  left: 0; }

.irs-max {
  right: 0; }

.irs-from,
.irs-to,
.irs-single {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  cursor: default;
  white-space: nowrap; }

.irs-grid {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px; }

.irs-with-grid .irs-grid {
  display: block; }

.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000; }

.irs-grid-pol.small {
  height: 4px; }

.irs-grid-text {
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  text-align: center;
  font-size: 9px;
  line-height: 9px;
  padding: 0 3px;
  color: #000; }

.irs-disable-mask {
  position: absolute;
  display: block;
  top: 0;
  left: -1%;
  width: 102%;
  height: 100%;
  cursor: default;
  background: rgba(0, 0, 0, 0);
  z-index: 2; }

.lt-ie9 .irs-disable-mask {
  background: #000;
  filter: alpha(opacity=0);
  cursor: not-allowed; }

.irs-disabled {
  opacity: 0.4; }

.irs-hidden-input {
  position: absolute !important;
  display: block !important;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  outline: none !important;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: transparent !important; }

.irs--flat {
  height: 40px; }

.irs--flat.irs-with-grid {
  height: 60px; }

.irs--flat .irs-line {
  top: 25px;
  height: 12px;
  background-color: #e1e4e9;
  border-radius: 4px; }

.irs--flat .irs-bar {
  top: 25px;
  height: 12px;
  background-color: #ed5565; }

.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px; }

.irs--flat .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: #e1e4e9; }

.irs--flat .irs-handle {
  top: 22px;
  width: 16px;
  height: 18px;
  background-color: transparent; }

.irs--flat .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  background-color: #da4453; }

.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: #a43540; }

.irs--flat .irs-min,
.irs--flat .irs-max {
  top: 0;
  padding: 1px 3px;
  color: #999;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  background-color: #e1e4e9;
  border-radius: 4px; }

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  color: white;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #ed5565;
  border-radius: 4px; }

.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  position: absolute;
  display: block;
  content: '';
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #ed5565; }

.irs--flat .irs-grid-pol {
  background-color: #e1e4e9; }

.irs--flat .irs-grid-text {
  color: #999; }

.irs--big {
  height: 55px; }

.irs--big.irs-with-grid {
  height: 70px; }

.irs--big .irs-line {
  top: 33px;
  height: 12px;
  background-color: white;
  background: linear-gradient(to bottom, #ddd -50%, white 150%);
  border: 1px solid #ccc;
  border-radius: 12px; }

.irs--big .irs-bar {
  top: 33px;
  height: 12px;
  background-color: #92bce0;
  border: 1px solid #428bca;
  background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5); }

.irs--big .irs-bar--single {
  border-radius: 12px 0 0 12px; }

.irs--big .irs-shadow {
  height: 1px;
  bottom: 16px;
  background-color: rgba(66, 139, 202, 0.5); }

.irs--big .irs-handle {
  top: 25px;
  width: 30px;
  height: 30px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #cbcfd5;
  background: linear-gradient(to bottom, white 0%, #b4b9be 30%, white 100%);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
  border-radius: 30px; }

.irs--big .irs-handle.state_hover,
.irs--big .irs-handle:hover {
  border-color: rgba(0, 0, 0, 0.45);
  background-color: #939ba7;
  background: linear-gradient(to bottom, white 0%, #919ba5 30%, white 100%); }

.irs--big .irs-min,
.irs--big .irs-max {
  top: 0;
  padding: 1px 5px;
  color: white;
  text-shadow: none;
  background-color: #9f9f9f;
  border-radius: 3px; }

.irs--big .irs-from,
.irs--big .irs-to,
.irs--big .irs-single {
  color: white;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #428bca;
  background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
  border-radius: 3px; }

.irs--big .irs-grid-pol {
  background-color: #428bca; }

.irs--big .irs-grid-text {
  color: #428bca; }

.irs--modern {
  height: 55px; }

.irs--modern.irs-with-grid {
  height: 55px; }

.irs--modern .irs-line {
  top: 25px;
  height: 5px;
  background-color: #d1d6e0;
  background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
  border: 1px solid #a3adc1;
  border-bottom-width: 0;
  border-radius: 5px; }

.irs--modern .irs-bar {
  top: 25px;
  height: 5px;
  background: #20b426;
  background: linear-gradient(to bottom, #20b426 0%, #18891d 100%); }

.irs--modern .irs-bar--single {
  border-radius: 5px 0 0 5px; }

.irs--modern .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(209, 214, 224, 0.5); }

.irs--modern .irs-handle {
  top: 37px;
  width: 12px;
  height: 13px;
  border: 1px solid #a3adc1;
  border-top-width: 0;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 3px 3px; }

.irs--modern .irs-handle > i:nth-child(1) {
  position: absolute;
  display: block;
  top: -4px;
  left: 1px;
  width: 6px;
  height: 6px;
  border: 1px solid #a3adc1;
  background: white;
  transform: rotate(45deg); }

.irs--modern .irs-handle > i:nth-child(2) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 10px;
  height: 12px;
  background: #e9e6e6;
  background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
  border-radius: 0 0 3px 3px; }

.irs--modern .irs-handle > i:nth-child(3) {
  position: absolute;
  display: block;
  box-sizing: border-box;
  top: 3px;
  left: 3px;
  width: 4px;
  height: 5px;
  border-left: 1px solid #a3adc1;
  border-right: 1px solid #a3adc1; }

.irs--modern .irs-handle.state_hover,
.irs--modern .irs-handle:hover {
  border-color: #7685a2;
  background: #c3c7cd;
  background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%); }

.irs--modern .irs-handle.state_hover > i:nth-child(1),
.irs--modern .irs-handle:hover > i:nth-child(1) {
  border-color: #7685a2; }

.irs--modern .irs-handle.state_hover > i:nth-child(3),
.irs--modern .irs-handle:hover > i:nth-child(3) {
  border-color: #48536a; }

.irs--modern .irs-min,
.irs--modern .irs-max {
  top: 0;
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  color: white;
  background-color: #d1d6e0;
  border-radius: 5px; }

.irs--modern .irs-from,
.irs--modern .irs-to,
.irs--modern .irs-single {
  font-size: 10px;
  line-height: 1.333;
  text-shadow: none;
  padding: 1px 5px;
  background-color: #20b426;
  color: white;
  border-radius: 5px; }

.irs--modern .irs-from:before,
.irs--modern .irs-to:before,
.irs--modern .irs-single:before {
  position: absolute;
  display: block;
  content: '';
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #20b426; }

.irs--modern .irs-grid {
  height: 25px; }

.irs--modern .irs-grid-pol {
  background-color: #dedede; }

.irs--modern .irs-grid-text {
  color: silver;
  font-size: 13px; }

.irs--sharp {
  height: 50px;
  font-size: 12px;
  line-height: 1; }

.irs--sharp.irs-with-grid {
  height: 57px; }

.irs--sharp .irs-line {
  top: 30px;
  height: 2px;
  background-color: black;
  border-radius: 2px; }

.irs--sharp .irs-bar {
  top: 30px;
  height: 2px;
  background-color: #ee22fa; }

.irs--sharp .irs-bar--single {
  border-radius: 2px 0 0 2px; }

.irs--sharp .irs-shadow {
  height: 1px;
  bottom: 21px;
  background-color: rgba(0, 0, 0, 0.5); }

.irs--sharp .irs-handle {
  top: 25px;
  width: 10px;
  height: 10px;
  background-color: #a804b2; }

.irs--sharp .irs-handle > i:first-child {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: #a804b2; }

.irs--sharp .irs-handle.state_hover,
.irs--sharp .irs-handle:hover {
  background-color: black; }

.irs--sharp .irs-handle.state_hover > i:first-child,
.irs--sharp .irs-handle:hover > i:first-child {
  border-top-color: black; }

.irs--sharp .irs-min,
.irs--sharp .irs-max {
  color: white;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 4px;
  opacity: 0.4;
  background-color: #a804b2;
  border-radius: 2px; }

.irs--sharp .irs-from,
.irs--sharp .irs-to,
.irs--sharp .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 4px;
  background-color: #a804b2;
  color: white;
  border-radius: 2px; }

.irs--sharp .irs-from:before,
.irs--sharp .irs-to:before,
.irs--sharp .irs-single:before {
  position: absolute;
  display: block;
  content: '';
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #a804b2; }

.irs--sharp .irs-grid {
  height: 25px; }

.irs--sharp .irs-grid-pol {
  background-color: #dedede; }

.irs--sharp .irs-grid-text {
  color: silver;
  font-size: 13px; }

.irs--round {
  height: 50px; }

.irs--round.irs-with-grid {
  height: 65px; }

.irs--round .irs-line {
  top: 36px;
  height: 4px;
  background-color: #dee4ec;
  border-radius: 4px; }

.irs--round .irs-bar {
  top: 36px;
  height: 4px;
  background-color: #006cfa; }

.irs--round .irs-bar--single {
  border-radius: 4px 0 0 4px; }

.irs--round .irs-shadow {
  height: 4px;
  bottom: 21px;
  background-color: rgba(222, 228, 236, 0.5); }

.irs--round .irs-handle {
  top: 26px;
  width: 24px;
  height: 24px;
  border: 4px solid #006cfa;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3); }

.irs--round .irs-handle.state_hover,
.irs--round .irs-handle:hover {
  background-color: #f0f6ff; }

.irs--round .irs-min,
.irs--round .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px; }

.irs--round .irs-from,
.irs--round .irs-to,
.irs--round .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: #006cfa;
  color: white;
  border-radius: 4px; }

.irs--round .irs-from:before,
.irs--round .irs-to:before,
.irs--round .irs-single:before {
  position: absolute;
  display: block;
  content: '';
  bottom: -6px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -3px;
  overflow: hidden;
  border: 3px solid transparent;
  border-top-color: #006cfa; }

.irs--round .irs-grid {
  height: 25px; }

.irs--round .irs-grid-pol {
  background-color: #dedede; }

.irs--round .irs-grid-text {
  color: silver;
  font-size: 13px; }

.irs--square {
  height: 50px; }

.irs--square.irs-with-grid {
  height: 60px; }

.irs--square .irs-line {
  top: 31px;
  height: 4px;
  background-color: #dedede; }

.irs--square .irs-bar {
  top: 31px;
  height: 4px;
  background-color: black; }

.irs--square .irs-shadow {
  height: 2px;
  bottom: 21px;
  background-color: #dedede; }

.irs--square .irs-handle {
  top: 25px;
  width: 16px;
  height: 16px;
  border: 3px solid black;
  background-color: white;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }

.irs--square .irs-handle.state_hover,
.irs--square .irs-handle:hover {
  background-color: #f0f6ff; }

.irs--square .irs-min,
.irs--square .irs-max {
  color: #333;
  font-size: 14px;
  line-height: 1;
  top: 0;
  padding: 3px 5px;
  background-color: rgba(0, 0, 0, 0.1); }

.irs--square .irs-from,
.irs--square .irs-to,
.irs--square .irs-single {
  font-size: 14px;
  line-height: 1;
  text-shadow: none;
  padding: 3px 5px;
  background-color: black;
  color: white; }

.irs--square .irs-grid {
  height: 25px; }

.irs--square .irs-grid-pol {
  background-color: #dedede; }

.irs--square .irs-grid-text {
  color: silver;
  font-size: 11px; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  width: 100% !important; }

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 47px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px; }

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative; }

.select2-container[dir='rtl']
.select2-selection--single
.select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px; }

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none; }

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  padding: 0; }

.select2-container
.select2-search--inline
.select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-dropdown {
  background-color: white;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0; }

.select-country .select2-results__options li:first-child {
  display: none; }

.select-region .select2-results__options li:first-child {
  display: none; }

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none; }

.select2-results__option[aria-selected] {
  cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-search--dropdown {
  display: block;
  padding: 4px; }

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box; }

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none; }

.select2-search--dropdown.select2-search--hide {
  display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
  margin-top: -1px; }

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 2px; }

.select2-container--default
.select2-selection--single
.select2-selection__rendered {
  line-height: 47px; }

.select2-container--default
.select2-selection--single
.select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold; }

.select2-container--default
.select2-selection--single
.select2-selection__placeholder {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 300; }

.select2-container--default
.select2-selection--single
.select2-selection__arrow {
  height: calc(100% - 2px);
  position: absolute;
  top: 1px;
  right: 12px;
  width: 20px; }

.select2-container--default
.select2-selection--single
.select2-selection__arrow
b {
  background-image: url("../assets/images/dropdown-icon.svg");
  background-repeat: no-repeat;
  width: 20px;
  height: 12px;
  left: 50%;
  margin-left: -10px;
  margin-top: -6px;
  position: absolute;
  top: 50%; }

.select2-container--default[dir='rtl']
.select2-selection--single
.select2-selection__clear {
  float: left; }

.select2-container--default[dir='rtl']
.select2-selection--single
.select2-selection__arrow {
  left: 1px;
  right: auto; }

.select2-container--default.select2-container--disabled
.select2-selection--single {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled
.select2-selection--single
.select2-selection__clear {
  display: none; }

.select2-container--default.select2-container--open
.select2-selection--single
.select2-selection__arrow
b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--default .select2-selection--multiple {
  background-color: white;
  background-image: url("../assets/images/dropdown-icon.svg");
  background-position: 98% center;
  background-repeat: no-repeat;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  cursor: pointer; }

.select2-container--default
.select2-selection--multiple
.select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 6px 30px 6px 20px;
  width: 100%; }

.select2-container--default
.select2-selection--multiple
.select2-selection__rendered
li {
  list-style: none; }

.select2-container--default
.select2-selection--multiple
.select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px; }

.select2-container--default
.select2-selection--multiple
.select2-selection__choice {
  position: relative;
  background-color: #fff;
  border: 1px solid #e6333d;
  border-radius: 25px;
  cursor: default;
  margin-right: 5px;
  padding: 5px 30px 5px 10px;
  font-size: 13px;
  text-transform: uppercase; }

.select2-container--default
.select2-selection--multiple
.select2-selection__choice__remove {
  position: absolute;
  right: 5px;
  top: 60%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url(../assets/images/mobile_close.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 0;
  cursor: pointer;
  margin: -2px 0 0 0; }

.select2-container--default
.select2-selection--multiple
.select2-selection__choice__remove:hover {
  color: #333; }

.select2-container--default[dir='rtl']
.select2-selection--multiple
.select2-selection__choice,
.select2-container--default[dir='rtl']
.select2-selection--multiple
.select2-search--inline {
  float: right; }

.select2-container--default[dir='rtl']
.select2-selection--multiple
.select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; }

.select2-container--default[dir='rtl']
.select2-selection--multiple
.select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--default.select2-container--focus
.select2-selection--multiple {
  outline: 0; }

.select2-container--default.select2-container--disabled
.select2-selection--multiple {
  background-color: #eee;
  cursor: default; }

.select2-container--default.select2-container--disabled
.select2-selection__choice__remove {
  display: none; }

.select2-container--default.select2-container--open.select2-container--above
.select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above
.select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--default.select2-container--open.select2-container--below
.select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below
.select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa; }

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
  height: 33px;
  line-height: 33px; }

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--default .select2-results__option[role='group'] {
  padding: 0; }

.select2-container--default .select2-results__option[aria-disabled='true'] {
  color: #999; }

.select2-container--default .select2-results__option[aria-selected='true'] {
  background-color: rgba(128, 128, 128, 0.3); }

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em; }

.select2-container--default
.select2-results__option
.select2-results__option
.select2-results__group {
  padding-left: 0; }

.select2-container--default
.select2-results__option
.select2-results__option
.select2-results__option {
  margin-left: -1em;
  padding-left: 2em; }

.select2-container--default
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option {
  margin-left: -2em;
  padding-left: 3em; }

.select2-container--default
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option {
  margin-left: -3em;
  padding-left: 4em; }

.select2-container--default
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option {
  margin-left: -4em;
  padding-left: 5em; }

.select2-container--default
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option
.select2-results__option {
  margin-left: -5em;
  padding-left: 6em; }

.select2-container--default
.select2-results__option--highlighted[aria-selected] {
  background-color: #e6333d;
  color: white; }

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb; }

.select2-container--classic
.select2-selection--single
.select2-selection__rendered {
  color: #444;
  line-height: 28px; }

.select2-container--classic
.select2-selection--single
.select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px; }

.select2-container--classic
.select2-selection--single
.select2-selection__placeholder {
  color: #999; }

.select2-container--classic
.select2-selection--single
.select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0); }

.select2-container--classic
.select2-selection--single
.select2-selection__arrow
b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0; }

.select2-container--classic[dir='rtl']
.select2-selection--single
.select2-selection__clear {
  float: left; }

.select2-container--classic[dir='rtl']
.select2-selection--single
.select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto; }

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open
.select2-selection--single
.select2-selection__arrow {
  background: transparent;
  border: none; }

.select2-container--classic.select2-container--open
.select2-selection--single
.select2-selection__arrow
b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; }

.select2-container--classic.select2-container--open.select2-container--above
.select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0); }

.select2-container--classic.select2-container--open.select2-container--below
.select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0); }

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0; }

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb; }

.select2-container--classic
.select2-selection--multiple
.select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px; }

.select2-container--classic
.select2-selection--multiple
.select2-selection__clear {
  display: none; }

.select2-container--classic
.select2-selection--multiple
.select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; }

.select2-container--classic
.select2-selection--multiple
.select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; }

.select2-container--classic
.select2-selection--multiple
.select2-selection__choice__remove:hover {
  color: #555; }

.select2-container--classic[dir='rtl']
.select2-selection--multiple
.select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto; }

.select2-container--classic[dir='rtl']
.select2-selection--multiple
.select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; }

.select2-container--classic.select2-container--open
.select2-selection--multiple {
  border: 1px solid #5897fb; }

.select2-container--classic.select2-container--open.select2-container--above
.select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.select2-container--classic.select2-container--open.select2-container--below
.select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0; }

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none; }

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent; }

.select2-container--classic .select2-dropdown--above {
  border-bottom: none; }

.select2-container--classic .select2-dropdown--below {
  border-top: none; }

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; }

.select2-container--classic .select2-results__option[role='group'] {
  padding: 0; }

.select2-container--classic .select2-results__option[aria-disabled='true'] {
  color: grey; }

.select2-container--classic
.select2-results__option--highlighted[aria-selected] {
  background-color: #3875d7;
  color: white; }

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; }

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb; }

.spinner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
  visibility: hidden;
  opacity: 0; }
  .spinner.show {
    visibility: visible;
    opacity: 1; }

.loader {
  position: relative;
  left: 50%;
  top: 50%;
  font-size: 9px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  text-indent: -9999em;
  animation: spinner 1.1s infinite ease;
  transform: translate(-50%, -50%), translateZ(0); }

@keyframes spinner {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #fff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7); }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #fff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5); }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #fff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #fff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #fff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #fff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #fff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2); }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #fff; } }

#cookieConsent {
  font-family: 'Freigeist-WideRegular';
  background-color: #e6333d;
  min-height: 26px;
  font-size: 15px;
  color: #fff;
  line-height: 26px;
  padding: 8px 5vw 8px 5vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 9999; }

#cookieConsent a {
  color: #fff;
  font-weight: bold; }

.access-buttons {
  display: flex;
  align-items: center;
  justify-content: center; }
  .access-buttons button:first-child {
    margin-right: 30px; }

div.checkbox:not(.checkbox--privacy) label span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%; }

.main {
  background: #fffdfa;
  font-family: 'Freigeist-WideRegular'; }

.main-slider-container {
  position: relative; }
  .main-slider-container .slick-arrow svg {
    fill: #fff; }

.main-slider {
  height: 400px;
  overflow: hidden; }
  @media only screen and (min-width: 768px) {
    .main-slider {
      height: 100vh; } }
  .main-slider .slick-list,
  .main-slider .slick-track {
    height: 100%; }

.main-slider__item {
  position: relative; }
  .main-slider__item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%); }
  .main-slider__item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .main-slider__item:nth-child(2) .main-slider__title, .main-slider__item:nth-child(8) .main-slider__title {
    letter-spacing: 0;
    font-size: 40px; }
    @media only screen and (min-width: 576px) {
      .main-slider__item:nth-child(2) .main-slider__title, .main-slider__item:nth-child(8) .main-slider__title {
        font-size: 80px;
        letter-spacing: 20px; } }

.main-slider__content {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate3d(-50%, 0, 0);
  width: 100%;
  padding: 0 50px;
  text-align: center;
  color: #fff; }
  @media only screen and (min-width: 768px) {
    .main-slider__content {
      width: 90%;
      padding: 0; } }
  @media (min-width: 1200px) {
    .main-slider__content {
      top: 40%;
      width: 75%; } }

.main-slider__title {
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 1;
  letter-spacing: normal; }
  @media only screen and (min-width: 576px) {
    .main-slider__title {
      font-size: 40px;
      letter-spacing: 0.05em; } }
  @media only screen and (min-width: 992px) {
    .main-slider__title {
      font-size: 60px; } }
  @media (min-width: 1200px) {
    .main-slider__title {
      line-height: 1.285714286; } }

.main-slider__subtitle {
  font-size: 22px; }

.main-search-box {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate3d(-50%, 0, 0);
  max-width: 80%;
  width: 100%; }
  @media only screen and (min-width: 768px) {
    .main-search-box {
      max-width: 50%;
      top: 60%; } }
  @media (min-width: 1200px) {
    .main-search-box {
      max-width: 30%; } }
  .main-search-box ul {
    display: none;
    list-style: none;
    padding: 0;
    text-transform: capitalize;
    position: absolute;
    top: 50px;
    right: 0;
    color: #000;
    z-index: 3000;
    max-height: 402px;
    min-width: 250px;
    width: 100%;
    overflow: auto;
    box-shadow: 4px 8px 15px 0px rgba(0, 1, 1, 0.13); }
    .main-search-box ul li {
      padding: 15px 10px;
      width: 100%;
      line-height: 30px;
      font-size: 15px;
      border-bottom: 1px solid #e3e3e3;
      background-color: #fcfcfc; }
      .main-search-box ul li:hover {
        cursor: pointer;
        background: #fff;
        color: #000; }
      .main-search-box ul li a {
        height: auto;
        width: 100%;
        display: block;
        text-decoration: none; }
      .main-search-box ul li:last-child {
        border: none; }

.main-search__form {
  position: relative; }
  .main-search__form .input-field input {
    padding-right: 60px;
    font-size: 14px;
    border-radius: 100px;
    font-weight: 300; }
    .main-search__form .input-field input:focus {
      outline: none; }
    .main-search__form .input-field input::-webkit-input-placeholder {
      font-weight: 300;
      font-size: 14px; }
    .main-search__form .input-field input:-moz-placeholder {
      font-weight: 300;
      font-size: 14px; }
    .main-search__form .input-field input::-moz-placeholder {
      font-weight: 300;
      font-size: 14px; }
    .main-search__form .input-field input:-ms-input-placeholder {
      font-weight: 300;
      font-size: 14px; }
    @media only screen and (min-width: 768px) {
      .main-search__form .input-field input {
        height: 60px;
        line-height: 60px;
        padding-right: 80px; } }

.search-result-header {
  padding-left: 225px; }
  .search-result-header li {
    list-style: none;
    text-decoration: none;
    text-transform: capitalize;
    padding: 7px 5px; }
    .search-result-header li:hover {
      cursor: pointer; }
    .search-result-header li a {
      text-decoration: none;
      border-bottom: 1px solid; }

.main-search-icon {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 100%;
  align-items: center;
  background: transparent;
  border: 0;
  padding: 0 20px; }

.content-section {
  padding: 15px 0; }
  @media only screen and (min-width: 992px) {
    .content-section {
      padding: 30px 0; } }
  @media (min-width: 1200px) {
    .content-section {
      padding: 50px 0 60px; } }

.info-block,
.main-regions-section.skew-items-section.info-block,
.content-section.info-block {
  padding: 0 0 25px 0; }

.content-section.info-block--home {
  padding-top: 0;
  padding-bottom: 15px; }

@media only screen and (min-width: 992px) {
  .content-section.info-block--home {
    padding: 180px 0 80px; } }

.top-padding,
.content-section.info-block.top-padding {
  padding-top: 25px; }

.content-section.info-block--home.top-padding {
  padding-top: 46px;
  padding-bottom: 15px; }
  @media only screen and (min-width: 992px) {
    .content-section.info-block--home.top-padding {
      padding-top: 71px; } }

.content-section.info-block.top-padding.last-info-block {
  padding-bottom: 0; }

.content__title {
  text-align: center;
  font-size: 32px;
  font-weight: 700; }
  @media only screen and (min-width: 768px) {
    .content__title {
      margin: 0 0 30px;
      font-size: 34px; } }

.content__subtitle {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 30px; }
  @media only screen and (min-width: 768px) {
    .content__subtitle {
      font-size: 26px; } }

.text-content {
  font-size: 24px; }

.banner-content-section {
  padding: 90px 0 70px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative; }
  .banner-content-section:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%); }

.banner-blog-main-page {
  display: flex;
  height: 468px;
  margin-bottom: 25px;
  padding: 0; }

.content-section.info-block .banner-main-page__block-link,
.banner-blog-main-page .banner-main-page__block-link {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: none; }
  .content-section.info-block .banner-main-page__block-link h2,
  .content-section.info-block .banner-main-page__block-link h5,
  .banner-blog-main-page .banner-main-page__block-link h2,
  .banner-blog-main-page .banner-main-page__block-link h5 {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none; }
  .content-section.info-block .banner-main-page__block-link h2,
  .banner-blog-main-page .banner-main-page__block-link h2 {
    margin-bottom: 5px; }
  .content-section.info-block .banner-main-page__block-link h3,
  .banner-blog-main-page .banner-main-page__block-link h3 {
    color: #000; }
  .content-section.info-block .banner-main-page__block-link:hover h3,
  .banner-blog-main-page .banner-main-page__block-link:hover h3 {
    color: #000; }

div.banner-content-section {
  padding: 90px 0 70px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 65%;
  position: relative;
  height: 400px; }
  @media only screen and (min-width: 768px) {
    div.banner-content-section {
      height: 600px; } }
  div.banner-content-section:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%); }

.banner-content__inner {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff; }
  @media only screen and (min-width: 768px) {
    .banner-content__inner {
      max-width: 70%; } }
  .banner-content__inner p {
    margin: 0 0 30px;
    font-size: 22px; }

.banner-content__title {
  font-size: 34px;
  font-weight: 700;
  margin: 0 0 50px; }

.banner-content__subtitle {
  font-size: 24px;
  font-weight: 700;
  margin: 0; }

.main-category-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
  padding: 50px 0 0; }

.main-category__item {
  flex: 0 0 100%;
  padding: 0 7px;
  margin-bottom: 14px;
  position: relative; }
  @media only screen and (min-width: 768px) {
    .main-category__item {
      flex: 0 0 50%; } }
  @media only screen and (min-width: 992px) {
    .main-category__item {
      flex: 0 0 25%; } }
  .main-category__item .flag {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: 35%; }
  .main-category__item .checkbox {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    .main-category__item .checkbox label {
      min-height: 100%;
      padding: 0; }
      .main-category__item .checkbox label:before {
        top: 10px;
        left: auto;
        right: 10px;
        transform: none;
        border-color: transparent; }
    .main-category__item .checkbox [type='checkbox']:checked + label {
      border: 1px solid #e6333d; }
      .main-category__item .checkbox [type='checkbox']:checked + label:after {
        top: 13px;
        left: auto;
        right: 15px;
        transform: rotate(45deg); }

.main-category__item-inner {
  position: relative;
  display: flex;
  min-height: 165px;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #fff;
  text-decoration: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%; }
  .main-category__item-inner:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%); }
  .main-category__item-inner:hover, .main-category__item-inner:focus {
    color: #fff !important; }
  @media only screen and (min-width: 576px) {
    .main-category__item-inner {
      min-height: 300px; } }
  @media only screen and (min-width: 768px) {
    .main-category__item-inner {
      min-height: 220px; } }
  @media only screen and (min-width: 992px) {
    .main-category__item-inner {
      min-height: 150px; } }

.main-category__name {
  position: relative; }

.content-items-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 25px; }

.content__item {
  flex: 0 0 100%; }
  @media only screen and (min-width: 768px) {
    .content__item {
      flex: 0 0 33.3333333%; } }
  .content__item + .content__item {
    margin-top: 10px; }
    @media only screen and (min-width: 768px) {
      .content__item + .content__item {
        margin-top: 0; } }
  .content__item p {
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px; }

.content__item-inner {
  display: block;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  text-decoration: none; }

.content__item-image {
  width: 135px;
  height: 135px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 25px; }
  .content__item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.content__item-title {
  min-height: 0; }
  @media only screen and (min-width: 768px) {
    .content__item-title {
      font-size: 20px;
      min-height: 50px; } }
  @media only screen and (min-width: 992px) {
    .content__item-title {
      min-height: 60px;
      font-size: 24px; } }

.main-products-section {
  padding: 25px 0; }

.main-title {
  font-size: 46px;
  font-weight: 700;
  text-align: center;
  margin: 0 0 25px; }

.main-subtitle {
  font-size: 22px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 25px; }

.product__item {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  text-decoration: none; }

.product__item-image {
  overflow: hidden;
  background: #fff;
  border-radius: 10px; }
  .product__item-image img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.product__item-details {
  padding: 10px 0;
  margin-top: auto; }

.product__item-price {
  display: block;
  font-size: 16px;
  color: #808080;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-family: initial; }

span.product__item-name {
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block; }

span.product__estate-name {
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block; }

.main-regions-section {
  padding: 50px 0; }
  .main-regions-section .skew-items-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center; }
    @media only screen and (min-width: 768px) {
      .main-regions-section .skew-items-list {
        flex-direction: row;
        align-items: stretch; } }
  .main-regions-section .skew__item {
    padding: 0 15px;
    margin: 0 0 30px; }
    @media only screen and (min-width: 768px) {
      .main-regions-section .skew__item {
        flex: 0 0 50%; } }
    @media (min-width: 1200px) {
      .main-regions-section .skew__item {
        flex: 0 0 25%; } }
    .main-regions-section .skew__item + .skew__item {
      margin-top: 0; }
  .main-regions-section .skew__item-photo {
    max-height: 250px; }
    @media only screen and (min-width: 992px) {
      .main-regions-section .skew__item-photo {
        flex: 0 0 55%; } }
    @media (min-width: 1200px) {
      .main-regions-section .skew__item-photo {
        max-height: 217px;
        flex: 0 0 55%; } }
    @media (min-width: 1560px) {
      .main-regions-section .skew__item-photo {
        flex: 0 0 55%; } }
  .main-regions-section .skew__item-info {
    padding: 15px;
    align-items: center; }
    @media only screen and (min-width: 768px) {
      .main-regions-section .skew__item-info {
        padding: 15px 15px 15px 0;
        align-items: flex-end; } }
    @media only screen and (min-width: 992px) {
      .main-regions-section .skew__item-info {
        flex: 0 0 45%; } }
    @media (min-width: 1200px) {
      .main-regions-section .skew__item-info {
        flex: 0 0 45%; } }
    @media (min-width: 1560px) {
      .main-regions-section .skew__item-info {
        flex: 0 0 45%; } }
    @media only screen and (min-width: 768px) {
      .main-regions-section .skew__item-info:before {
        width: 75%;
        left: -37%; } }
    @media (min-width: 1200px) {
      .main-regions-section .skew__item-info:before {
        left: -27.5%;
        width: 55%; } }
    @media (min-width: 1560px) {
      .main-regions-section .skew__item-info:before {
        width: 75%;
        left: -37%; } }
    @media only screen and (min-width: 768px) {
      .main-regions-section .skew__item-info .title {
        font-size: 22px; } }
    @media only screen and (min-width: 992px) {
      .main-regions-section .skew__item-info .title {
        font-size: 28px;
        margin: 0 0 15px; } }
    @media only screen and (min-width: 768px) {
      .main-regions-section .skew__item-info .btn--sm {
        font-size: 11px; } }
    @media only screen and (min-width: 992px) {
      .main-regions-section .skew__item-info .btn--sm {
        font-size: 13px; } }
    @media (min-width: 1200px) {
      .main-regions-section .skew__item-info .btn--sm {
        font-size: 11px; } }
    @media (min-width: 1560px) {
      .main-regions-section .skew__item-info .btn--sm {
        font-size: 12px; } }
  .main-regions-section img.flag {
    max-width: 100px; }
    @media only screen and (min-width: 768px) {
      .main-regions-section img.flag {
        max-width: 60%; } }

.regions-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px; }

.region__item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 0 15px; }
  @media only screen and (min-width: 768px) {
    .region__item {
      flex: 0 0 50%; } }
  @media only screen and (min-width: 992px) {
    .region__item {
      flex: 0 0 25%; } }

.region__inner {
  position: relative;
  display: flex;
  min-height: 165px;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden; }
  .region__inner:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%); }
  @media only screen and (min-width: 576px) {
    .region__inner {
      min-height: 300px; } }
  @media only screen and (min-width: 768px) {
    .region__inner {
      min-height: 220px; } }
  @media only screen and (min-width: 992px) {
    .region__inner {
      min-height: 150px;
      font-size: 36px; } }

.region__title {
  position: relative; }

.regions__list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 1px solid #d3d3d3;
  border-top: 0;
  background: #fff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px; }
  .regions__list li {
    flex-grow: 1;
    display: flex; }
    .regions__list li a {
      flex-grow: 1;
      display: flex;
      align-items: center;
      position: relative;
      padding: 10px 30px 10px 10px;
      text-decoration: none;
      font-size: 15px;
      transition: all .3s ease; }
      .regions__list li a:hover, .regions__list li a:focus {
        color: #e6333d; }
        .regions__list li a:hover .icon-arrow, .regions__list li a:focus .icon-arrow {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, -50%, 0); }
      .regions__list li a .icon-arrow {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate3d(-5px, -50%, 0);
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease; }
    .regions__list li + li a {
      border-top: 1px solid #d3d3d3; }

.blog-container {
  padding: 30px 2.5%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover; }

.blog-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media only screen and (min-width: 992px) {
    .blog-content {
      align-items: flex-start; } }

.blog-content__item {
  background: #fff;
  border-radius: 6px;
  padding: 15px 10px 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column; }
  @media (min-width: 1200px) {
    .blog-content__item:nth-of-type(2n) {
      margin-top: 10%; } }
  @media only screen and (min-width: 576px) {
    .blog-content__item {
      max-width: 47.5%; } }
  @media only screen and (min-width: 992px) {
    .blog-content__item {
      max-width: 23.5%; } }
  @media (min-width: 1560px) {
    .blog-content__item {
      max-width: 320px; } }

.blog__item-header {
  margin-bottom: 10px; }
  .blog__item-header p {
    margin: 0; }

.blog-tags-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .blog-tags-list li {
    margin-right: 5px; }
  .blog-tags-list a {
    color: #0077cc; }

.blog__item-image {
  max-width: 100%; }

.footer-shop-name-social {
  display: flex;
  justify-content: center;
  align-items: center; }
  .footer-shop-name-social h6 {
    margin-right: 10px; }

.info-mail {
  text-decoration: none; }

.footer-social-link {
  display: inline-block;
  width: 20px;
  height: 20px;
  opacity: 0.7; }

.footer-social-link--facebook {
  margin-right: 10px;
  background: transparent url("../assets/images/facebook.svg") 50% no-repeat;
  background-size: contain; }

.footer-social-link--instagram {
  background: transparent url("../assets/images/instagram.svg") 50% no-repeat;
  background-size: contain; }

.block-two-images {
  position: relative;
  padding: 0 15px; }
  .block-two-images .link--two-images {
    position: relative;
    display: block;
    margin-bottom: 16px; }
    .block-two-images .link--two-images:last-child {
      margin-bottom: 0; }
    @media only screen and (min-width: 992px) {
      .block-two-images .link--two-images {
        left: 50%;
        width: 50%;
        max-width: 950px;
        margin-bottom: 0; } }
  @media only screen and (min-width: 992px) {
    .block-two-images .link--two-images-shop {
      transform: translateX(-90%); }
    .block-two-images .link--two-images-visit {
      transform: translate(-10%, -33%); } }
  .block-two-images .img-two-images-img {
    vertical-align: top; }
  .block-two-images .label-shop {
    top: 10%;
    left: 10%;
    color: white;
    font-size: 42px;
    position: absolute; }
  .block-two-images .label-visit {
    top: 10%;
    left: 10%;
    color: white;
    font-size: 42px;
    position: absolute; }
    @media only screen and (min-width: 992px) {
      .block-two-images .label-visit {
        left: auto;
        right: 10%; } }

.main-block {
  position: relative;
  background-size: cover; }
  @media (min-width: 1200px) {
    .main-block {
      display: flex;
      align-items: flex-end;
      min-height: 98vh;
      background-image: url(/assets/images/main-bg.jpeg); } }
  .main-block .main-text-sub {
    display: flex;
    padding: 24px 15px;
    flex-direction: column;
    justify-content: center;
    background-image: url(/assets/images/main-sub-block.jpeg);
    background-size: cover; }
    @media only screen and (min-width: 992px) {
      .main-block .main-text-sub {
        padding: 70px 80px; } }
    @media (min-width: 1200px) {
      .main-block .main-text-sub {
        min-width: 638px;
        min-height: 336px;
        padding: 24px;
        margin-left: 7%;
        margin-bottom: 5%; } }
    .main-block .main-text-sub .main-text-inner {
      margin-top: 0;
      margin-bottom: 24px;
      color: white;
      white-space: pre-line;
      font-size: 22px; }
      @media (min-width: 1200px) {
        .main-block .main-text-sub .main-text-inner {
          font-size: 20px;
          text-align: center; } }
    .main-block .main-text-sub .main-text-inner:last-child {
      margin-bottom: 0; }

.video {
  width: 100%; }
  @media only screen and (max-width: 575px) {
    .video {
      display: none; } }
  @media only screen and (min-width: 576px) {
    .video {
      width: 90%; } }
  @media only screen and (min-width: 768px) {
    .video {
      width: 90%; } }
  @media only screen and (min-width: 992px) {
    .video {
      width: 90%; } }

.video-0 {
  width: 100%; }

.video-1 {
  width: 100%;
  border-radius: 15px; }

.shop-button {
  height: 34px;
  background: #e8bf1d !important;
  margin-top: 86px; }

.shop-button-text {
  line-height: 34px !important;
  color: black; }

.shop-button-link {
  padding: 0 40px !important; }

.label::before {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 1.5em; }

.images-section {
  margin-bottom: 30px; }
  @media only screen and (max-width: 575px) {
    .images-section {
      margin-top: 2rem; } }

.accordion {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #211e4a;
  max-width: 90%;
  margin: 0 auto;
  padding: 50px;
  color: white;
  border-radius: 10px;
  margin-block-end: 34px; }
  .accordion h1 {
    text-align: center;
    font-weight: 100;
    padding-bottom: 7rem; }

.acc-item {
  margin-bottom: 20px; }
  .acc-item h2 {
    font-size: 21px; }

.acc-title {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 100; }

.acc-title::after {
  content: '+';
  color: #e8bf1d;
  font-size: 40px;
  margin-left: auto; }

.active-header::after {
  transform: rotate(45deg);
  transition: ease 500ms; }

.acc-answer {
  max-height: 0;
  overflow: hidden;
  transition: 500ms ease max-height; }
  @media only screen and (max-width: 575px) {
    .acc-answer {
      overflow: scroll; } }

.acc-answer p {
  margin-bottom: 10px; }

.acc-answer.active-header {
  max-height: 40rem;
  transition: 500ms ease; }

.top__section {
  background: #fffdfa; }
  @media (min-width: 1200px) {
    .top__section {
      margin-top: -82px !important; } }
  @media (min-width: 1560px) {
    .top__section {
      margin-top: -82px !important; } }
  @media only screen and (min-width: 992px) {
    .top__section {
      width: 90%;
      margin: 0 auto; } }
  @media only screen and (min-width: 768px) {
    .top__section {
      width: 90%;
      margin: 0 auto;
      margin-top: -82px !important; } }
  @media only screen and (min-width: 576px) {
    .top__section {
      width: 90%;
      margin: 0 auto;
      margin-top: 0; } }
  @media only screen and (max-width: 575px) {
    .top__section {
      margin-top: 0;
      background-image: url("../assets/images/hand.png");
      background-size: cover;
      height: 110vh;
      color: white; } }

.top__section h1 {
  color: #211e4a;
  padding-top: 14px;
  font-size: 21px;
  margin-top: 45px; }
  @media only screen and (max-width: 575px) {
    .top__section h1 {
      margin-top: 0; } }

.top__section h2 {
  color: #211e4a;
  padding-top: 14px;
  font-size: 21px; }
  @media only screen and (max-width: 575px) {
    .top__section h2 {
      margin-top: 0; } }

.top__section-bottom {
  margin-top: 200px; }

.top__section-title {
  text-align: center;
  padding-top: 50px; }
  @media only screen and (max-width: 575px) {
    .top__section-title .top__section-title1 {
      color: white;
      text-align: left;
      padding-left: 20px;
      padding-top: 52vh; } }
  .top__section-title .top__section-title2 {
    display: none; }
    @media only screen and (max-width: 575px) {
      .top__section-title .top__section-title2 {
        display: block;
        color: white;
        text-align: left;
        padding-left: 20px; } }

.top__section-ul {
  color: #211e4a;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: space-between;
  padding-left: 0; }

.top__section-li---img {
  margin-top: 17px; }

.top__section-li--text {
  font-size: 25px;
  font-weight: 100;
  margin: 0; }

.top__section-li--text-sm {
  margin-bottom: 0;
  margin-top: 86px;
  font-weight: 400; }

.pictures {
  display: flex; }
  @media (min-width: 1560px) {
    .pictures {
      width: 100% !important;
      margin-top: 90px;
      color: #211e4a;
      flex-direction: row !important; } }
  @media (min-width: 1200px) {
    .pictures {
      width: 100% !important;
      margin-top: 90px !important;
      color: #211e4a;
      flex-direction: row !important; } }
  @media only screen and (min-width: 768px) {
    .pictures {
      width: 90%;
      margin: 0px auto;
      flex-direction: row !important; } }
  @media only screen and (min-width: 576px) {
    .pictures {
      width: 90%;
      margin: 0px auto;
      flex-direction: column; } }
  @media only screen and (max-width: 575px) {
    .pictures {
      flex-direction: column; } }
  .pictures img {
    object-fit: cover; }
    @media (min-width: 1560px) {
      .pictures img {
        width: 100%;
        object-fit: cover; } }

.content {
  display: flex; }
  @media only screen and (max-width: 575px) {
    .content {
      padding-bottom: 10px; } }

.content {
  justify-content: space-between;
  position: absolute;
  top: 50%;
  right: 50%;
  translate: 50%;
  z-index: 10;
  width: 100%;
  text-align: center;
  color: white; }

.content-right {
  justify-content: space-between;
  position: absolute;
  top: 50%;
  right: 50%;
  translate: 50%;
  z-index: 10;
  width: 100%;
  text-align: center;
  color: white; }

.content-img--left {
  position: relative; }

.content-img--right {
  position: relative; }

.content-img {
  height: 825px;
  border-radius: 10px;
  max-height: 826px;
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden; }
  @media only screen and (max-width: 575px) {
    .content-img {
      height: 300px;
      width: 100%; } }
  @media only screen and (min-width: 576px) {
    .content-img {
      height: 400px;
      width: 100%; } }
  @media (min-width: 1200px) {
    .content-img {
      height: 825px;
      width: 100%; } }
  @media (min-width: 1560px) {
    .content-img {
      height: 825px;
      width: 100%; } }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgba(232, 196, 29, 0.7);
  border-radius: 10px; }

.overlay-right {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgba(116, 4, 43, 0.7);
  background-blend-mode: multiply;
  border-radius: 10px; }

.content-img--left:hover .content-img {
  opacity: 0.8;
  box-shadow: 23px 12px 52px black; }

.content-img--left:hover .overlay {
  opacity: 1; }

.content-img--right:hover .content-img {
  opacity: 0.8;
  box-shadow: 23px 12px 52px black; }

.content-img--right:hover .overlay-right {
  opacity: 1; }

.left-img {
  margin-right: 15px; }
  @media only screen and (max-width: 575px) {
    .left-img {
      width: 90%;
      margin: 0 auto;
      padding-bottom: 2rem; } }
  @media only screen and (min-width: 576px) {
    .left-img {
      width: 100%; } }
  @media only screen and (min-width: 768px) {
    .left-img {
      width: 50% !important; } }
  @media (min-width: 1200px) {
    .left-img {
      width: 50% !important; } }
  @media (min-width: 1560px) {
    .left-img {
      width: 50% !important; } }

.left-img {
  position: relative; }

.img--title {
  font-size: 50px;
  font-weight: 900; }
  @media screen and (max-width: 1110px) and (min-width: 768px) {
    .img--title {
      display: flex;
      align-items: center;
      font-size: unset; } }
  @media only screen and (max-width: 575px) {
    .img--title {
      font-size: 30px;
      font-weight: 900;
      text-align: center;
      padding-bottom: 2rem; } }
  .img--title a {
    text-decoration: none; }

@media (min-width: 1200px) {
  .header__logo {
    padding-top: 100px;
    transition-delay: 200ms;
    transition-property: padding-top; } }

@media (min-width: 1200px) {
  .header__logo img {
    height: 100px;
    transition-delay: 200ms;
    transition-property: height; } }

@media (min-width: 1200px) {
  .header {
    margin-top: -50px;
    transition-delay: 200ms;
    transition-property: margin-top; } }

.img-title-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-shadow: 2px 2px 4px #000000; }

.left-img--text {
  margin: auto;
  font-size: 25px;
  font-weight: 600;
  padding-block-start: 12px; }
  .left-img--text a {
    text-decoration: none; }
  @media screen and (max-width: 1110px) and (min-width: 768px) {
    .left-img--text {
      max-width: 25vw; } }
  @media screen and (max-width: 767px) and (min-width: 576px) {
    .left-img--text {
      max-width: 50vw; } }
  @media screen and (max-width: 1410px) and (min-width: 1111px) {
    .left-img--text {
      max-width: 30vw; } }
  @media only screen and (max-width: 575px) {
    .left-img--text {
      display: none; } }
  @media only screen and (min-width: 576px) {
    .left-img--text {
      font-size: 12px; } }
  @media (min-width: 1200px) {
    .left-img--text {
      display: initial !important;
      margin: auto;
      font-size: 15px; } }
  @media (min-width: 1560px) {
    .left-img--text {
      display: initial !important;
      font-size: 16px; } }

.right-img--text {
  margin: auto;
  font-size: 25px;
  font-weight: 600;
  padding-block-start: 12px; }
  .right-img--text a {
    text-decoration: none; }
  @media only screen and (max-width: 575px) {
    .right-img--text {
      display: none; } }
  @media screen and (max-width: 1110px) and (min-width: 768px) {
    .right-img--text {
      max-width: 16vw; } }
  @media only screen and (min-width: 576px) {
    .right-img--text {
      font-size: 12px; } }
  @media (min-width: 1200px) {
    .right-img--text {
      display: initial !important;
      font-size: 15px; } }
  @media (min-width: 1560px) {
    .right-img--text {
      display: initial !important;
      font-size: 16px; } }

.content-icon {
  height: 106px;
  width: 99px;
  background-repeat: no-repeat;
  text-decoration: none; }
  @media only screen and (max-width: 575px) {
    .content-icon {
      margin-left: auto; } }

.content-icon--left {
  background-image: url("/assets/images/green-sticker.png");
  background-size: 93px; }
  .content-icon--left > p {
    text-align: center;
    max-width: 90px;
    font-size: 11px;
    padding: 16px 5px; }
  @media only screen and (min-width: 576px) {
    .content-icon--left {
      margin-left: auto; } }
  @media only screen and (min-width: 768px) {
    .content-icon--left {
      margin-left: auto; } }

.content-icon--right {
  background-image: url("/assets/images/handmade.png");
  background-size: 83px;
  text-align: center; }
  .content-icon--right > p {
    font-size: 11px;
    padding: 21px 13px 0 0; }

.right-img {
  width: 40%; }
  @media only screen and (max-width: 575px) {
    .right-img {
      width: 90%;
      margin: 0 auto; } }
  @media only screen and (min-width: 576px) {
    .right-img {
      width: 100%; } }
  @media only screen and (min-width: 768px) {
    .right-img {
      width: 50% !important; } }
  @media (min-width: 1200px) {
    .right-img {
      width: 50% !important; } }
  @media (min-width: 1560px) {
    .right-img {
      width: 50% !important; } }

.right-img {
  position: relative; }

.inside {
  color: white;
  padding: 10px 40px 10px 40px; }

.inside h2 {
  text-align: center; }

.question {
  display: flex;
  margin-top: 20px; }

.question p {
  font-size: 48px;
  font-style: normal;
  font-weight: 100;
  margin-left: 40px;
  margin-top: 0px; }

.question-num {
  font-size: 65px;
  font-style: normal;
  font-weight: 100; }

.plus {
  font-size: 46px;
  font-weight: 100;
  margin-left: 469px;
  color: #e8bf1d; }

@media (min-width: 1200px) {
  .top__section-content {
    display: flex;
    justify-content: space-between;
    padding-top: 10rem;
    color: #211e4a; } }

@media only screen and (min-width: 992px) {
  .top__section-content {
    display: flex;
    justify-content: space-between;
    padding-top: 10rem;
    color: #211e4a; } }

@media only screen and (min-width: 768px) {
  .top__section-content {
    display: flex;
    justify-content: space-between;
    padding-top: 10rem;
    color: #211e4a; } }

@media only screen and (max-width: 575px) {
  .top__section-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto; } }

@media only screen and (max-width: 575px) {
  .top__section-content img {
    display: none; } }

.hand-made {
  position: relative; }
  @media only screen and (min-width: 576px) {
    .hand-made {
      display: none; } }
  @media only screen and (max-width: 575px) {
    .hand-made {
      display: none; } }
  @media only screen and (min-width: 768px) {
    .hand-made {
      display: initial !important; } }
  @media (min-width: 1200px) {
    .hand-made {
      display: initial !important; } }
  @media (min-width: 1560px) {
    .hand-made {
      display: initial !important; } }

.hand-made-img {
  height: 91px;
  width: 93px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 88px;
  position: absolute;
  bottom: 0;
  background-image: url("../assets/images/wine-trips.png"); }
  @media only screen and (max-width: 575px) {
    .hand-made-img {
      top: 2px; } }
  .hand-made-img > p {
    text-align: center;
    font-size: 11px;
    margin-right: 4px;
    margin-top: 30px; }

.wine-text > p {
  margin-bottom: 0;
  margin-top: 2px; }

@media only screen and (max-width: 575px) {
  .wine-text p {
    margin-top: revert; } }

@media (min-width: 1560px) {
  .wine-text {
    margin-left: -10% !important;
    font-size: 21px !important;
    font-weight: 100;
    max-width: 400px !important; } }

@media (min-width: 1200px) {
  .wine-text {
    margin-left: -10% !important;
    font-size: 21px;
    font-weight: 100;
    max-width: 400px; } }

@media only screen and (min-width: 768px) {
  .wine-text {
    margin-left: -10%;
    max-width: 296px;
    font-size: 18px; } }

@media only screen and (min-width: 576px) {
  .wine-text {
    max-width: 296px;
    font-size: 18px; } }

@media only screen and (max-width: 575px) {
  .wine-text {
    text-align: left;
    padding-bottom: 2rem; } }

.wine-text--sm {
  position: relative; }
  @media only screen and (max-width: 575px) {
    .wine-text--sm {
      display: none; } }
  @media only screen and (min-width: 576px) {
    .wine-text--sm {
      display: none; } }
  @media only screen and (min-width: 768px) {
    .wine-text--sm {
      display: initial; } }
  @media (min-width: 1200px) {
    .wine-text--sm {
      display: initial; } }
  @media (min-width: 1560px) {
    .wine-text--sm {
      display: initial; } }

.wine-text--sm p {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  width: 278px; }
  @media screen and (max-width: 1360px) and (min-width: 1200px) {
    .wine-text--sm p {
      margin-right: -4vw; } }
  @media only screen and (min-width: 768px) {
    .wine-text--sm p {
      right: -86px;
      width: 318px;
      font-size: 13px; } }
  @media only screen and (min-width: 576px) {
    .wine-text--sm p {
      right: 43px;
      width: 100px;
      font-size: 13px; } }
  @media (min-width: 1200px) {
    .wine-text--sm p {
      right: -62px;
      width: 390px;
      font-size: 14px; } }
  .wine-text--sm p a {
    text-decoration: none; }

.shop {
  display: none; }
  @media only screen and (max-width: 575px) {
    .shop {
      display: flex;
      padding: 20px 0 0 20px; } }

.shop-btn {
  background-color: #e8bf1d;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 174px;
  height: 37px;
  color: #211e4a;
  cursor: pointer;
  border-radius: 20px;
  font-size: 13px; }
  @media only screen and (max-width: 575px) {
    .shop-btn {
      position: initial; } }

.about {
  margin-top: 50px;
  text-align: center; }

.about-btn {
  background-color: #e8bf1d;
  cursor: pointer;
  border-radius: 20px;
  color: #211e4a; }

.pad {
  padding: 0; }
  @media only screen and (max-width: 575px) {
    .pad {
      position: relative; } }

.container-purple {
  background: #211e4a;
  border-radius: 10px;
  padding: 0; }

.auth-block {
  max-width: 576px;
  margin: 0 auto; }
  @media only screen and (min-width: 992px) {
    .auth-block {
      display: flex;
      max-width: none;
      margin: 0; } }
  .auth-block .auth-sub-block {
    margin: 0 0 15px;
    flex-grow: 1;
    text-align: center;
    padding-bottom: 24px;
    border-radius: 10px;
    background: #fff; }
    @media only screen and (min-width: 992px) {
      .auth-block .auth-sub-block {
        flex-basis: 33%;
        margin-right: 15px;
        margin-bottom: 0; } }
    .auth-block .auth-sub-block.bordered {
      border: 1.5px solid rgba(0, 0, 0, 0.1); }
    .auth-block .auth-sub-block .auth-img {
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: center;
      background-position-y: bottom;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit; }
      .auth-block .auth-sub-block .auth-img.estate {
        background-image: url("/assets/images/wine-estates.jpg"); }
      .auth-block .auth-sub-block .auth-img.jumper {
        background-image: url("/assets/images/winejumpers.jpg"); }
    .auth-block .auth-sub-block .auth-block-content {
      margin-top: 32px;
      margin-bottom: 32px; }
      .auth-block .auth-sub-block .auth-block-content .auth-col-title {
        font-size: 16px;
        font-weight: 700; }
      .auth-block .auth-sub-block .auth-block-content .auth-col-description {
        font-size: 16px;
        font-weight: 400; }
    .auth-block .auth-sub-block .auth-block-button {
      background-color: #b01515;
      padding: 18px 64px 18px 64px;
      text-decoration: none;
      color: white;
      text-align: center; }

.login-section--home {
  margin-bottom: 60px; }
  @media (min-width: 1200px) {
    .login-section--home {
      display: none; } }

.stars-label {
  padding-left: 0 !important;
  display: inline;
  max-width: 110px; }
  .stars-label .stars {
    cursor: pointer;
    margin-left: 25px; }

.star-checkbox {
  display: flex;
  flex-direction: column; }
  .star-checkbox input[type='checkbox'] {
    width: 0px;
    opacity: 0; }

.stars-stub {
  height: 20px; }

.simple-list {
  list-style-type: none; }

.categories-content {
  padding: 30px 0; }
  .categories-content .main-category-list {
    padding-top: 0; }

.category__title {
  font-size: 35px;
  letter-spacing: 5px;
  font-weight: 300;
  text-align: center; }

.category__subtitle {
  font-size: 18px;
  font-weight: 300;
  color: #999;
  text-align: center; }

.categories-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 0 0; }
  @media only screen and (min-width: 576px) {
    .categories-list-wrapper {
      flex-direction: row;
      padding: 60px 0 0; } }
  .categories-list-wrapper .btn {
    text-transform: uppercase;
    height: 40px;
    line-height: 40px; }

.category-select {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0 0 15px; }
  @media only screen and (min-width: 576px) {
    .category-select {
      margin: 0; } }
  @media only screen and (min-width: 992px) {
    .category-select {
      display: none; } }

.category-select__item {
  position: relative; }
  .category-select__item:first-of-type {
    margin-right: 15px; }
  .category-select__item .dropdown-toggle {
    border: 1px solid #999;
    padding: 0 30px 0 15px;
    border-radius: 5px;
    min-width: 150px; }
    .category-select__item .dropdown-toggle:after {
      right: 10px; }
  .category-select__item a,
  .category-select__item span {
    display: block;
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    text-decoration: none; }
  .category-select__item .dropdown {
    left: -30%; }
    @media only screen and (min-width: 576px) {
      .category-select__item .dropdown {
        left: 0; } }
    .category-select__item .dropdown .dropdown__item a {
      padding: 0 10px; }

.categories__list {
  display: none; }
  @media only screen and (min-width: 992px) {
    .categories__list {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 0 0 -15px; } }

.category__item + .category__item:before {
  content: '|';
  color: #999; }

.category__item a,
.category__item span {
  display: inline-block;
  padding: 0 15px;
  font-size: 14px;
  line-height: 2;
  text-decoration: none;
  text-transform: uppercase;
  color: #999;
  border-bottom: 5px solid transparent;
  user-select: none; }

.category__item a:hover, .category__item a:focus {
  border-bottom-color: #e6333d; }

.category__item.active span {
  border-bottom-color: #e6333d; }

.categories-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0 0; }

.categories-filters {
  flex: 0 0 100%; }
  @media only screen and (min-width: 992px) {
    .categories-filters {
      flex: 0 0 40%;
      padding: 0 30px 10px 0;
      border-right: 1px solid #999; } }
  .categories-filters .btn-holder {
    flex-direction: column; }
    @media only screen and (min-width: 576px) {
      .categories-filters .btn-holder {
        flex-direction: row; } }
  .categories-filters .btn {
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    border-radius: 20px;
    min-width: 150px; }
    .categories-filters .btn + .btn {
      margin: 15px 0 0; }
      @media only screen and (min-width: 576px) {
        .categories-filters .btn + .btn {
          margin: 0 0 0 15px; } }

.category-sort {
  border-radius: 20px;
  font-family: 'Freigeist-WideRegular'; }
  .category-sort:focus {
    outline: none;
    box-shadow: none; }

.categories-filters__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px; }
  .categories-filters__head h4 {
    font-size: 22px;
    text-transform: uppercase; }

.categories-filters__preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px; }

.delivery-countries-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
  width: 100%; }

.delivery-countries-header {
  width: 100%;
  margin-bottom: 0px; }

.delivery-countries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.launching-soon-countries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-content: center; }

.country-with-flag {
  height: 36px;
  cursor: pointer;
  margin-right: 16px; }
  @media only screen and (max-width: 575px) {
    .country-with-flag {
      margin-right: 8px; } }

.delivery-popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px; }

.country-flag {
  display: flex;
  align-items: center; }
  .country-flag .flag-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px; }
  .country-flag .country-name {
    text-align: start; }

#prefinery_popup {
  top: 25% !important; }

.filter-tag {
  position: relative;
  margin: 0 7px 7px 0;
  font-size: 12px;
  border: 1px solid #e6333d;
  border-radius: 25px;
  padding: 5px 30px 5px 10px;
  text-transform: uppercase; }
  .filter-tag .icon-remove {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url("../assets/images/mobile_close.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer; }

.categories-filters__item {
  margin-bottom: 15px; }
  @media only screen and (min-width: 992px) {
    .categories-filters__item {
      margin-bottom: 20px; } }
  .categories-filters__item .label {
    display: block;
    text-transform: uppercase; }
  .categories-filters__item .irs--flat {
    max-width: 85%;
    margin: 0 auto; }
    @media only screen and (min-width: 576px) {
      .categories-filters__item .irs--flat {
        max-width: 100%; } }
    .categories-filters__item .irs--flat .irs-line {
      height: 3px; }
    .categories-filters__item .irs--flat .irs-max,
    .categories-filters__item .irs--flat .irs-min {
      background-color: transparent;
      font-size: 12px;
      color: #000; }
    .categories-filters__item .irs--flat .irs-bar {
      height: 3px;
      background-color: #e6333d; }
    .categories-filters__item .irs--flat .irs-handle > i:first-child {
      background-color: #e6333d;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: -5px;
      cursor: pointer; }
    .categories-filters__item .irs--flat .irs-from,
    .categories-filters__item .irs--flat .irs-to {
      margin: -3px 0 0 8px;
      background-color: #e6333d; }
      .categories-filters__item .irs--flat .irs-from:before,
      .categories-filters__item .irs--flat .irs-to:before {
        border-top-color: #e6333d; }
  .categories-filters__item:first-of-type .categories-filters__label:after {
    display: none; }

.categories-filters__inner {
  display: flex;
  flex-wrap: wrap;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.2s ease, opacity 0.4s ease; }
  .categories-filters__inner.active {
    opacity: 1;
    max-height: 100%; }
  .categories-filters__inner .checkbox {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 3px;
    padding-right: 5px; }
    @media only screen and (min-width: 576px) {
      .categories-filters__inner .checkbox {
        flex: 0 0 33.3333%;
        max-width: 33.3333%; } }

.categories-filters__label,
.group-filter {
  display: inline-block;
  margin: 10px 0 5px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  user-select: none; }
  .categories-filters__label:after,
  .group-filter:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin: -3px 0 0 10px;
    background-image: url("../assets/images/filter-plus.svg");
    background-position: 100% 50%;
    background-repeat: no-repeat;
    transition: all .3s ease; }
  .categories-filters__label.opened:after,
  .group-filter.opened:after {
    background-image: url("../assets/images/filter-minus.svg"); }

.primary-filter {
  text-transform: uppercase;
  font-weight: 700; }

.wines-list {
  max-width: 100vw; }

.categories-items-list {
  flex: 0 0 100%;
  padding: 30px 0 0; }
  @media only screen and (min-width: 992px) {
    .categories-items-list {
      flex: 0 0 60%;
      padding: 0 0 0 30px; } }
  .categories-items-list .col {
    margin: 0 0 30px; }
  @media (min-width: 1200px) {
    .categories-items-list .col-xl-2 {
      flex: 0 0 20%;
      max-width: 20%; } }
  .categories-items-list .product__item {
    border: 1px solid #808080;
    padding: 10px; }
    @media only screen and (min-width: 992px) {
      .categories-items-list .product__item {
        border: 0;
        padding: 0;
        margin: 0; } }
  @media only screen and (min-width: 992px) {
    .categories-items-list .product__item-image {
      border: 1px solid #808080;
      height: 190px; } }

.filter-applied__title {
  font-size: 22px; }

.filter-heading {
  padding-top: 24px;
  padding-bottom: 30px; }

.rating-filter {
  display: flex;
  flex-direction: column; }

@media only screen and (min-width: 768px) {
  .product-section {
    background: linear-gradient(to right, #f2f4ef 0%, #f2f4ef 50%, #fff 50%, #fff 100%); } }

.product__text {
  padding-top: 30px;
  order: 2; }
  @media only screen and (min-width: 768px) {
    .product__text {
      order: 1; } }

.product-seller__name {
  font-weight: 700;
  font-size: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block; }

.about-seller__link {
  text-decoration: none;
  text-transform: uppercase;
  color: #e6333d; }

.product-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.product-content__item,
.product-footer {
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 20px;
  padding-top: 20px; }

.scores-expertise {
  font-weight: 600; }

.product__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block; }

.product__price {
  display: block;
  font-size: 16px;
  color: #666666;
  text-transform: uppercase;
  margin: 0 0 10px; }

.product__actions {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.product-content__item--desc {
  font-size: 12px;
  line-height: 1.4; }
  .product-content__item--desc h5 {
    color: #999;
    font-weight: 400;
    margin: 0 0 20px;
    font-size: 12px;
    line-height: 1.4; }
  .product-content__item--desc .description {
    font-family: 'Freigeist-WideRegular'; }

.product-details-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .product-details-list li {
    margin-bottom: 15px; }
    .product-details-list li span {
      display: inline-block;
      vertical-align: middle; }

.product-detail__label {
  color: #999;
  font-size: 12px;
  margin-right: 10px; }

.product-detail__value {
  font-size: 14px; }

.product-footer {
  padding-top: 20px; }

.product-reviews-block {
  padding: 20px 0; }

.product-reviews__title {
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 300; }

.product__photo {
  position: relative;
  order: 1; }
  @media only screen and (min-width: 768px) {
    .product__photo {
      order: 1; } }

.product-slider {
  overflow: hidden;
  height: 270px;
  margin-bottom: 20px; }
  @media only screen and (min-width: 768px) {
    .product-slider {
      height: 700px; } }
  .product-slider .slick-list,
  .product-slider .slick-track {
    height: 100%; }

@media only screen and (min-width: 768px) {
  .product-slider-controls {
    display: none; } }

.product-slider__item a {
  display: block;
  height: 100%; }
  .product-slider__item a img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.product-preview {
  overflow: hidden;
  height: 125px;
  display: none; }
  @media only screen and (min-width: 768px) {
    .product-preview {
      display: block; } }
  .product-preview .slick-list,
  .product-preview .slick-track {
    height: 100%; }

.product-preview__item {
  cursor: pointer; }
  .product-preview__item img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.recommended-section {
  padding: 30px 0;
  padding: 30px 0 30px; }
  .recommended-section .section-title {
    margin-bottom: 30px; }

.recommended-products__content {
  position: relative; }

.recommended-products-slider {
  max-width: 85%;
  margin: 0 auto; }
  @media (min-width: 1300px) {
    .recommended-products-slider {
      max-width: 100%; } }

.recommended__product {
  padding: 0 10px; }
  .recommended__product .product__item {
    border: 1px solid #808080;
    padding: 10px; }
  .recommended__product .product__item-image {
    display: flex;
    height: 270px; }
    @media only screen and (min-width: 768px) {
      .recommended__product .product__item-image {
        height: 320px; } }

.recommended-slider-controls .slick-arrow {
  width: 50px;
  height: 50px; }
  .recommended-slider-controls .slick-arrow.prev-arrow {
    left: -15px; }
    @media only screen and (min-width: 768px) {
      .recommended-slider-controls .slick-arrow.prev-arrow {
        left: 0; } }
    @media (min-width: 1300px) {
      .recommended-slider-controls .slick-arrow.prev-arrow {
        left: -50px; } }
  .recommended-slider-controls .slick-arrow.next-arrow {
    right: -15px; }
    @media only screen and (min-width: 768px) {
      .recommended-slider-controls .slick-arrow.next-arrow {
        right: 0; } }
    @media (min-width: 1300px) {
      .recommended-slider-controls .slick-arrow.next-arrow {
        right: -50px; } }

:root {
  --star-size: 60px;
  --star-color: #fff;
  --star-background: #b81928;
  --shift: 0px; }

.stars {
  --percent: calc(var(--rating) / 5 * 100%);
  display: inline-block;
  font-size: var(--star-size);
  font-family: Times;
  line-height: 1;
  margin-right: var(--shift); }
  .stars::before {
    content: '★★★★★';
    background: linear-gradient(90deg, var(--star-background) var(--percent), var(--star-color) var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 200ms linear; }

.stars-container {
  display: flex;
  justify-content: space-between; }

.product-stars {
  padding: 10px 0 0 0; }

.stars-filter {
  color: #b81928; }

.delivery-country-cont {
  display: flex;
  justify-content: flex-end; }

.delivery-sort {
  border-radius: 20px;
  font-family: 'Freigeist-WideRegular'; }
  .delivery-sort:focus {
    outline: none;
    box-shadow: none; }

.bottle-amount-cont {
  display: flex;
  align-items: baseline; }
  .bottle-amount-cont .volume {
    margin-left: 10px; }

.auth-container {
  margin: 0 auto;
  padding: 50px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media only screen and (min-width: 576px) {
    .auth-container {
      max-width: 280px; } }
  .auth-container--login .page-title {
    margin-bottom: 20px; }
  .auth-container--login .auth-form .input-field.error:before {
    display: none; }

.auth-form--reset-pass .btn {
  display: block;
  margin: 26px auto 0;
  border-radius: 20px; }

.auth-form--reset-pass .input-field.error:before {
  visibility: visible;
  opacity: 1;
  top: 75px; }

.auth-form .checkbox label {
  text-transform: none; }

.auth-form .input-field--checkbox.error:before {
  top: 40px; }
  @media only screen and (min-width: 768px) {
    .auth-form .input-field--checkbox.error:before {
      top: 43px; } }
  @media only screen and (min-width: 992px) {
    .auth-form .input-field--checkbox.error:before {
      top: 27px; } }
  @media (min-width: 1200px) {
    .auth-form .input-field--checkbox.error:before {
      top: 23px; } }

.form__link {
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 10px 0;
  color: #808080; }

small.form-text {
  margin: 0.25rem 0 0.7rem;
  font-size: 14px; }

.form-error {
  display: none;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  padding: 10px 4px;
  border-radius: 2px;
  background: #d0011b;
  max-width: 280px;
  margin: 0 auto 15px; }
  .form-error--show {
    display: block; }
  .form-error p {
    margin: 0; }
    .form-error p + p {
      margin-top: 7px; }

#userPasswordHelp {
  margin-top: 15px; }

.login-container {
  position: relative;
  border-bottom: 1px solid #d3d3d3;
  overflow: hidden; }
  @media only screen and (min-width: 992px) {
    .login-container:before {
      content: '';
      position: absolute;
      left: -55%;
      top: 0;
      width: 100%;
      height: 100%;
      transform: skew(-30deg);
      background-color: #f2f4ef;
      z-index: 2; }
    .login-container:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%); } }

.testimonial {
  margin-bottom: 13rem; }

.box-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  @media only screen and (min-width: 992px) {
    .box-wrapper {
      flex-direction: row; } }

.box {
  border: 1.5px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-height: 400px;
  max-width: 576px;
  margin: 0 auto;
  background: #fff; }
  @media only screen and (min-width: 992px) {
    .box {
      max-width: none;
      width: 48%;
      margin: 0;
      max-height: 400px; } }

.box-1 {
  margin-bottom: 10rem; }
  @media only screen and (min-width: 992px) {
    .box-1 {
      margin-bottom: 0; } }

.box-header {
  display: flex;
  position: relative;
  margin-top: 7px;
  color: #211e4a;
  padding-left: 8px; }

.box-header--img {
  height: 76px;
  width: 76px; }

.box-header--img-2 {
  position: absolute;
  width: 31px;
  bottom: 18px;
  left: 30px; }

.box-header--img-2-red {
  position: absolute;
  width: 51px;
  bottom: 16px;
  left: 20px; }

.box-header--title {
  padding-left: 30px;
  font-size: 30px;
  text-transform: uppercase; }
  @media only screen and (max-width: 575px) {
    .box-header--title {
      font-size: 17px;
      padding-left: 15px; } }
  .box-header--title p {
    margin-bottom: 0; }
    @media only screen and (max-width: 575px) {
      .box-header--title p {
        margin-top: 45px; } }

.box-header--text {
  padding: 13px;
  color: white;
  background: #211e4a;
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  margin-top: 8px;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit; }
  .box-header--text hr {
    margin: 14px 0; }

.box-header--text::-webkit-scrollbar-track {
  padding: 2px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 4px; }

.box-header--text::-webkit-scrollbar {
  width: 7px; }

.box-header--text::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e6e3e3; }

.box-header--text-content p:last-child {
  font-size: 12px;
  font-style: italic; }

.box-header--text-title {
  font-size: 20px;
  text-transform: uppercase; }
  @media only screen and (max-width: 575px) {
    .box-header--text-title {
      font-size: 17px; } }

.login-section {
  margin: 7rem 0 6rem 0; }

.login-right__bg {
  display: none;
  position: absolute;
  right: 0;
  top: 0; }
  @media only screen and (min-width: 992px) {
    .login-right__bg {
      display: block; } }

.login__col--form {
  flex-grow: 1;
  position: relative;
  padding: 0;
  z-index: 2;
  text-align: left;
  margin: 45px 0 25px 0; }
  @media only screen and (min-width: 992px) {
    .login__col--form {
      display: flex;
      align-items: center;
      flex-basis: 33%;
      margin: 0; } }
  @media only screen and (min-width: 992px) {
    .login__col--form .login__form-content {
      width: 100%;
      max-width: 320px;
      margin: 0 auto; } }
  .login__col--form .login__title {
    text-align: center; }

.login__col--image {
  position: relative;
  z-index: 1;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden; }
  .login__col--image img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }
    @media only screen and (min-width: 992px) {
      .login__col--image img {
        display: none; } }

.profile-absence {
  font-size: 18px;
  margin: 60px auto 20px auto;
  text-align: center; }

.login__title {
  font-size: 36px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center; }

.login-form .input-field {
  margin-bottom: 30px; }

.login-form label {
  color: #555; }

.login__form-content .form__link {
  padding: 30px 0 0;
  color: #000;
  text-transform: uppercase;
  text-align: center; }

.login__image-content {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  color: #fff;
  font-size: 18px;
  line-height: 1.333333; }
  @media only screen and (min-width: 992px) {
    .login__image-content {
      align-items: flex-end;
      text-align: right;
      padding: 0; } }
  .login__image-content h2 {
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 20px; }
    @media (min-width: 1200px) {
      .login__image-content h2 {
        font-size: 48px; } }
  .login__image-content p {
    margin: 0 0 30px; }

.skew-items-section--login .skew__item-info:before {
  transform: skew(-30deg); }

@media (min-width: 1200px) {
  .skew-items-section--login .skew__item-info {
    padding-top: 40px;
    padding-bottom: 40px; } }

.region-image-section {
  padding: 15px 0;
  font-size: 14px;
  line-height: 1.3333333; }
  @media only screen and (min-width: 768px) {
    .region-image-section {
      font-size: 16px;
      padding: 30px 0; } }
  @media (min-width: 1200px) {
    .region-image-section {
      font-size: 18px;
      padding: 80px 0; } }
  @media only screen and (min-width: 768px) {
    .region-image-section .row:nth-of-type(odd) .region--photo {
      order: 1;
      padding-right: 0; } }
  @media only screen and (min-width: 768px) {
    .region-image-section .row:nth-of-type(odd) .region--text {
      order: 2;
      padding-right: 30px; } }
  @media only screen and (min-width: 992px) {
    .region-image-section .row:nth-of-type(odd) .region--text {
      padding-left: 73px; } }
  @media only screen and (min-width: 768px) {
    .region-image-section .row:nth-of-type(even) .region--photo {
      order: 2;
      padding-left: 0; } }
  @media only screen and (min-width: 768px) {
    .region-image-section .row:nth-of-type(even) .region--text {
      order: 1;
      padding-left: 30px;
      text-align: right; } }
  @media only screen and (min-width: 992px) {
    .region-image-section .row:nth-of-type(even) .region--text {
      padding-right: 73px; } }

.region--photo {
  order: 1; }
  .region--photo img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.region--text {
  order: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px; }
  .region--text p {
    margin: 0 0 7px; }
    @media only screen and (min-width: 768px) {
      .region--text p {
        margin: 0 0 15px; } }
    @media only screen and (min-width: 992px) {
      .region--text p {
        margin: 0 0 25px; } }

.seller-photo-section {
  padding: 30px 0; }
  @media only screen and (min-width: 768px) {
    .seller-photo-section {
      padding: 104px 0; } }

.seller-photos-list {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media only screen and (min-width: 768px) {
    .seller-photos-list {
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: row; } }
  .seller-photos-list img {
    width: 400px;
    height: 303px;
    margin: 5px; }

.seller-photo__item {
  position: relative;
  flex: 0 0 100%; }
  @media only screen and (min-width: 768px) {
    .seller-photo__item {
      flex: 0 0 32.8%; } }
  @media only screen and (min-width: 768px) {
    .seller-photo__item:nth-of-type(2) {
      z-index: 2; } }
  .seller-photo__item + .seller-photo__item {
    margin-top: 15px; }
    @media only screen and (min-width: 768px) {
      .seller-photo__item + .seller-photo__item {
        margin-top: 0; } }

.seller-products-section {
  padding: 30px 0; }
  @media only screen and (min-width: 768px) {
    .seller-products-section {
      padding: 80px 0 70px; } }

@media only screen and (min-width: 768px) {
  .seller-products__content {
    width: 85%;
    margin: 0 auto;
    align-items: center; } }

.seller-products__text {
  text-align: center; }
  .seller-products__text .title {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 700;
    margin: 0 0 20px; }
    @media only screen and (min-width: 768px) {
      .seller-products__text .title {
        font-size: 48px; } }
  .seller-products__text .subtitle {
    font-size: 26px;
    line-height: 1.2;
    margin: 0 0 20px; }
    @media only screen and (min-width: 768px) {
      .seller-products__text .subtitle {
        font-size: 30px;
        margin: 0 0 40px; } }
  .seller-products__text .btn {
    display: block;
    max-width: 360px;
    margin: 0 auto;
    font-size: 14px;
    height: 60px;
    line-height: 60px; }

.promoMessage {
  font-size: 18px; }

.upload-group--banner {
  flex: 0 0 100%;
  max-width: 100%; }

.upload-product-form .input-field--switch label {
  margin: 0; }

.promo-switch {
  display: flex;
  justify-content: flex-end; }

.winejumper-section {
  padding: 60px 0; }
  @media only screen and (min-width: 768px) {
    .winejumper-section {
      padding: 105px 0; } }

.winejumper-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap; }
  @media only screen and (min-width: 992px) {
    .winejumper-list {
      max-width: 85%;
      margin: 0 auto; } }

.winejumper__item {
  flex: 0 0 100%;
  text-align: center; }
  @media only screen and (min-width: 768px) {
    .winejumper__item {
      flex: 0 0 50%; } }
  .winejumper-list .winejumper__item {
    padding: 30px; }
    @media only screen and (min-width: 768px) {
      .winejumper-list .winejumper__item {
        padding: 30px 5% 40px; } }
    @media (min-width: 1200px) {
      .winejumper-list .winejumper__item {
        padding: 30px 7.5% 40px; } }
  .winejumper__item:first-of-type {
    border: 1px solid #f2f4ef; }
  .winejumper__item:last-of-type {
    background: #f2f4ef;
    position: relative; }
    @media only screen and (min-width: 768px) {
      .winejumper__item:last-of-type {
        margin-left: -10px; } }
    @media only screen and (min-width: 992px) {
      .winejumper__item:last-of-type {
        margin: 0; } }
    @media only screen and (min-width: 768px) {
      .winejumper__item:last-of-type:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: -10px;
        top: -10px;
        border: 10px solid #f2f4ef;
        z-index: -1; } }
    @media only screen and (min-width: 992px) {
      .winejumper__item:last-of-type:before {
        left: -20px;
        top: -20px;
        border: 20px solid #f2f4ef; } }

.winejumper__item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .winejumper__item-info .title {
    font-weight: 400;
    line-height: 1;
    color: #e6333d;
    margin: 0 0 15px; }
    @media only screen and (min-width: 768px) {
      .winejumper__item-info .title {
        font-size: 28px; } }
    @media only screen and (min-width: 992px) {
      .winejumper__item-info .title {
        font-size: 36px;
        margin: 0 0 30px; } }
    .winejumper__item-info .title--badge {
      position: relative; }
      .winejumper__item-info .title--badge:after {
        content: attr(data-badge);
        display: inline-block;
        vertical-align: middle;
        height: 22px;
        padding: 0 10px;
        margin: 0 0 0 10px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
        background: #e6333d;
        border-radius: 6px; }
        @media only screen and (min-width: 768px) {
          .winejumper__item-info .title--badge:after {
            height: 25px;
            font-size: 16px;
            line-height: 25px; } }
        @media only screen and (min-width: 992px) {
          .winejumper__item-info .title--badge:after {
            height: 30px;
            font-size: 18px;
            line-height: 30px; } }
  .winejumper__item-info p {
    margin: 0 0 30px; }
    @media only screen and (min-width: 992px) {
      .winejumper__item-info p {
        font-size: 18px; } }
  .winejumper__item-info .btn {
    max-width: 280px;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    text-transform: uppercase; }
    .winejumper__item-info .btn.disabled {
      opacity: .6;
      cursor: default;
      pointer-events: none; }
      .winejumper__item-info .btn.disabled:hover, .winejumper__item-info .btn.disabled:focus {
        cursor: default;
        pointer-events: none; }

.banner-content--not-found {
  min-height: 250px;
  margin: 0 0 20px; }
  @media only screen and (min-width: 576px) {
    .banner-content--not-found {
      min-height: 400px; } }

.no-results {
  display: flex;
  flex-grow: 1;
  align-items: center; }

.no-results__title {
  color: #e6333d; }

.cart-section {
  height: 100%;
  margin-bottom: 10rem; }
  .cart-section form {
    height: 100%; }

#continueInEstate {
  float: right; }

.cart-section__head {
  display: flex;
  align-items: center;
  padding: 30px 0; }
  .cart-section__head .section-title {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin: 0; }
  .cart-section__head .col {
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .cart-section__head .col {
        text-align: left; } }
  .cart-section__head.empty-cart-head {
    height: 100%; }
  .cart-section__head.empty-cart-head .row,
  .cart-section__head.empty-cart-head .container {
    height: 100%; }
  .cart-section__head.empty-cart-head .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  .cart-section__head.empty-cart-head h4 {
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 15px; }
  @media (max-width: 767px) {
    .cart-section__head .btn-holder {
      justify-content: center; } }
  .cart-section__head.stuck {
    position: fixed;
    left: 0;
    top: 45px;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    background: white;
    border-bottom: 1px solid #c8c8c8;
    z-index: 100; }
    @media only screen and (min-width: 992px) {
      .cart-section__head.stuck {
        top: 70px; } }

.total-cart-head__sum {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 30px;
  color: #808080;
  text-transform: uppercase;
  font-size: 16px; }
  .total-cart-head__sum strong {
    color: #000;
    font-size: 18px;
    display: inline-block;
    margin-left: 7px; }

.btn--continue {
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-radius: 20px;
  margin: 0 15px 0 0;
  border: 1px solid; }

.js-prev-step {
  border-radius: 20px; }

.cart-section__content {
  padding: 45px 0; }

.cart__free-shipping-details {
  color: #b81928;
  font-size: 16px; }

.cart__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d3d3d3; }

.cart__item-img {
  flex: 0 0 100%;
  max-height: 300px; }
  @media only screen and (min-width: 768px) {
    .cart__item-img {
      flex: 0 0 20%;
      max-height: 245px; } }
  .cart__item-img img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.cart__item-details {
  position: relative;
  flex: 0 0 100%;
  padding: 15px 0; }
  @media only screen and (min-width: 768px) {
    .cart__item-details {
      flex: 0 0 80%;
      padding: 15px 60px 15px 30px; } }

.cart__item-title {
  margin-bottom: 20px; }
  .cart__item-title a {
    text-decoration: none; }

.cart__item-quantity {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px; }
  @media only screen and (min-width: 576px) {
    .cart__item-quantity {
      flex-direction: row;
      align-items: center; } }
  .cart__item-quantity .label {
    display: inline-block;
    margin: 0 42px 0 0;
    color: #808080; }
    @media only screen and (min-width: 576px) {
      .cart__item-quantity .label {
        margin: 0 42px 0 0; } }

.cart__item-quantity-checkout {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px; }
  @media only screen and (min-width: 576px) {
    .cart__item-quantity-checkout {
      flex-direction: column;
      align-items: flex-start; } }
  .cart__item-quantity-checkout .label {
    display: inline-block;
    margin: 0 42px 0 0;
    color: #808080; }
    @media only screen and (min-width: 576px) {
      .cart__item-quantity-checkout .label {
        margin: 0 42px 0 0; } }

.cart__item-coupon {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px; }
  @media only screen and (min-width: 576px) {
    .cart__item-coupon {
      flex-direction: row;
      align-items: flex-end; } }
  .cart__item-coupon .label-coupon {
    margin: 0 17px 0 0 !important; }

.coupon {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 576px) {
    .coupon {
      margin-bottom: 5px; } }

.cart__item-price {
  font-size: 20px;
  text-transform: uppercase;
  display: inline-block;
  margin: 15px 0 0; }
  @media only screen and (min-width: 576px) {
    .cart__item-price {
      margin: 0 0 0 15px; } }

.cart__item-seller {
  display: flex;
  align-items: center; }
  .cart__item-seller a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 700; }

.cart__item-seller-img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden; }
  .cart__item-seller-img img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.remove-cart__item {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer; }
  @media only screen and (min-width: 576px) {
    .remove-cart__item {
      right: 15px;
      top: 10px; } }
  .remove-cart__item svg {
    width: 15px;
    height: 15px;
    fill: #808080; }

@media (max-width: 767px) {
  .cart__total .btn-holder {
    justify-content: center; } }

.cart__total-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0 45px; }
  @media only screen and (min-width: 768px) {
    .cart__total-header {
      justify-content: flex-end;
      flex-direction: row; } }

.cart__total-header--left {
  padding: 0;
  margin: 0 0 15px; }
  @media only screen and (min-width: 768px) {
    .cart__total-header--left {
      max-width: 50%;
      padding: 0 30px;
      margin: 0; } }
  @media (min-width: 1200px) {
    .cart__total-header--left {
      max-width: 25%; } }
  .cart__total-header--left span {
    color: #e6333d; }

.cart__total-sum {
  font-size: 24px;
  font-weight: 700;
  color: #808080;
  margin: 0 0 15px; }
  @media only screen and (min-width: 768px) {
    .cart__total-sum {
      margin: 0; } }
  .cart__total-sum span {
    margin-left: 15px;
    color: #000; }

.cart__product-description-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .cart__product-description-wrapper .description {
    display: none;
    margin-bottom: 5px; }
  .cart__product-description-wrapper .expand-button {
    padding: 0;
    color: #b6b6b4;
    font-size: 14px;
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer; }

.undeliverable-products {
  background: #f2f4ef;
  padding: 20px 20px;
  margin-bottom: 30px; }

.checkout-section {
  padding-top: 30px; }

.checkout-form .btn-holder {
  padding: 15px 0; }

.checkout-form .btn--dark {
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-radius: 20px; }

.checkout-form .btn--apply {
  color: #000000;
  background: #efefef;
  border-radius: 20px;
  text-transform: uppercase; }

.unregistered-checkout__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f2f4ef;
  padding: 45px 15px;
  margin-bottom: 30px; }

.registered-checkout__content .saved-address {
  flex: 0 0 100%; }
  @media only screen and (min-width: 768px) {
    .registered-checkout__content .saved-address {
      flex: 0 0 50%; } }
  @media only screen and (min-width: 992px) {
    .registered-checkout__content .saved-address {
      flex: 0 0 23%; } }

.checkout-seller__name {
  display: inline-block;
  width: 100%;
  background: #d3d3d3;
  padding: 15px 10px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700; }

.checkout-order__content {
  display: flex;
  flex-wrap: wrap; }

.checkout-items__list {
  flex: 0 0 100%;
  list-style: none;
  margin: 0;
  padding: 0; }
  @media only screen and (min-width: 768px) {
    .checkout-items__list {
      flex: 0 0 70%; } }

.checkout__item {
  display: flex;
  padding: 20px 0; }
  .checkout__item + .checkout__item {
    border-top: 1px solid #d3d3d3; }

.checkout__item-img {
  width: 100px;
  height: 100px;
  background: #fff;
  border: 1px solid #d3d3d3;
  padding: 5px; }
  .checkout__item-img img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.checkout__item-info {
  padding: 0 30px; }

.checkout__item-title {
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 15px; }

.checkout__item-qty {
  display: block;
  margin-bottom: 10px; }

.checkout__item-price {
  display: block;
  font-weight: 700; }

.checkout-order__delivery {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #d3d3d3;
  padding: 10px 15px;
  margin-bottom: 15px; }
  @media only screen and (min-width: 768px) {
    .checkout-order__delivery {
      flex: 0 0 30%;
      border-top: 0;
      margin-bottom: 0; } }

.checkout-order__item:last-of-type .checkout-order__delivery {
  border-bottom: 1px solid #d3d3d3; }

.delivery__title {
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
  margin-bottom: 10px; }
  .delivery__title span {
    font-weight: 400; }

.checkout-order__free-shipping {
  color: #e6333d; }

.checkout-order__extra_block {
  display: flex;
  justify-content: center;
  flex-direction: column; }
  .checkout-order__extra_block-notification {
    max-width: 285px;
    color: #e6333d;
    align-self: flex-end;
    padding: 7px 15px; }
  .checkout-order__extra_block-pay-tab {
    max-width: 100%;
    padding: 7px;
    color: #e6333d;
    text-align: justify; }
    @media only screen and (min-width: 992px) {
      .checkout-order__extra_block-pay-tab {
        max-width: 60%; } }
  .checkout-order__extra_block-description {
    color: grey; }
  .checkout-order__extra_block .input-field {
    margin-top: 20px; }
  .checkout-order__extra_block textarea {
    background: #eee; }

@media only screen and (min-width: 992px) {
  .review {
    max-width: 100%;
    padding-left: 0; } }

.checkout-total {
  border: 1px solid #d3d3d3;
  padding: 10px; }

.checkout-total__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d3d3d3; }

.edit-delivery__address {
  cursor: pointer; }
  .edit-delivery__address svg {
    width: 24px;
    height: 24px;
    fill: #808080; }

.checkout-total__title {
  font-size: 14px;
  line-height: 1;
  font-weight: 700; }

.checkout-total__address {
  margin-bottom: 45px;
  color: #808080; }
  .checkout-total__address span {
    display: block; }

.checkout-total__sum {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #d3d3d3; }
  .checkout-total__sum li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; }
  .checkout-total__sum .label {
    color: #808080; }

.checkout-product-price {
  list-style: none;
  margin: 0;
  padding: 0; }
  .checkout-product-price li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px; }
  .checkout-product-price .label {
    color: #808080;
    width: 70px; }

.checkout-total__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0; }
  .checkout-total__box h4 {
    color: #808080;
    font-size: 18px;
    line-height: 1; }
  .checkout-total__box strong {
    font-size: 20px; }

.cards-container {
  display: flex;
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center; }
  .cards-container #payment-form {
    width: 25%; }
    @media only screen and (max-width: 575px) {
      .cards-container #payment-form {
        width: 50%; } }
  .cards-container #payment-form .btn-holder {
    justify-content: center; }
  .cards-container .StripeElement {
    padding: 20px;
    border: solid 1px #d3d3d3; }
  .cards-container #styled-stripe-button {
    width: 30%;
    position: absolute;
    left: 35%;
    margin-bottom: 20px; }
  .cards-container .card-elements-group .card-elements-group__label-title {
    display: block;
    margin: 0 0 8px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase; }
  .cards-container #card-number-element,
  .cards-container #card-expiry-element,
  .cards-container #card-cvc-element {
    margin-bottom: 20px; }
  .cards-container .card-elements-group__card-number,
  .cards-container .card-elements-group__card-expiry,
  .cards-container .card-elements-group__card-cvc {
    position: relative;
    display: block;
    width: 100%; }
  .cards-container .card-elements-group__card-number {
    margin-top: 15px; }
  .cards-container .card-elements-group__card-number span.brand {
    position: absolute;
    right: 10px;
    top: 50px;
    width: 30px; }
  .cards-container .card-elements-group__card-number::before,
  .cards-container .card-elements-group__card-expiry::before,
  .cards-container .card-elements-group__card-cvc::before {
    display: block;
    font-size: 10px;
    white-space: nowrap;
    opacity: 0; }
  .cards-container .card-elements-group__card-number.error span.brand {
    color: #fa755a; }
  .cards-container .card-elements-group__card-number.error::before,
  .cards-container .card-elements-group__card-expiry.error::before,
  .cards-container .card-elements-group__card-cvc.error::before {
    position: absolute;
    left: 5px;
    bottom: -14px;
    color: #fa755a;
    opacity: 1;
    content: attr(data-error); }

.col__title {
  text-transform: uppercase;
  margin: 0 0 30px; }

.checkout-payment__option {
  font-size: 18px;
  font-weight: 700; }

.checkout-payment__summary {
  background: #f2f4ef;
  border-radius: 6px;
  padding: 15px 15px; }
  @media only screen and (min-width: 992px) {
    .checkout-payment__summary {
      max-width: 60%; } }

.checkout-payment__list {
  list-style: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #808080; }
  .checkout-payment__list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px; }
    .checkout-payment__list li .label {
      color: #808080; }

.checkout-payment__total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 0; }
  .checkout-payment__total strong {
    font-size: 18px;
    color: #e6333d; }

.delivery-address-box #postalCode {
  flex: 0 0 195px;
  border-radius: 0; }

.delivery-address-box--active {
  padding-top: 20px; }

.notification {
  margin-bottom: 0; }

#accessPointDetails {
  margin-top: 10px; }

.input-field label {
  text-transform: uppercase; }

.input-field__wrapper--delivery-block {
  flex-direction: column; }
  .input-field__wrapper--delivery-block .input-field--country {
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0; }
  .input-field__wrapper--delivery-block .btn-postal-code-find {
    border-radius: 20px;
    margin-top: 30px; }
  @media only screen and (min-width: 768px) {
    .input-field__wrapper--delivery-block {
      flex-direction: row; }
      .input-field__wrapper--delivery-block .input-field--country {
        margin-bottom: 0;
        padding-right: 15px; }
      .input-field__wrapper--delivery-block .btn-postal-code-find {
        margin-top: 0; } }

.discount {
  display: flex;
  margin-top: 15px;
  align-items: flex-end; }
  .discount input {
    height: 40px;
    border: 1px solid #d3d3d3;
    margin-top: 5px;
    line-height: 47px;
    padding: 0 20px;
    width: 95%;
    font-size: 16px; }

input[type='button'] {
  -webkit-appearance: button;
  cursor: pointer; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

.cart__item-quantity-checkout {
  display: table; }

.button-plus,
.button-minus,
.product_quantity {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 10px; }

.button-plus,
.button-minus {
  width: auto;
  transition: all 300ms ease;
  font-weight: bold;
  height: 30px;
  padding: 0;
  width: 30px;
  position: relative;
  border: none;
  font-size: 18px; }
  @media (max-width: 351px) {
    .button-plus,
    .button-minus {
      height: 21px;
      width: 21px; } }

.product_quantity {
  position: relative;
  height: 38px;
  text-align: center;
  width: 48px;
  display: inline-block;
  font-size: 13px;
  margin: 0 0 5px;
  resize: vertical;
  -moz-appearance: textfield; }

.button-plus {
  top: 1.4px; }

.checkout__delivery-adress {
  display: flex;
  justify-content: space-between;
  max-width: 98%;
  margin-top: 10px; }

.delivery-terms {
  padding-top: 20px; }

.delivery-options-cont {
  display: flex;
  flex-direction: column; }

.delivery-checkbox {
  display: inline-flex; }

.age-confirmation-block {
  margin-top: 15px; }

.regions-list-section {
  padding: 50px 0 15px; }
  .regions-list-section .content__title {
    font-weight: normal;
    text-transform: lowercase; }
    @media only screen and (min-width: 768px) {
      .regions-list-section .content__title {
        font-size: 24px; } }
  @media only screen and (min-width: 992px) {
    .regions-list-section .regions-box {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(5, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      margin: 0; }
      .regions-list-section .regions-box .region__item--1 {
        grid-area: 1 / 1 / 3 / 3; }
      .regions-list-section .regions-box .region__item--2 {
        grid-area: 1 / 3 / 2 / 5; }
      .regions-list-section .regions-box .region__item--3 {
        grid-area: 1 / 5 / 4 / 6; }
      .regions-list-section .regions-box .region__item--4 {
        grid-area: 2 / 3 / 4 / 5; }
      .regions-list-section .regions-box .region__item--5 {
        grid-area: 3 / 1 / 4 / 3; }
      .regions-list-section .regions-box .region__item--full {
        grid-area: 4 / 1 / 6 / 6; } }
  @media only screen and (min-width: 992px) {
    .regions-list-section .regions-box--france {
      grid-template-rows: repeat(6, 1fr); }
      .regions-list-section .regions-box--france .region__item--1 {
        grid-area: 1 / 1 / 3 / 3; }
      .regions-list-section .regions-box--france .region__item--2 {
        grid-area: 1 / 3 / 2 / 5; }
      .regions-list-section .regions-box--france .region__item--3 {
        grid-area: 1 / 5 / 4 / 6; }
      .regions-list-section .regions-box--france .region__item--4 {
        grid-area: 2 / 3 / 4 / 5; }
      .regions-list-section .regions-box--france .region__item--5 {
        grid-area: 3 / 1 / 4 / 3; }
      .regions-list-section .regions-box--france .region__item--6 {
        grid-area: 4 / 1 / 6 / 2; }
      .regions-list-section .regions-box--france .region__item--7 {
        grid-area: 4 / 2 / 5 / 4; }
      .regions-list-section .regions-box--france .region__item--8 {
        grid-area: 5 / 2 / 6 / 4; }
      .regions-list-section .regions-box--france .region__item--9 {
        grid-area: 4 / 4 / 6 / 6; }
      .regions-list-section .regions-box--france .region__item--10 {
        grid-area: 6 / 1 / 8 / 4; }
      .regions-list-section .regions-box--france .region__item--11 {
        grid-area: 6 / 4 / 8 / 6; }
      .regions-list-section .regions-box--france .region__item--full {
        grid-area: 8 / 1 / 10 / 6; } }
  @media only screen and (min-width: 992px) {
    .regions-list-section .regions-box--germany {
      grid-template-rows: repeat(6, 1fr); }
      .regions-list-section .regions-box--germany .region__item--1 {
        grid-area: 1 / 1 / 3 / 3; }
      .regions-list-section .regions-box--germany .region__item--2 {
        grid-area: 1 / 3 / 2 / 5; }
      .regions-list-section .regions-box--germany .region__item--3 {
        grid-area: 1 / 5 / 4 / 6; }
      .regions-list-section .regions-box--germany .region__item--4 {
        grid-area: 2 / 3 / 4 / 5; }
      .regions-list-section .regions-box--germany .region__item--5 {
        grid-area: 3 / 1 / 4 / 3; }
      .regions-list-section .regions-box--germany .region__item--6 {
        grid-area: 4 / 1 / 6 / 2; }
      .regions-list-section .regions-box--germany .region__item--7 {
        grid-area: 4 / 2 / 5 / 4; }
      .regions-list-section .regions-box--germany .region__item--8 {
        grid-area: 5 / 2 / 6 / 4; }
      .regions-list-section .regions-box--germany .region__item--9 {
        grid-area: 4 / 4 / 6 / 6; }
      .regions-list-section .regions-box--germany .region__item--full {
        grid-area: 6 / 1 / 8 / 6; } }
  @media only screen and (min-width: 992px) {
    .regions-list-section .regions-box--spain {
      grid-template-rows: repeat(9, 1fr); }
      .regions-list-section .regions-box--spain .region__item--1 {
        grid-area: 1 / 1 / 3 / 3; }
      .regions-list-section .regions-box--spain .region__item--2 {
        grid-area: 1 / 3 / 2 / 5; }
      .regions-list-section .regions-box--spain .region__item--3 {
        grid-area: 1 / 5 / 4 / 6; }
      .regions-list-section .regions-box--spain .region__item--4 {
        grid-area: 2 / 3 / 4 / 5; }
      .regions-list-section .regions-box--spain .region__item--5 {
        grid-area: 3 / 1 / 4 / 3; }
      .regions-list-section .regions-box--spain .region__item--6 {
        grid-area: 4 / 1 / 6 / 2; }
      .regions-list-section .regions-box--spain .region__item--7 {
        grid-area: 4 / 2 / 5 / 4; }
      .regions-list-section .regions-box--spain .region__item--8 {
        grid-area: 5 / 2 / 6 / 4; }
      .regions-list-section .regions-box--spain .region__item--9 {
        grid-area: 4 / 4 / 6 / 6; }
      .regions-list-section .regions-box--spain .region__item--10 {
        grid-area: 6 / 1 / 8 / 3; }
      .regions-list-section .regions-box--spain .region__item--11 {
        grid-area: 6 / 3 / 8 / 4; }
      .regions-list-section .regions-box--spain .region__item--12 {
        grid-area: 6 / 4 / 8 / 6; }
      .regions-list-section .regions-box--spain .region__item--null {
        grid-area: 8 / 1 / 10 / 3; }
      .regions-list-section .regions-box--spain .region__item--full {
        grid-area: 8 / 3 / 10 / 6; } }
  @media only screen and (min-width: 992px) {
    .regions-list-section .regions-box--italy {
      grid-template-rows: repeat(11, 1fr); }
      .regions-list-section .regions-box--italy .region__item--1 {
        grid-area: 1 / 1 / 3 / 3; }
      .regions-list-section .regions-box--italy .region__item--2 {
        grid-area: 1 / 3 / 2 / 5; }
      .regions-list-section .regions-box--italy .region__item--3 {
        grid-area: 1 / 5 / 4 / 6; }
      .regions-list-section .regions-box--italy .region__item--4 {
        grid-area: 2 / 3 / 4 / 5; }
      .regions-list-section .regions-box--italy .region__item--5 {
        grid-area: 3 / 1 / 4 / 3; }
      .regions-list-section .regions-box--italy .region__item--6 {
        grid-area: 4 / 1 / 7 / 2; }
      .regions-list-section .regions-box--italy .region__item--7 {
        grid-area: 4 / 2 / 6 / 4; }
      .regions-list-section .regions-box--italy .region__item--8 {
        grid-area: 6 / 2 / 7 / 4; }
      .regions-list-section .regions-box--italy .region__item--9 {
        grid-area: 4 / 4 / 5 / 6; }
      .regions-list-section .regions-box--italy .region__item--10 {
        grid-area: 5 / 4 / 7 / 6; }
      .regions-list-section .regions-box--italy .region__item--11 {
        grid-area: 7 / 1 / 8 / 3; }
      .regions-list-section .regions-box--italy .region__item--12 {
        grid-area: 8 / 1 / 10 / 3; }
      .regions-list-section .regions-box--italy .region__item--13 {
        grid-area: 7 / 3 / 10 / 4; }
      .regions-list-section .regions-box--italy .region__item--14 {
        grid-area: 7 / 4 / 9 / 6; }
      .regions-list-section .regions-box--italy .region__item--15 {
        grid-area: 9 / 4 / 10 / 6; }
      .regions-list-section .regions-box--italy .region__item--full {
        grid-area: 10 / 1 / 12 / 6; } }
  @media only screen and (min-width: 992px) {
    .regions-list-section .region__item {
      margin: 0;
      padding: 0; } }
  .regions-list-section .region__inner {
    text-shadow: 5px 1px 4px black, 0 0 1em;
    height: 100%;
    border-radius: 10px; }

.card-info {
  display: flex;
  width: 15%;
  flex-direction: column;
  align-items: center; }
  .card-info .row {
    width: 65%; }
  .card-info .card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 14px;
    font-size: 20px; }
    .card-info .card:hover {
      cursor: pointer;
      background-color: rgba(128, 128, 128, 0.3); }
    .card-info .card .asterix {
      display: inline-block;
      height: 16px; }
  .card-info .profile-title {
    margin-top: 25px; }
  @media (max-width: 2100px) {
    .card-info {
      width: 18%; } }
  @media (max-width: 1800px) {
    .card-info {
      width: 22%; } }
  @media (max-width: 1600px) {
    .card-info {
      width: 28%; } }
  @media (max-width: 1250px) {
    .card-info {
      width: 32%; } }
  @media (max-width: 1150px) {
    .card-info {
      width: 36%; } }
  @media (max-width: 950px) {
    .card-info {
      width: 40%; } }
  @media (max-width: 850px) {
    .card-info {
      width: 44%; } }
  @media (max-width: 750px) {
    .card-info {
      width: 50%; } }
  @media (max-width: 650px) {
    .card-info {
      width: 60%; } }
  @media (max-width: 550px) {
    .card-info {
      width: 70%; } }

.payment__credit-cards {
  justify-content: center;
  display: flex; }

.payment__credit-cards ul {
  list-style-type: none;
  display: flex;
  padding: 0; }

.payment__credit-cards li {
  padding-right: 10px; }

.payment__card-details {
  text-align: center;
  padding: 20px; }

.payment__payment-security {
  text-align: center;
  font-size: 16px;
  padding: 0 0 80px; }
  @media (max-width: 320px) {
    .payment__payment-security {
      padding: 0 0 120px; } }

#smallBoxes {
  margin-left: 9px; }

#cross:hover {
  cursor: pointer; }

form.confirm-status-modal svg {
  padding-top: 10px;
  width: 10px;
  margin-left: 5px; }

form.confirm-status-modal select {
  max-width: 150px;
  height: 47px; }

form.confirm-status-modal .input-field {
  height: 40px; }
  form.confirm-status-modal .input-field.error:before {
    top: 50px;
    left: 150px; }

form.confirm-status-modal #timeError {
  display: none;
  margin-left: 15px; }
  form.confirm-status-modal #timeError.error {
    color: #ff0000;
    display: block; }

.order-table-btn {
  display: inline-flex;
  height: auto !important;
  padding: 10px 32px;
  align-items: center;
  justify-content: center;
  line-height: 1.5 !important; }

.pickup {
  max-width: 170px; }

.reorder-form {
  display: flex;
  justify-content: center; }

.check-price-btn {
  margin-top: 54px !important; }

.add-item {
  color: #fff;
  background-color: #b81928;
  width: 34px;
  height: 34px;
  padding: 0;
  font-size: 30px;
  min-width: 0;
  box-sizing: border-box;
  min-height: 36px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 50%;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

.btn--remove {
  display: flex;
  align-items: center;
  width: 15px;
  height: 15px;
  cursor: pointer; }
  .btn--remove svg {
    fill: #e6333d;
    width: 15px;
    height: 15px; }

.btn--rm-selection {
  display: inline-flex !important; }

.rating-rules {
  font-size: 16px; }

.rating-row {
  justify-content: space-between !important; }

.rating-btn-cont {
  justify-content: flex-end;
  padding: 0 20px; }

.remove-btn-cont {
  display: flex;
  align-items: center;
  margin-right: 34px;
  width: 15px; }

.wine-rating-panel {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.rating-info-cont {
  display: block;
  padding: 0 70px 20px 0; }

.wine-rating-panel {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.rating-info-cont {
  display: block;
  padding: 0 70px 20px 0; }

.wine-rating .form-control:disabled {
  background-color: #eee; }

.rating-blocked-cont {
  display: flex;
  justify-content: center; }

.rating-blocked {
  font-size: 24px;
  color: #b81928;
  font-weight: 700; }

.country-price__row {
  margin: 20px 0; }

.country-price__buyer-price-col {
  text-align: right; }

.blog-placeholder .blog-placeholder-image {
  background-image: url(/assets/images/blog_placeholder.jpg);
  background-size: cover;
  height: 100vh; }
  .blog-placeholder .blog-placeholder-image .blog-placeholder-label {
    font-size: 28px;
    letter-spacing: 1px;
    color: white;
    padding: 80px; }

.blog-section {
  margin-top: 10vh;
  margin-bottom: 40vh; }

.container .paginationjs-next {
  font-weight: 800;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 21px;
  cursor: pointer; }
  .container .paginationjs-next.disabled {
    opacity: 0.5; }

.container .paginationjs-prev {
  font-weight: 800;
  padding-top: 0;
  margin-left: 12px;
  margin-right: 12px;
  padding-bottom: 0;
  font-size: 21px;
  cursor: pointer; }
  .container .paginationjs-prev.disabled {
    opacity: 0.5; }

.container .paginationjs-page {
  margin-top: 4px; }

@media only screen and (max-width: 575px) {
  .container .stories-block {
    width: auto !important;
    margin-bottom: 45px; } }

@media only screen and (max-width: 575px) {
  .container .inspiration-block {
    margin-bottom: 40px; } }

.container .post-slider {
  padding: 30px; }
  .container .post-slider .post-slider-item {
    min-width: 50px;
    min-height: 250px;
    margin: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    text-decoration: none; }
    .container .post-slider .post-slider-item img {
      min-height: 222px;
      object-fit: cover;
      max-height: 222px;
      width: 100% !important; }
  .container .post-slider .slick-slide {
    text-align: center; }

.container .row-divider {
  border: 0.25px dashed rgba(0, 0, 0, 0.25); }

.container .blog-section .container {
  text-align: center; }

.container .blog-post-meta {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 18px; }
  .container .blog-post-meta .meta-date {
    font-weight: 300;
    margin-bottom: 20px;
    font-family: 'Lato'; }
  .container .blog-post-meta .meta-description {
    padding-top: 24px;
    font-size: 16px; }

.container .blog-post-content p {
  margin-bottom: 10px !important; }
  .container .blog-post-content p span {
    font-family: 'Freigeist-WideRegular' !important; }

.container .blog-post-content p:last-child img {
  object-fit: contain;
  margin-top: -80px;
  margin-bottom: -100px; }
  @media only screen and (max-width: 575px) {
    .container .blog-post-content p:last-child img {
      height: 250px;
      margin-top: -40px;
      margin-bottom: -40px; } }

.container .blog-post-content p:last-child img {
  object-fit: contain;
  margin-top: -80px;
  margin-bottom: -100px; }
  @media only screen and (max-width: 575px) {
    .container .blog-post-content p:last-child img {
      height: 250px;
      margin-top: -40px;
      margin-bottom: -40px; } }

.container .blog-post-content p:last-child iframe {
  width: 100%; }
  @media only screen and (max-width: 575px) {
    .container .blog-post-content p:last-child iframe {
      height: 250px; } }

.container .blog-post-footer {
  margin-top: 42px;
  text-align: center; }
  .container .blog-post-footer .related-posts {
    margin-top: 42px; }
    .container .blog-post-footer .related-posts .related-posts-label {
      text-transform: uppercase;
      text-align: left; }
    .container .blog-post-footer .related-posts .related-stories {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
  .container .blog-post-footer .blog-post-nav {
    margin-top: 42px;
    text-align: center; }
    .container .blog-post-footer .blog-post-nav .nav-row {
      margin-top: 18px;
      margin-bottom: 18px; }
      @media only screen and (max-width: 575px) {
        .container .blog-post-footer .blog-post-nav .nav-row {
          display: flex;
          flex-direction: column; } }
    .container .blog-post-footer .blog-post-nav .nav-row-item {
      margin-right: 24px;
      margin-left: 24px;
      text-decoration: none;
      color: #b91828;
      font-weight: 600;
      font-size: 18px;
      text-transform: uppercase; }

.container .blog-post-footer-button {
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  background-color: #b91828;
  padding-right: 70px;
  color: white;
  padding-left: 70px;
  padding-top: 18px;
  padding-bottom: 18px;
  text-transform: uppercase; }

.container .see-more-area {
  text-align: center;
  margin-top: 32px; }
  .container .see-more-area .see-more-blogs {
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    background-color: #e6333d;
    padding-right: 72px;
    color: white;
    padding-left: 72px;
    padding-top: 6px;
    padding-bottom: 6px;
    text-decoration: none; }

.container .post-item-title {
  padding-top: 20px;
  font-size: 14px;
  font-weight: 500; }

.container .post-item-date {
  font-size: 10px;
  padding-top: 8px;
  font-weight: 300;
  padding-bottom: 20px;
  font-family: 'Lato'; }

.container .blog-divider {
  border: 0;
  border-top: 2.5px solid #b91828; }

.container .sub-label {
  display: flex; }

.container .sub-cat-heading-green {
  color: #b91828;
  font-weight: 600; }

.container .sub-cat-heading {
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 4px; }

.container .sub-cat-heading-add {
  margin-right: 4px; }

.container .sub-cats-content {
  margin-left: 10%;
  margin-right: 10%; }

.container .blog-list-container {
  margin-left: 3%;
  margin-right: 3%; }

.container .main-label {
  padding-top: 42px;
  margin-bottom: 18px;
  text-align: center;
  font-size: 32px;
  letter-spacing: 2px;
  color: #b81927;
  margin-bottom: 64px;
  text-transform: lowercase; }

@media only screen and (max-width: 575px) {
  .container .post-all-list {
    display: flex;
    flex-direction: column; } }

@media (min-width: 1200px) {
  .container .post-all-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; } }

@media only screen and (max-width: 575px) {
  .container .post-block {
    margin: 0 auto;
    margin-bottom: 3%;
    margin-top: 3%;
    width: 100%;
    min-height: 200px;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); } }

@media (min-width: 1200px) {
  .container .post-block {
    width: 21.33333%;
    margin-right: 1.5%;
    margin-left: 1.5%;
    margin-bottom: 1.5%;
    min-height: 200px;
    margin-top: 1.5%;
    text-decoration: none;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); } }

.container .sub-main-label {
  text-align: center;
  letter-spacing: 2px;
  font-size: 21px;
  margin-bottom: 24px;
  color: #505050; }

.container .paginationjs-pages {
  text-align: center; }
  .container .paginationjs-pages .posts-page-navigation {
    display: inline-block;
    list-style: none; }
  .container .paginationjs-pages .posts-page-navigation > li {
    color: black;
    float: left;
    padding: 4px 8px;
    text-decoration: none; }
    .container .paginationjs-pages .posts-page-navigation > li.active {
      color: #b91828;
      border-radius: 50%;
      border: 1px solid #b91828; }
  .container .paginationjs-pages .posts-page-navigation a {
    text-decoration: none; }

@media only screen and (max-width: 575px) {
  .container .blog-main-nav {
    display: flex;
    flex-direction: column; } }

@media only screen and (min-width: 576px) {
  .container .blog-main-nav {
    display: flex;
    flex-direction: column; } }

@media (min-width: 1200px) {
  .container .blog-main-nav {
    display: flex;
    justify-content: center;
    flex-direction: initial; } }

.container .blog-main-nav .nav-link {
  text-decoration: none; }
  @media only screen and (max-width: 575px) {
    .container .blog-main-nav .nav-link {
      margin: 10px; } }
  .container .blog-main-nav .nav-link .nav-item {
    min-width: 225px;
    min-height: 325px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background: #fff;
    text-align: center;
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px; }
    .container .blog-main-nav .nav-link .nav-item .nav-circle {
      position: relative; }
      .container .blog-main-nav .nav-link .nav-item .nav-circle .blog-nav-img-text {
        text-shadow: 5px 1px 4px black, 0 0 1em;
        position: absolute;
        color: white;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: 600;
        text-transform: lowercase; }
      .container .blog-main-nav .nav-link .nav-item .nav-circle .blog-nav-img {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 338px;
        height: 241px;
        object-fit: cover; }
        @media only screen and (max-width: 575px) {
          .container .blog-main-nav .nav-link .nav-item .nav-circle .blog-nav-img {
            width: 100%;
            min-height: auto; } }
        @media only screen and (min-width: 576px) {
          .container .blog-main-nav .nav-link .nav-item .nav-circle .blog-nav-img {
            width: 100%;
            min-height: auto; } }
        .container .blog-main-nav .nav-link .nav-item .nav-circle .blog-nav-img:hover {
          filter: brightness(50%); }

.wine-trip-title {
  margin-bottom: 15px;
  font-size: 24px;
  text-align: center;
  text-transform: lowercase; }

.center-menu-trips {
  text-align: center;
  margin: auto; }
  .center-menu-trips .trip-row {
    display: flex;
    justify-content: center; }
    @media only screen and (max-width: 575px) {
      .center-menu-trips .trip-row {
        display: flex;
        flex-direction: column; } }
    .center-menu-trips .trip-row .trip-element {
      text-align: center;
      width: 700px;
      height: 350px;
      background-size: cover;
      margin: 10px;
      text-decoration: none;
      border-radius: 10px; }
      .center-menu-trips .trip-row .trip-element .trip-element-label {
        text-shadow: 5px 1px 4px black, 0 0 1em;
        font-size: 42px;
        color: #fff;
        font-weight: 600;
        text-transform: capitalize;
        vertical-align: middle;
        line-height: 350px;
        text-align: center; }
      .center-menu-trips .trip-row .trip-element#trip-france {
        background-image: url(/assets/images/trip-france.jpeg); }
      .center-menu-trips .trip-row .trip-element#trip-italy {
        background-image: url(/assets/images/trip-italy.jpeg); }
      .center-menu-trips .trip-row .trip-element#trip-germany {
        background-image: url(/assets/images/trip-germany.jpeg); }
      .center-menu-trips .trip-row .trip-element#trip-spain {
        background-image: url(/assets/images/trip-spain.jpeg); }
      @media only screen and (max-width: 575px) {
        .center-menu-trips .trip-row .trip-element {
          width: auto;
          height: auto; } }

.main-banner--about-us {
  background-position: center; }

.discountType span#select2-type-container {
  background-color: #d3d3d3; }

#discount-nav-bar {
  height: 50px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: flex-end; }
  @media (min-width: 1200px) {
    #discount-nav-bar {
      max-width: 90%; } }
  #discount-nav-bar .btn svg {
    fill: #fff;
    width: 20px;
    height: 16px;
    display: inline-block;
    vertical-align: middle; }

.success-cont {
  margin: 25px;
  text-align: center; }

.success-main {
  margin: 0;
  color: #e6333d;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 32px; }

.success-common {
  margin: 0;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 32px; }
