#cookieConsent {
  font-family: 'Freigeist-WideRegular';
  background-color: $secondary-color;
  min-height: 26px;
  font-size: 15px;
  color: #fff;
  line-height: 26px;
  padding: 8px 5vw 8px 5vw;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 9999;
}
#cookieConsent a {
  color: #fff;
  font-weight: bold;
}
.access-buttons {
  display: flex;
  align-items: center;
  justify-content: center;

  button:first-child {
    margin-right: 30px;
  }
}
