.auth-container {
  margin: 0 auto;
  padding: 50px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include respond-to(handhelds) {
    max-width: 280px;
  }
  &--login {
    .page-title {
      margin-bottom: 20px;
    }
    .auth-form {
      .input-field {
        &.error {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
.auth-form {
  //label {
  //  font-size: 12px;
  //  color: $default-lighter;
  //  margin-bottom: 3px;
  //}
  //.form-control {
  //  height: 30px;
  //  line-height: 30px;
  //  padding: 0 10px;
  //  font-size: 12px;
  //}
  //.btn-holder {
  //  padding: 10px 0;
  //}
  //.btn {
  //  font-size: 12px;
  //}

  &--reset-pass {
    .btn {
      display: block;
      margin: 26px auto 0;
      border-radius: 20px;
    }
    .input-field {
      &.error {
        &:before {
          visibility: visible;
          opacity: 1;
          top: 75px;
        }
      }
    }
  }
  .checkbox label {
    text-transform: none;
  }
  .input-field--checkbox {
    &.error {
      &:before {
        top: 40px;
        @include respond-to(wide-handhelds) {
          top: 43px;
        }
        @include respond-to(tablets) {
          top: 27px;
        }
        @include respond-to(desktop) {
          top: 23px;
        }
      }
    }
  }
}
.form__link {
  display: block;
  text-align: center;
  margin: 0 auto;
  padding: 10px 0;
  color: $default-lighter;
}

small.form-text {
  margin: 0.25rem 0 0.7rem;
  font-size: 14px;
}

.form-error {
  display: none;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  padding: 10px 4px;
  border-radius: 2px;
  background: $error;
  max-width: 280px;
  margin: 0 auto 15px;
  &--show {
    display: block;
  }
  p {
    margin: 0;
    & + p {
      margin-top: 7px;
    }
  }
}
#userPasswordHelp {
  margin-top: 15px;
}
