.cart-section {
  height: 100%;
  margin-bottom: 10rem;

  form {
    height: 100%;
  }
}
#continueInEstate {
  float: right;
}
.cart-section__head {
  display: flex;
  align-items: center;
  padding: 30px 0;

  .section-title {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    margin: 0;
  }

  .col {
    text-align: center;
    @include respond-to(wide-handhelds) {
      text-align: left;
    }
  }

  &.empty-cart-head {
    height: 100%;
  }

  &.empty-cart-head .row,
  &.empty-cart-head .container {
    height: 100%;
  }

  &.empty-cart-head .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.empty-cart-head h4 {
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 15px;
  }

  @media (max-width: 767px) {
    .btn-holder {
      justify-content: center;
    }
  }

  &.stuck {
    position: fixed;
    left: 0;
    top: 45px;
    align-items: center;
    padding: 10px 0;
    width: 100%;
    background: white;
    border-bottom: 1px solid #c8c8c8;
    z-index: 100;

    @include respond-to(tablets) {
      top: 70px;
    }
  }
}

.total-cart-head__sum {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 30px;
  color: $default-lighter;
  text-transform: uppercase;
  font-size: 16px;

  strong {
    color: $default-color;
    font-size: 18px;
    display: inline-block;
    margin-left: 7px;
  }
}

.btn--continue {
  height: 40px;
  line-height: 40px;
  text-transform: uppercase;
  border-radius: 20px;
  margin: 0 15px 0 0;
  border: 1px solid;
}

.js-prev-step {
  border-radius: 20px;
}

.cart-section__content {
  padding: 45px 0;
}

.cart__free-shipping-details {
  color: #b81928;
  font-size: 16px;
}

.cart__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $border-color;
}

.cart__item-img {
  flex: 0 0 100%;
  max-height: 300px;
  @include respond-to(wide-handhelds) {
    flex: 0 0 20%;
    max-height: 245px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.cart__item-details {
  position: relative;
  flex: 0 0 100%;
  padding: 15px 0;
  @include respond-to(wide-handhelds) {
    flex: 0 0 80%;
    padding: 15px 60px 15px 30px;
  }
}

.cart__item-title {
  margin-bottom: 20px;

  a {
    text-decoration: none;
  }
}

.cart__item-quantity {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @include respond-to(handhelds) {
    flex-direction: row;
    align-items: center;
  }

  .label {
    display: inline-block;
    margin: 0 42px 0 0;
    color: $default-lighter;
    @include respond-to(handhelds) {
      margin: 0 42px 0 0;
    }
  }
}

.cart__item-quantity-checkout {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @include respond-to(handhelds) {
    flex-direction: column;
    align-items: flex-start;
  }

  .label {
    display: inline-block;
    margin: 0 42px 0 0;
    color: $default-lighter;
    @include respond-to(handhelds) {
      margin: 0 42px 0 0;
    }
  }
}

.cart__item-coupon {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  @include respond-to(handhelds) {
    flex-direction: row;
    align-items: flex-end;
  }

  .label-coupon {
    margin: 0 17px 0 0 !important;
  }
}

.coupon {
  display: flex;
  flex-direction: column;
  @include respond-to(handhelds) {
    margin-bottom: 5px;
  }
}

.cart__item-price {
  font-size: 20px;
  text-transform: uppercase;
  display: inline-block;
  margin: 15px 0 0;
  @include respond-to(handhelds) {
    margin: 0 0 0 15px;
  }
}

.cart__item-seller {
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    font-size: 16px;
    font-weight: $font-bold;
  }
}

.cart__item-seller-img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.remove-cart__item {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;

  @include respond-to(handhelds) {
    right: 15px;
    top: 10px;
  }

  svg {
    width: 15px;
    height: 15px;
    fill: $default-lighter;
  }
}

.cart__total {
  @media (max-width: 767px) {
    .btn-holder {
      justify-content: center;
    }
  }
}
.cart__total-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0 45px;
  @include respond-to(wide-handhelds) {
    justify-content: flex-end;
    flex-direction: row;
  }
}
.cart__total-header--left {
  padding: 0;
  margin: 0 0 15px;

  @include respond-to(wide-handhelds) {
    max-width: 50%;
    padding: 0 30px;
    margin: 0;
  }
  @include respond-to(desktop) {
    max-width: 25%;
  }
  span {
    color: $secondary-color;
  }
}
.cart__total-sum {
  //display: flex;
  //align-items: center;
  //justify-content: flex-end;
  //padding: 15px 0 45px;
  font-size: 24px;
  font-weight: $font-bold;
  color: $default-lighter;
  margin: 0 0 15px;

  @include respond-to(wide-handhelds) {
    margin: 0;
  }

  span {
    //display: block;
    margin-left: 15px;
    color: $default-color;
  }
}

.cart__product-description-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .description {
    display: none;
    margin-bottom: 5px;
  }

  .expand-button {
    padding: 0;
    color: #b6b6b4;
    font-size: 14px;
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;
  }
}

.undeliverable-products {
  background: #f2f4ef;
  padding: 20px 20px;
  margin-bottom: 30px;
}
