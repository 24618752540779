.discountType span#select2-type-container {
  background-color: $border-color;
}

#discount-nav-bar {
  height: 50px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;

  display: flex;
  justify-content: flex-end;

  @include respond-to(desktop) {
    max-width: 90%;
  }

  .btn {
    svg {
      fill: #fff;
      width: 20px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
