.winejumper-section {
  padding: 60px 0;
  @include respond-to(wide-handhelds) {
    padding: 105px 0;
  }
}

.winejumper-list {
  @include reset(ul);
  display: flex;
  flex-wrap: wrap;
  @include respond-to(tablets) {
    max-width: 85%;
    margin: 0 auto;
  }
}

.winejumper__item {
  flex: 0 0 100%;
  text-align: center;
  @include respond-to(wide-handhelds) {
    flex: 0 0 50%;
  }

  .winejumper-list & {
    padding: 30px;
    @include respond-to(wide-handhelds) {
      padding: 30px 5% 40px;
    }
    @include respond-to(desktop) {
      padding: 30px 7.5% 40px;
    }
  }

  &:first-of-type {
    border: 1px solid $primary-color;
  }

  &:last-of-type {
    background: $primary-color;
    position: relative;

    @include respond-to(wide-handhelds) {
      margin-left: -10px;
    }
    @include respond-to(tablets) {
      margin: 0;
    }

    &:before {
      @include respond-to(wide-handhelds) {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: -10px;
        top: -10px;
        border: 10px solid $primary-color;
        z-index: -1;
      }
      @include respond-to(tablets) {
        left: -20px;
        top: -20px;
        border: 20px solid $primary-color;
      }
    }
  }
}

.winejumper__item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    font-weight: $font-regular;
    line-height: 1;
    color: $secondary-color;
    margin: 0 0 15px;
    @include respond-to(wide-handhelds) {
      font-size: 28px;
    }
    @include respond-to(tablets) {
      font-size: 36px;
      margin: 0 0 30px;
    }

    &--badge {
      position: relative;

      &:after {
        content: attr(data-badge);
        display: inline-block;
        vertical-align: middle;
        height: 22px;
        padding: 0 10px;
        margin: 0 0 0 10px;
        font-size: 14px;
        line-height: 22px;
        font-weight: $font-bold;
        color: #fff;
        text-transform: uppercase;
        background: $secondary-color;
        border-radius: 6px;

        @include respond-to(wide-handhelds) {
          height: 25px;
          font-size: 16px;
          line-height: 25px;
        }
        @include respond-to(tablets) {
          height: 30px;
          font-size: 18px;
          line-height: 30px;
        }
      }
    }
  }

  p {
    margin: 0 0 30px;
    @include respond-to(tablets) {
      font-size: 18px;
    }
  }

  .btn {
    max-width: 280px;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    text-transform: uppercase;

    &.disabled {
      opacity: .6;
      cursor: default;
      pointer-events: none;

      &:hover, &:focus {
        cursor: default;
        pointer-events: none;
      }
    }
  }
}
