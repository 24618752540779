.profile-section {
  padding: 60px 0;

  &--order-details {
    .page-title {
      text-align: left;
    }
  }
}

.profile-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: $font-regular;
  text-align: center;
  margin: 0 0 15px;
}

.input-field__dob {
  max-width: 200px;
}

.profile-subtitle {
  font-size: 14px;
  font-weight: $font-light;
  color: $default-light;
  text-align: center;
  margin: 0 0 40px;
}

.promo-select-subtext {
  color: #999;
  margin-left: 5px;
}

.profile-form {
  .input-field {
    margin: 0 0 25px;

    label {
      text-transform: uppercase;
      margin: 0 0 15px;
    }

    h4 {
      font-size: 15px;
      line-height: 1;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0 0 15px;
    }
  }

  .upload-group {
    label {
      margin: 0;
      text-transform: none;
      font-weight: $font-bold;
      background: rgba($default-light, 0.15);
      cursor: pointer;

      @include respond-to(wide-handhelds) {
        min-height: 130px;
        font-size: 14px;
      }

      @include respond-to(tablets) {
        min-height: 217px;
        font-size: 15px;
      }
    }

    &--banner {
      label {
        @include respond-to(tablets) {
          min-height: 315px;
        }
      }
    }

    &--profile {
      max-width: 150px;

      label {
        min-height: 150px;
      }
    }
  }

  .btn-holder {
    padding: 30px 0;
    gap: 5px;
  }

  .btn--dark {
    font-size: 10px;
    line-height: 40px;
    height: 40px;
    border-radius: 20px;
    text-transform: uppercase;

    @include respond-to(handhelds) {
      font-size: 14px;
    }
  }

  .btn--default {
    font-size: 10px;

    @include respond-to(handhelds) {
      font-size: 14px;

    }
  }
}

.image-upload-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  @include respond-to(wide-handhelds) {
    flex-direction: row;
  }
}

.upload-group {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid $border-color;

  &+.upload-group {
    margin: 15px 0 0;

    @include respond-to(wide-handhelds) {
      margin: 0 0 0 15px;
    }
  }

  &.has-error {
    border: solid 1px $error;
  }

  label {
    display: block;
    position: relative;
    z-index: 2;
    min-height: 200px;
    margin: 0;
    background: #fff;
  }

  [type='file'] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
  }

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.preview-thumb {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: #fff;

  &.show-preview {
    opacity: 1;
    visibility: visible;
    z-index: 3;
  }

  .imageThumb {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:hover .remove-preview {
    opacity: 1;
    visibility: visible;
  }
}

.remove-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($default-color, 0.3);
  opacity: 0;
  visibility: hidden;
  @include transition;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background: rgba(#fff, 0.8);
    border-radius: 50%;
    cursor: pointer;
  }

  svg {
    width: 20px;
    height: 20px;
    fill: $secondary-color;
  }
}

.tab-content {
  .profile-title {
    text-align: left;
  }
}

.profile-delivery-form {
  margin-bottom: 30px;
}

.delivery-address-box {
  display: flex;
  flex-wrap: wrap;

  .profile-title {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.saved-address {
  flex: 0 0 23%;
  border: 1px solid $border-color;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  margin-right: 15px;
  margin-bottom: 15px;

  .details {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
}

.delete-address {
  width: 40px;
  height: 40px;
  background: rgba($secondary-color, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  @include transition;

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: #fff;
  }
}

.profile-items-search {
  background: $primary-color;
  padding: 30px 0;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include respond-to(handhelds) {
      flex-direction: row;
    }
  }

  .btn {
    svg {
      fill: #fff;
      width: 20px;
      height: 16px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.items-search-form {
  width: 100%;
  position: relative;
  margin-bottom: 15px;

  @include respond-to(handhelds) {
    margin-bottom: 0;
    max-width: 250px;
  }

  .input-field {
    margin: 0;

    input {
      padding-right: 45px;
    }
  }
}

.items-search__btn {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 40px;
  height: calc(100% - 2px);
  background: #fff;
  border: 0;
  cursor: pointer;
}

.profile-items-section,
.profile-orders-section {
  padding: 30px 0;
}

.items-table {
  display: block;

  @include respond-to(wide-handhelds) {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  &--orders {
    .items-table__body {
      .items__row {
        &:nth-of-type(odd) {
          background: $primary-color;
        }
      }
    }
  }
}

.items-table__body {
  display: block;

  @include respond-to(wide-handhelds) {
    display: table-row-group;
  }
}

.items-table__head {
  display: none;

  @include respond-to(wide-handhelds) {
    display: table-header-group;
    border-bottom: 1px solid $border-color;
  }

  .table__item {
    font-weight: $font-bold;
    text-transform: uppercase;
    font-size: 12px;

    @include respond-to(tablets) {
      font-size: 14px;
    }
  }
}

.items__row {
  display: block;

  @include respond-to(wide-handhelds) {
    display: table-row;
    height: 58px;
    border-bottom: 1px solid $border-color;
  }
}

.table__item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0.625em;

  @include respond-to(wide-handhelds) {
    display: table-cell;
  }

  &:not(:first-of-type) {
    @include respond-to(wide-handhelds) {
      text-align: center;
    }
  }

  &:before {
    content: attr(data-label);
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 7px;
    font-weight: $font-bold;
    font-size: 14px;
    text-transform: uppercase;

    @include respond-to(wide-handhelds) {
      display: none;
    }
  }

  .btn {
    font-size: 12px;
    height: 30px;
    line-height: 30px;
  }
}

.item__details {
  display: flex;

  @include respond-to(wide-handhelds) {
    align-items: center;
  }

  img {
    width: 130px;
  }
}

.item__title {
  padding-top: 10px;
  padding-left: 15px;
  font-size: 14px;

  @include respond-to(wide-handhelds) {
    padding-top: 0;
  }
}

.item-actions-list {
  display: flex;
  align-items: center;

  @include respond-to(wide-handhelds) {
    flex-direction: column;
    justify-content: center;
  }

  a {
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    margin: 0 15px 0 0;

    @include respond-to(wide-handhelds) {
      margin: 0;
    }

    svg {
      display: inline-block;
      vertical-align: middle;
      @include transition;
    }
  }
}

.btn--edit {

  &:hover,
  &:focus {
    svg {
      fill: $default-color;
    }
  }

  svg {
    fill: $default-light;
    width: 26px;
    height: 26px;
  }
}

.btn--remove {
  svg {
    fill: $secondary-color;
    width: 20px;
    height: 20px;
  }
}

.order-details__row {
  display: flex;
  flex-wrap: wrap;

  @include respond-to(wide-handhelds) {
    margin-bottom: 30px;
  }
}

.order-details__item {
  flex: 0 0 100%;
  margin-bottom: 10px;

  @include respond-to(wide-handhelds) {
    flex: 0 0 33.333333%;
    word-break: break-word;
    padding: 0 5px;
    margin: 0;
  }

  &--total {
    background: $primary-color;
    border-radius: 6px;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .order-item__title {
      color: $default-color;
    }
  }
}

.order-item__title {
  color: $secondary-color;
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: $font-bold;
}

.order-total__table {
  padding: 15px 0;
}

.order-total__row {
  display: flex;
  align-items: center;

  &+& {
    margin-top: 5px;
  }

  .label {
    min-width: 155px;
  }
}

.order-item__table {
  .items-table {
    margin-bottom: 30px;
  }
}

.btn--download {
  svg {
    transform: rotate(180deg);
    transform-origin: 50% 45%;
    fill: #fff;
    width: 20px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
  }
}

.orders-status {
  padding: 10px 0 30px;
  max-width: 50%;

  .radiobtn {
    position: relative;
  }

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type='radio']:checked+label,
  [type='radio']:not(:checked)+label {
    position: relative;
    display: block;
    padding: 0 15px 0 30px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    text-transform: uppercase;
    color: $default-lighter;
    cursor: pointer;
  }

  [type='radio']:checked+label:before,
  [type='radio']:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 7px;
    top: 50%;
    margin-top: -6px;
    width: 12px;
    height: 6px;
    border: 0;
    border-left: 1px solid $default-lighter;
    border-bottom: 1px solid $default-lighter;
    transform: rotate(-45deg);
  }

  [type='radio']:checked+label {
    background: $secondary-color;
    color: #fff;
  }

  [type='radio']:checked+label:before {
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }
}

.confirm-status-modal {
  .fancybox-close-small {
    display: none;
  }
}

.modal-title {
  text-align: center;
  margin-bottom: 15px;
}