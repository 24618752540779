*{
  box-sizing: border-box;
}
html {
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  overflow-x: hidden;
}
html, body {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
main {
  display: block;
}
small {
  font-size: 80%;
}
img {
  border-style: none;
  max-width: 100%;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button {
  display: none;
}
h1, h2, h3, h4, h5 {
  margin: 0;
}
