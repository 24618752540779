.success-cont {
  margin: 25px;
  text-align: center;
}

.success-main {
  margin: 0;
  color: #e6333d;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 32px;
}

.success-common {
  margin: 0;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 32px;
}
