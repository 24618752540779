.card-info {
  display: flex;
  width: 15%;
  flex-direction: column;
  align-items: center;

  .row {
    width: 65%;
  }

  .card {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 14px;
    font-size: 20px;

    &:hover {
      cursor: pointer;
      background-color: rgba(128, 128, 128, 0.3);
    }

    .asterix {
      display: inline-block;
      height: 16px;
    }
  }

  .profile-title {
    margin-top: 25px;
  }

  @media (max-width: 2100px) {
    width: 18%;
  }

  @media (max-width: 1800px) {
    width: 22%;
  }

  @media (max-width: 1600px) {
    width: 28%;
  }

  @media (max-width: 1250px) {
    width: 32%;
  }

  @media (max-width: 1150px) {
    width: 36%;
  }

  @media (max-width: 950px) {
    width: 40%;
  }

  @media (max-width: 850px) {
    width: 44%;
  }

  @media (max-width: 750px) {
    width: 50%;
  }

  @media (max-width: 650px) {
    width: 60%;
  }

  @media (max-width: 550px) {
    width: 70%;
  }
}
.payment__credit-cards {
  justify-content: center;
  display: flex;
}
.payment__credit-cards ul {
  list-style-type: none;
  display: flex;
  padding: 0;
}
.payment__credit-cards li {
  padding-right: 10px;
}
.payment__card-details {
  text-align: center;
  padding: 20px;
}
.payment__payment-security {
  text-align: center;
  font-size: 16px;
  padding: 0 0 80px;
  @media (max-width: 320px) {
    padding: 0 0 120px;
  }
}
