.product-section {
  @include respond-to(wide-handhelds) {
    background: linear-gradient(
      to right,
      $primary-color 0%,
      $primary-color 50%,
      #fff 50%,
      #fff 100%
    );
  }
}

.product__text {
  padding-top: 30px;
  order: 2;
  @include respond-to(wide-handhelds) {
    order: 1;
  }
}

.product-seller__name {
  font-weight: $font-bold;
  font-size: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block;
}

.about-seller__link {
  text-decoration: none;
  text-transform: uppercase;
  color: $secondary-color;
}

.product-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-content__item,
.product-footer {
  border-bottom: 1px solid #c8c8c8;
  padding-bottom: 20px;
  padding-top: 20px;
}

.scores-expertise {
  font-weight: 600;
}

.product__name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block;
}

.product__price {
  display: block;
  font-size: 16px;
  color: #666666;
  text-transform: uppercase;
  margin: 0 0 10px;
}

.product__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-content__item--desc {
  font-size: 12px;
  line-height: 1.4;

  h5 {
    color: #999;
    font-weight: $font-regular;
    margin: 0 0 20px;
    font-size: 12px;
    line-height: 1.4;
  }

  .description {
    font-family: 'Freigeist-WideRegular';
  }
}

.product-details-list {
  @include reset(ul);

  li {
    margin-bottom: 15px;

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.product-detail__label {
  color: #999;
  font-size: 12px;
  margin-right: 10px;
}

.product-detail__value {
  font-size: 14px;
}

.product-footer {
  padding-top: 20px;
}

.product-reviews-block {
  padding: 20px 0;
}

.product-reviews__title {
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: $font-light;
}

.product__photo {
  position: relative;
  order: 1;
  @include respond-to(wide-handhelds) {
    order: 1;
  }
}

.product-slider {
  overflow: hidden;
  height: 270px;
  margin-bottom: 20px;
  @include respond-to(wide-handhelds) {
    height: 700px;
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }
}

.product-slider-controls {
  @include respond-to(wide-handhelds) {
    display: none;
  }
}

.product-slider__item {
  a {
    display: block;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.product-preview {
  overflow: hidden;
  height: 125px;
  display: none;
  @include respond-to(wide-handhelds) {
    display: block;
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }
}

.product-preview__item {
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.recommended-section {
  padding: 30px 0;
  padding: 30px 0 30px;

  .section-title {
    margin-bottom: 30px;
  }
}

.recommended-products__content {
  position: relative;
}
.recommended-products-slider {
  max-width: 85%;
  margin: 0 auto;
  @media (min-width: 1300px) {
    max-width: 100%;
  }
}
.recommended__product {
  padding: 0 10px;
  .product__item {
    border: 1px solid $default-lighter;
    padding: 10px;
  }
  .product__item-image {
    display: flex;
    height: 270px;
    @include respond-to(wide-handhelds) {
      height: 320px;
    }
  }
}

.recommended-slider-controls {
  .slick-arrow {
    width: 50px;
    height: 50px;

    &.prev-arrow {
      left: -15px;
      @include respond-to(wide-handhelds) {
        left: 0;
      }
      @media (min-width: 1300px) {
        left: -50px;
      }
    }

    &.next-arrow {
      right: -15px;
      @include respond-to(wide-handhelds) {
        right: 0;
      }
      @media (min-width: 1300px) {
        right: -50px;
      }
    }
  }
}

:root {
  --star-size: 60px;
  --star-color: #fff;
  --star-background: #b81928;
  --shift: 0px;
}

.stars {
  --percent: calc(var(--rating) / 5 * 100%);

  display: inline-block;
  font-size: var(--star-size);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;
  margin-right: var(--shift);

  &::before {
    content: '★★★★★';
    // letter-spacing: 1px;
    background: linear-gradient(
      90deg,
      var(--star-background) var(--percent),
      var(--star-color) var(--percent)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 200ms linear;
  }
}

.stars-container {
  display: flex;
  justify-content: space-between;
}

.product-stars {
  padding: 10px 0 0 0;
}

.stars-filter {
  color: #b81928;
}

.delivery-country-cont {
  display: flex;
  justify-content: flex-end;
}

.delivery-sort {
  border-radius: 20px;
  font-family: 'Freigeist-WideRegular';
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.bottle-amount-cont {
  display: flex;
  align-items: baseline;

  .volume {
    margin-left: 10px;
  }
}
