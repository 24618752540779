.checkout-section {
  padding-top: 30px;
}

.checkout-form {
  .btn-holder {
    padding: 15px 0;
  }

  .btn--dark {
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    border-radius: 20px;
  }

  .btn--apply {
    color: #000000;
    background: #efefef;
    border-radius: 20px;
    text-transform: uppercase;
  }
}

.unregistered-checkout__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $primary-color;
  padding: 45px 15px;
  margin-bottom: 30px;
}

.registered-checkout__content {
  .saved-address {
    flex: 0 0 100%;

    @include respond-to(wide-handhelds) {
      flex: 0 0 50%;
    }

    @include respond-to(tablets) {
      flex: 0 0 23%;
    }
  }
}

.checkout-seller__name {
  display: inline-block;
  width: 100%;
  background: $border-color;
  padding: 15px 10px;
  font-size: 16px;
  line-height: 1;
  font-weight: $font-bold;
}

.checkout-order__content {
  display: flex;
  flex-wrap: wrap;
}

.checkout-items__list {
  flex: 0 0 100%;
  @include reset(ul);

  @include respond-to(wide-handhelds) {
    flex: 0 0 70%;
  }
}

.checkout__item {
  display: flex;
  padding: 20px 0;

  &+& {
    border-top: 1px solid $border-color;
  }
}

.checkout__item-img {
  width: 100px;
  height: 100px;
  background: #fff;
  border: 1px solid $border-color;
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.checkout__item-info {
  padding: 0 30px;
}

.checkout__item-title {
  font-size: 16px;
  line-height: 1;
  font-weight: $font-bold;
  margin-bottom: 15px;
}

.checkout__item-qty {
  display: block;
  margin-bottom: 10px;
}

.checkout__item-price {
  display: block;
  font-weight: $font-bold;
}

.checkout-order__delivery {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $border-color;
  padding: 10px 15px;
  margin-bottom: 15px;

  @include respond-to(wide-handhelds) {
    flex: 0 0 30%;
    border-top: 0;
    margin-bottom: 0;
  }
}

.checkout-order__item:last-of-type {
  .checkout-order__delivery {
    border-bottom: 1px solid $border-color;
  }
}

.delivery__title {
  font-size: 14px;
  line-height: 1;
  font-weight: $font-bold;
  margin-bottom: 10px;

  span {
    font-weight: $font-regular;
  }
}

.checkout-order__free-shipping {
  color: $secondary-color;
}

.checkout-order__extra_block {
  display: flex;
  justify-content: center;
  flex-direction: column;

  &-notification {
    max-width: 285px;
    color: $secondary-color;
    align-self: flex-end;
    padding: 7px 15px;
  }

  &-pay-tab {
    max-width: 100%;
    padding: 7px;
    color: $secondary-color;
    text-align: justify;

    @include respond-to(tablets) {
      max-width: 60%;
    }
  }

  &-description {
    color: grey;
  }

  .input-field {
    margin-top: 20px;
  }

  textarea {
    background: #eee;
  }
}

.review {
  @include respond-to(tablets) {
    max-width: 100%;
    padding-left: 0;
  }
}

.checkout-total {
  border: 1px solid $border-color;
  padding: 10px;
}

.checkout-total__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid $border-color;
}

.edit-delivery__address {
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: $default-lighter;
  }
}

.checkout-total__title {
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
}

.checkout-total__address {
  margin-bottom: 45px;
  color: $default-lighter;

  span {
    display: block;
  }
}

.checkout-total__sum {
  @include reset(ul);
  border-bottom: 1px solid $border-color;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .label {
    color: $default-lighter;
  }
}

.checkout-product-price {
  @include reset(ul);

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }

  .label {
    color: $default-lighter;
    width: 70px;
  }
}

.checkout-total__box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;

  h4 {
    color: $default-lighter;
    font-size: 18px;
    line-height: 1;
  }

  strong {
    font-size: 20px;
  }
}

.cards-container {
  display: flex;
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center;

  #payment-form {
    width: 25%;

    @include respond-to(phones) {
      width: 50%;
    }
  }

  #payment-form .btn-holder {
    justify-content: center;
  }

  .StripeElement {
    padding: 20px;
    border: solid 1px #d3d3d3;
  }


  #styled-stripe-button {
    width: 30%;
    position: absolute;
    left: 35%;
    margin-bottom: 20px;
  }


  .card-elements-group .card-elements-group__label-title {
    display: block;
    margin: 0 0 8px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }

  #card-number-element,
  #card-expiry-element,
  #card-cvc-element {
    margin-bottom: 20px;
  }

  .card-elements-group__card-number,
  .card-elements-group__card-expiry,
  .card-elements-group__card-cvc {
    position: relative;
    display: block;
    width: 100%;
  }

  .card-elements-group__card-number {
    margin-top: 15px;
  }

  .card-elements-group__card-number {
    span.brand {
      position: absolute;
      right: 10px;
      top: 50px;
      width: 30px;
    }
  }

  .card-elements-group__card-number::before,
  .card-elements-group__card-expiry::before,
  .card-elements-group__card-cvc::before {
    display: block;
    font-size: 10px;
    white-space: nowrap;
    opacity: 0;
  }

  .card-elements-group__card-number.error {
    span.brand {
      color: #fa755a;
    }
  }

  .card-elements-group__card-number.error::before,
  .card-elements-group__card-expiry.error::before,
  .card-elements-group__card-cvc.error::before {
    position: absolute;
    left: 5px;
    bottom: -14px;
    color: #fa755a;
    opacity: 1;
    content: attr(data-error);
  }
}

.col__title {
  text-transform: uppercase;
  margin: 0 0 30px;
}

.checkout-payment__option {
  font-size: 18px;
  font-weight: $font-bold;
}

.checkout-payment__summary {
  background: $primary-color;
  border-radius: 6px;
  padding: 15px 15px;

  @include respond-to(tablets) {
    max-width: 60%;
  }
}

.checkout-payment__list {
  @include reset(ul);
  border-bottom: 1px solid $default-lighter;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .label {
      color: $default-lighter;
    }
  }
}

.checkout-payment__total {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 0;

  strong {
    font-size: 18px;
    color: $secondary-color;
  }
}

.delivery-address-box {
  #postalCode {
    flex: 0 0 195px;
    border-radius: 0;
  }

  &--active {
    padding-top: 20px;
  }
}

.notification {
  margin-bottom: 0;
}

#accessPointDetails {
  margin-top: 10px;
}

.input-field label {
  text-transform: uppercase;
}

.input-field__wrapper--delivery-block {
  flex-direction: column;

  .input-field--country {
    margin-bottom: 15px;
    padding-left: 0;
    padding-right: 0;
  }

  .btn-postal-code-find {
    border-radius: 20px;
    margin-top: 30px;
  }

  @include respond-to(wide-handhelds) {
    flex-direction: row;

    .input-field--country {
      margin-bottom: 0;
      padding-right: 15px;
    }

    .btn-postal-code-find {
      margin-top: 0;
    }
  }
}

.discount {
  display: flex;
  margin-top: 15px;
  align-items: flex-end;

  input {
    height: 40px;
    border: 1px solid #d3d3d3;
    margin-top: 5px;
    line-height: 47px;
    padding: 0 20px;
    width: 95%;
    font-size: 16px;
  }
}

input[type='button'] {
  -webkit-appearance: button;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.cart__item-quantity-checkout {
  display: table;
}

.button-plus,
.button-minus,
.product_quantity {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 10px;
}

.button-plus,
.button-minus {
  width: auto;
  transition: all 300ms ease;
  font-weight: bold;
  height: 30px;
  padding: 0;
  width: 30px;
  position: relative;
  border: none;
  font-size: 18px;

  @media (max-width: 351px) {
    height: 21px;
    width: 21px;
  }
}

.product_quantity {
  position: relative;
  height: 38px;
  text-align: center;
  width: 48px;
  display: inline-block;
  font-size: 13px;
  margin: 0 0 5px;
  resize: vertical;
  -moz-appearance: textfield;
}

.button-plus {
  top: 1.4px;
}

.checkout__delivery-adress {
  display: flex;
  justify-content: space-between;
  max-width: 98%;
  margin-top: 10px;
}

.delivery-terms {
  padding-top: 20px;
}

.delivery-options-cont {
  display: flex;
  flex-direction: column;
}

.delivery-checkbox {
  display: inline-flex;
}

.age-confirmation-block {
  margin-top: 15px;
}