.check-price-btn {
  margin-top: 54px !important;
}

.add-item {
  color: #fff;
  background-color: #b81928;
  width: 34px;
  height: 34px;
  padding: 0;
  font-size: 30px;
  min-width: 0;
  box-sizing: border-box;
  min-height: 36px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 50%;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;

  justify-content: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.btn--remove {
  display: flex;
  align-items: center;
  width: 15px;
  height: 15px;
  cursor: pointer;
  svg {
    fill: $secondary-color;
    width: 15px;
    height: 15px;
  }
}

.btn--rm-selection {
  display: inline-flex !important;
}

.rating-rules {
  font-size: 16px;
}

.rating-row {
  justify-content: space-between !important;
}

.rating-btn-cont {
  justify-content: flex-end;
  padding: 0 20px;
}

.remove-btn-cont {
  display: flex;
  align-items: center;
  margin-right: 34px;
  width: 15px;
}

.wine-rating-panel {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rating-info-cont {
  display: block;
  padding: 0 70px 20px 0;
}

.wine-rating-panel {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rating-info-cont {
  display: block;
  padding: 0 70px 20px 0;
}

.wine-rating .form-control:disabled {
  background-color: #eee;
}

.rating-blocked-cont {
  display: flex;
  justify-content: center;
}

.rating-blocked {
  font-size: 24px;
  color: #b81928;
  font-weight: 700;
}

.country-price__row {
  margin: 20px 0;
}

.country-price__buyer-price-col {
  text-align: right;
}
