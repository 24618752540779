body {
  font-family: $default-font-family;
  font-size: $default-font-size;
  font-weight: $font-regular;
  max-width: 100%;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;

  main {
    flex: 1 0 auto;
    position: relative;
    z-index: 1;
    padding-top: 50px;

    @include respond-to(wide-handhelds) {
      padding-top: 71px;
    }
  }

  &.main-page {
    main {
      padding-top: 0;
    }
  }
}

a {
  @include transition;
  color: inherit;
}

img {
  max-width: 100%;
}

b,
strong {
  font-weight: $font-bold;
}

button {
  outline: none;
}

button:focus {
  background: transparent;
}

.clearfix:before,
.clearfix:after {
  display: block;
  content: ' ';
}

.irs-to:after {
  display: inline-block;
}

.clearfix:after {
  clear: both;
}

body.noscroll,
body.overflow {
  overflow: hidden;
}

noscript {
  background: #fff;
  font-size: 14px;
  width: 100%;
  z-index: 9999999;
  text-align: center;

  a {
    color: red;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.input-button-size-2 {
  margin-left: -39px !important;
  margin-right: 0px !important;
}

form {
  fieldset {
    padding-top: 30px;
    border-color: #f0ffff;

    &+fieldset {
      margin-top: 30px;
    }
  }

  legend {
    color: $secondary-color;
  }
}

input,
textarea {
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  background: #fff;
}

input[type='search'] {
  -webkit-appearance: none;
}

.hidden {
  display: none !important;
}

.unvisible {
  visibility: hidden !important;
}

.section-title {
  font-size: 24px;
  font-weight: $font-bold;
  color: $default-color;
  text-align: center;
  margin: 0 auto 30px;

  @include respond-to(tablets) {
    margin: 0 auto 72px;
    font-size: 36px;
  }
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5 {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: normal;
  line-height: 1.285714286;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

.header-banner-info {
  position: relative;
  left: 0;
  top: 51px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #c8c8c8;
  color: $secondary-color;
  text-align: center;
  z-index: 9;

  @include respond-to(wide-handhelds) {
    top: 81px;
  }
}

.page-overlay {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 57, 57, 0.5);
  display: none;
}

.fieldset-category--error {
  position: relative;
  border: solid 1px $error;

  &:before {
    content: attr(data-error);
    position: absolute;
    top: -12px;
    right: 0;
    color: $error;
    font-size: 12px;
    line-height: 14px;
  }
}

.input-field {
  margin: 0 0 14px;
  text-align: left;
  position: relative;

  &:before {
    content: attr(data-error);
    position: absolute;
    top: -12px;
    right: 0;
    color: $error;
    font-size: 12px;
    line-height: 14px;
    opacity: 0;
    visibility: hidden;
  }

  &.col {
    &:before {
      right: 15px;
    }
  }

  &.error {
    --error-top: 86px;

    &:before {
      visibility: visible;
      opacity: 1;
      top: var(--error-top);
    }

    input,
    textarea,
    select,
    span.select2 {
      border: solid 1px $error;

      @include placeholder {
        color: #000;
      }
    }
  }

  &--image {
    &.error {
      &:before {
        top: 25px;

        @include respond-to(wide-handhelds) {
          top: 0;
        }
      }

      .upload-group {
        margin-top: 45px;

        @include respond-to(wide-handhelds) {
          margin-top: 0;
        }
      }
    }
  }

  &--checkbox {
    &.error {
      &:before {
        position: relative;
        display: block;
        text-align: right;

        @include respond-to(tablets) {
          position: absolute;
          top: -13px;
        }

        @include respond-to(desktop) {
          top: -15px;
        }
      }

      .checkbox label:before {
        border-color: $secondary-color;
      }
    }
  }

  &--textarea {
    &.error {
      &:before {
        top: 290px;
      }
    }
  }

  &.align-right {
    text-align: right;
  }

  &__wrapper {
    display: flex;
    align-items: stretch;
  }

  div.error-message {
    color: $error;
    font-size: 12px;
    line-height: 14px;
  }

  label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 8px;
  }

  input,
  textarea,
  select {
    height: 47px;
    border-radius: 2px;
    background-color: #fcfcfc;
    border: solid 1px $border-color;
    line-height: 47px;
    font-size: 16px;
    font-family: $default-font-family;
    padding: 0 20px;
    width: 100% !important;

    @include placeholder {
      color: rgba($default-color, 0.5);
      font-size: 18px;
      font-family: $default-font-family;
      font-weight: $font-light;
    }
  }

  textarea {
    line-height: normal;
    padding: 20px;
    height: 248px;
    resize: none;
  }
}

.item-description:before {
  top: 160px !important;
}

.item-price:before {
  top: 100px !important;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.text-muted {
  color: #636c72;

  span {
    color: $secondary-color;
  }
}

.input-subtext {
  padding-left: 5px;
}

select {
  height: 40px;
  padding: 10px 15px;
  min-width: 150px;
  font-size: 14px;
  background-color: #fff;
  background-image: url('../assets/images/dropdown-icon.svg');
  background-position: 100% center;
  background-repeat: no-repeat;
  border-color: $default-light;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:hover {
    cursor: pointer;
  }
}

:root {
  --custom-color: #999;
}

.mandatory-field {
  --custom-color: #e6333d;
}

.checkbox {
  position: relative;

  label {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 25px;
    min-height: 30px;
    font-size: 12px;
    color: var(--custom-color);
    text-transform: uppercase;
    user-select: none;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 9px;
      background-color: #fff;
      border: 1px solid $default-light;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  [type='checkbox'] {
    position: absolute;
    opacity: 0;

    &:checked+label {
      color: $default-color;

      &:before {
        background: $secondary-color;
        border-color: $secondary-color;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        left: 7px;
        width: 6px;
        height: 11px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        margin: -2px 0 0 -1px;
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked+.slider {
      background-color: $primary-btn-bg-color;

      &:before {
        transform: translateX(26px);
      }
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  @include transition;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: #fff;
    border-radius: 50%;
    @include transition;
  }
}

.slick-initialized .slick-slide {
  outline: none;
}

.btn-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--right {
    justify-content: flex-end;
  }

  &--center {
    justify-content: center;
  }
}

.btn {
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 40px;
  height: 40px;
  padding: 0 25px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border: 0;
  font-family: 'Freigeist-WideRegular';
  @include transition;

  &:hover,
  &:focus {
    cursor: pointer;
    color: $default-color;
  }

  &--seller-discount {
    height: 20px;
    line-height: 0;
    padding: 2px 7px 0 7px;
  }

  &--default {
    color: #fff;
    background: $secondary-color;
    border-radius: 20px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      color: #fff;
      opacity: 0.8;
      background: $secondary-color;
    }
  }

  &--center {
    margin: 0 auto;
  }

  &.shop-button {
    color: #000;
    background: #fff;
    border-radius: 20px;
    text-transform: uppercase;
    position: relative;
    margin-top: -250px;
    font-size: 24px;
    font-weight: bold;

    &:hover,
    &:focus {
      color: #000;
      opacity: 0.8;
      background: #fff;
    }
  }

  &--bordered {
    color: $secondary-color;
    border: 1px solid $secondary-color;
    border-radius: 20px;
    background: transparent;

    &:hover,
    &:focus {
      background: $secondary-color;
      color: #fff;
    }
  }

  &--light {
    color: #fff;
    border: 1px solid #fff;
    background: transparent;

    &:hover,
    &:focus {
      background: #fff;
      color: $default-color;
    }
  }

  &--dark {
    background: #252254;
    color: #fff;
    border-radius: 20px;

    &:hover,
    &:focus {
      color: #fff;
      background: $default-lighter;
    }
  }

  &--black {
    background: #000;
    color: #fff;
    border-radius: 20px;

    &:hover,
    &:focus {
      color: #fff;
      background: $default-lighter;
    }
  }

  &--white {
    background: #fff;
    color: #000;
    border-radius: 20px;
  }

  &--close-modal {
    position: absolute;
    right: 15px;
    top: 15px;
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: $primary-color;
    border-radius: 50%;
    cursor: pointer;

    svg {
      width: 15px;
      height: 15px;
      fill: $default-lighter;
      @include transition;
    }

    &:hover,
    &:focus {
      svg {
        fill: $default-color;
      }
    }
  }

  &--login {
    @include respond-to(tablets) {
      line-height: 40px;
      height: 40px;
      padding: 0 25px;
    }
  }

  &--lg {
    @include respond-to(desktop) {
      border-radius: 20px;
      max-width: 282px;
      width: 100%;
    }

    border-radius: 20px;
    font-size: 14px;
    text-transform: uppercase;
  }

  &--sm {
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    padding: 0 5px;

    @include respond-to(tablets) {
      padding: 0 10px;
      font-size: 12px;
    }

    @include respond-to(desktop) {
      padding: 0 7px;
    }

    @include respond-to(desktop-xl) {
      padding: 0 10px;
      font-size: 12px;
    }
  }

  &--primary {
    color: #fff;
    background: rgba($primary-btn-bg-color, 0.8);
    text-transform: uppercase;
    border-radius: 20px;

    &.btn--lg {
      font-size: 26px;

      @include respond-to(desktop) {
        font-size: 32px;
      }
    }

    &:hover,
    &:focus {
      color: #fff;
      background: $primary-btn-bg-color;
    }
  }

  &-postal-code-find {
    display: flex;
    height: 47px;
    align-self: flex-end;
    align-items: center;
    margin-left: 10px;
  }
}

.btn--bordered {
  border-radius: 20px;
}

.add-to-cart-btn {
  border-radius: 20px;
}

.banner-container {
  display: flex;
}

.dropdown-toggle {
  position: relative;
  padding: 0 20px;

  &.nav-toggle:after {
    display: none;
  }
}

.dropdown {
  display: none;
  position: absolute;
  left: 0;
  top: 122%;
  background: #fff;
  overflow-y: initial;
  min-width: 220px;
  @include transition;
  z-index: 10;

  &--lang {
    min-width: 100%;
  }

  &--navigation {
    right: auto;
    left: 0;
    max-width: auto;
    top: 80px;
  }

  .btn {
    display: block;
    text-align: center;
    font-size: 16px;
  }
}

.dropdown__list {
  @include reset(ul);
}

.buyer_dropdown_item {
  height: 40px;

  &:hover,
  &:focus {
    background: rgba($default-lighter, 0.3);
    cursor: pointer;
  }

  a {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 10px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    line-height: normal;
    user-select: none;
    @include transition;
  }
}

.dropdown__item-text {
  color: white;
  font-size: 20px;
  font-weight: 100;
  padding-left: 20px;
}

.dropdown__item {

  a,
  span {
    display: block;
    padding: 0px 10px;
    height: 40px;
    width: max-content;
    min-width: 100%;
    line-height: 40px;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    user-select: none;
    @include transition;

    &:hover,
    &:focus {
      background: rgba($default-lighter, 0.3);
    }
  }
}

.dropdown--menu {
  left: 0;
  top: 0;
  right: 0;
  overflow-y: auto;
  height: 100vh;
  background: #211e4a;

  @include respond-to(tablets) {
    position: fixed;
  }

  .dropdown__item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .dropdown__item-margin {
    margin-bottom: 35px;
  }

  .dropdown__item-li {
    width: fit-content;
    background: #a9c92f;
    display: inline-block;
    margin-left: 20px;
  }

  .lang-selector {
    text-align: right !important;
    padding-right: 40px;
    font-size: 20px !important;
    color: black !important;
  }

  .dropdown__list--lang {
    padding-top: 0;
    display: contents;
  }

  .dropdown__list--lang a {
    color: #fff;
  }

  .dropdown__item-a {
    font-size: 35px;
  }

  a {
    text-transform: capitalize;
    text-align: left;
    padding: 0 20px;
    font-weight: 100;
    color: #fff;
  }

  .header__nav-item--language+.dropdown__item {
    margin-top: 30px;

    @include respond-to(phones) {
      display: none;
    }
  }

  .dropdown {
    position: static;
  }

  .dropdown--lang {
    a {
      text-transform: uppercase;
      color: #fff !important;

      @include respond-to(desktop) {
        color: $default-color;
      }
    }

    background-color: #211e4a;

    @include respond-to(desktop) {
      background-color: #fff;
    }
  }

  .dropdown-toggle.language-menu,
  .dropdown-toggle.toggle--seller-menu {
    padding-right: 40px;
    padding-left: 20px;
    font-size: 20px;
    line-height: 40px;
    justify-content: flex-end;
    color: #fff;

    &::before {
      content: none;
    }

    &::after {
      right: 20px;
    }
  }

  .dropdown-toggle.toggle--seller-menu {
    text-transform: none;
  }
}

.dropdown--profile {
  top: 36px;
  left: auto;
  right: 0;
  min-width: 200px;

  @include respond-to(tablets) {
    top: 49px;
  }

  @include respond-to(desktop) {
    top: 61px;
  }

  .dropdown__item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-to(desktop) {
      margin-bottom: 0;
    }
  }
}

.pagination-container {
  text-align: center;
  width: 100%;
}

.pagination {
  @include reset(ul);
  display: inline-block;
  list-style: none;
}

.page-item.active {
  color: #b91828;
  border-radius: 50%;
  border: 1px solid #b91828;
}

.page-nav-item {
  font-size: 18px;
  margin-left: 8px;
  margin-right: 8px;

  &.disabled {
    opacity: 0.5;
  }
}

.page-item {
  width: 32px;
  line-height: 30px;
  border-radius: 50%;
  border: 1px solid white;
  float: left;

  &.item {
    margin-top: 4px;
  }
}

.page-link {
  text-decoration: none;
  cursor: pointer;

  &--inactive {
    cursor: auto;
  }
}

.tabs {
  position: relative;
  z-index: 2;
  padding: 0;
  margin: 0 0 -1px;
  list-style: none;
  display: flex;
}

.tab-link {
  flex-grow: 1;
  height: 45px;
  padding: 0 10px;
  color: rgba($default-color, 0.6);
  font-size: 12px;
  line-height: 45px;
  text-transform: uppercase;
  border: 1px solid transparent;
  cursor: pointer;

  @include respond-to(wide-handhelds) {
    font-size: 14px;
  }

  &.current {
    border: 1px solid #ccc;
    border-bottom: 1px solid #fff;
    color: $default-color;
  }

  &.has-error {
    animation-name: error-background-flashing;
    animation-duration: 0.5s;
    animation-iteration-count: 3;
    color: $secondary-color;
  }
}

@-webkit-keyframes error-background-flashing {
  from {
    background-color: rgba($secondary-color, 0);
    border-top-color: $secondary-color;
    border-right-color: $secondary-color;
    border-left-color: $secondary-color;
  }

  to {
    background-color: rgba($secondary-color, 0.3);
    border-top-color: $secondary-color;
    border-right-color: $secondary-color;
    border-left-color: $secondary-color;
  }
}

.tab-content {
  display: none;
  border: 1px solid #ccc;
  padding: 30px 10px;

  &.current {
    display: block;
  }
}

.main-banner {
  min-height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;

  @include respond-to(wide-handhelds) {
    min-height: 420px;
  }

  @include respond-to(desktop-xl) {
    min-height: 500px;
  }

  &:before {
    @include backdrop;
  }
}

.main-banner__content {
  position: relative;
  padding: 0 15px;
  z-index: 1;
  text-align: center;
}

.main-banner__title {
  text-shadow: 5px 1px 4px black, 0 0 1em;
  font-size: 34px;
  line-height: 1.2;
  font-weight: $font-bold;

  @include respond-to(wide-handhelds) {
    font-size: 48px;
  }

  @include respond-to(desktop) {
    font-size: 72px;
  }
}

.main-banner__subtitle {
  font-size: 16px;
  line-height: 1.2;
  margin-top: 10px;

  @include respond-to(wide-handhelds) {
    font-size: 16px;
  }

  blockquote {
    max-width: 60%;
    font-size: 22px;
    margin: 0 auto 45px;
    font-style: italic;

    footer {
      padding: 15px 0;
      font-size: 16px;
    }
  }
}

.main-banner__text {
  display: block;
  padding: 15px 0;
  font-size: 16px;
}

.intro-text-section {
  font-size: 16px;
  line-height: 1.33333;
  padding: 30px 0 23px;

  @include respond-to(wide-handhelds) {
    font-size: 16px;
  }

  @include respond-to(desktop) {
    font-size: 16px;
    padding: 70px 0 10px;
  }

  .region-h2 {
    color: #b81927;
    font-size: 24px;
  }

  .region-h2__bottom {
    color: #606060;
    font-size: 24px;
    margin-top: 30px;
  }

  .p-regions {
    margin-bottom: 0;
  }

  .read-more__button {
    text-align: center;
    margin-bottom: 10px;
  }

  .title {
    font-weight: $font-bold;
    font-size: 30px;
    line-height: 1.2;
    //text-align: center;
    margin: 0 0 15px;

    @include respond-to(wide-handhelds) {
      font-size: 24px;
      margin: 0 0 30px;
    }
  }

  &--left {
    .title {
      text-align: left;
    }
  }

  p {
    margin: 0 0 7px;

    @include respond-to(tablets) {
      margin: 0 0 25px;
    }
  }

  .blockquote-box {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  blockquote {
    font-style: italic;
    line-height: 1.6;
    margin: 0 0 15px;

    @include respond-to(wide-handhelds) {
      max-width: 50%;
    }

    @include respond-to(tablets) {
      max-width: 34%;
      font-size: 22px;

      margin: 0 0 45px;
    }

    footer {
      padding: 15px 0;
      font-size: 16px;
    }
  }

  a {
    color: $secondary-color;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .input-field {
    font-size: 14px;
  }

  pre {
    font-family: 'Freigeist-WideRegular';
  }
}

.intro-description {
  font-family: 'Freigeist-WideMedium';
}

.icons-list {
  @include reset(ul);
  margin-bottom: 30px;

  li {
    display: flex;
    align-items: center;

    &+li {
      margin-top: 15px;
    }

    img {
      flex: 0 0 80px;
      max-width: 80px;
    }

    span {
      display: block;
      margin-left: 25px;
    }
  }
}

.bg-grey {
  background: $primary-color;
}

.section-banner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 20px;

  .title {
    font-size: 24px;
    line-height: 1.2;
    font-weight: $font-bold;
    margin: 0 0 10px;
    text-align: center;

    @include respond-to(wide-handhelds) {
      font-size: 34px;
      margin: 0 0 20px;
    }
  }

  p {
    margin: 0 0 20px;
  }
}

.skew-items-section {
  padding: 65px 0 70px;
}

.skew-items-list {
  @include reset(ul);
}

.estates-section.skew-items-section {
  .skew__item {
    height: 326px;
  }

  .skew__item-photo {
    height: 326px;
  }

  .estate-title-mobile {
    display: flex;
    min-height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    font-size: 32px;
    text-align: center;

    @include respond-to(wide-handhelds) {
      display: none;
    }

    .estate-link-div-mobile {
      .estate-link-mobile {
        text-decoration: none;
      }
    }
  }

  .skew__item-info {
    @include respond-to(wide-handhelds) {
      &:before {
        left: -25%;
        transform: skew(-15deg);
      }
    }
  }

  a {
    height: 266px;
    overflow: hidden;
  }
}

.skew__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;

  &+& {
    margin-top: 30px;

    @include respond-to(tablets) {
      margin-top: 74px;
    }
  }
}

.skew__item-photo-box {
  position: relative;
  flex-basis: 100%;
  background-color: #f2f4ef;

  @include respond-to(wide-handhelds) {
    flex-basis: 55%;
    background-color: transparent;
  }

  @include respond-to(tablets) {
    flex-basis: 55%;
  }
}

.skew__item-placeholder {
  position: absolute;
  width: 140px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: rgba(0, 0, 0, 0.1);

  @include respond-to(wide-handhelds) {
    transform: translate(-80%, -50%);
  }

  @include respond-to(desktop) {
    display: none;
  }
}

.skew__item-photo {
  position: relative;
  flex: 0 0 100%;
  z-index: 1;

  @include respond-to(wide-handhelds) {
    flex: 0 0 55%;
  }

  @include respond-to(tablets) {
    flex: 0 0 55%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.skew__item-info {
  position: relative;
  width: 45%;
  z-index: 2;
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  background: $primary-color;
  text-align: left;

  @include respond-to(wide-handhelds) {
    padding: 15px 8px 15px 0;
    flex: 0 0 45%;
    text-align: right;
    align-items: flex-end;
  }

  @include respond-to(tablets) {
    padding: 15px 60px 15px 0;
    flex: 0 0 45%;
  }

  @include respond-to(wide-handhelds) {
    &:before {
      content: '';
      position: absolute;
      left: -20%;
      top: 0;
      width: 100%;
      height: calc(100% + 1px);
      transform: skew(-15deg);
      background: inherit;
      z-index: -1;
    }
  }

  a {
    display: block;
    width: 100%;
    text-decoration: none;
  }

  .title {
    font-weight: $font-regular;
    line-height: 1;
    color: $secondary-color;
    margin: 0 0 15px;
    word-wrap: break-word;

    @include respond-to(wide-handhelds) {
      font-size: 28px;
    }

    @include respond-to(tablets) {
      font-size: 36px;
      margin: 0 0 30px;
    }
  }

  p {
    margin: 0 0 30px;
    word-wrap: break-word;

    @include respond-to(tablets) {
      font-size: 18px;
    }
  }
}

.page-title {
  font-size: 22px;
  color: $text-color;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 40px;
  padding-bottom: 4px;
  text-align: center;
}

.breadcrumbs {
  margin-left: -10px;
  padding: 15px 0;

  ul {
    @include reset(ul);
    display: flex;
    align-items: center;

    li {
      color: #b81928;
      font-size: 14px;

      &.active {
        color: #b81928;
      }

      &+li {
        position: relative;

        &:before {
          content: '|';
          display: inline-block;
          vertical-align: middle;
          color: #b81928;
        }
      }

      a,
      span {
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px;
        text-transform: uppercase;
        text-decoration: none;
        color: #b81928;
      }
    }
  }
}

.slick-arrow {
  position: absolute;
  top: 50%;
  width: 45px;
  height: 45px;
  transform: translate3d(0, -50%, 0);
  font-size: 0;
  background-color: transparent;
  border: 0;
  opacity: 0.5;
  z-index: 10;
  @include transition;

  @include respond-to(wide-handhelds) {
    width: 60px;
    height: 60px;
  }

  svg {
    fill: $default-color;
    width: 100%;
    height: 100%;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    outline: none;
    opacity: 1;
  }

  &.prev-arrow {
    left: 0;

    @include respond-to(wide-handhelds) {
      left: 15px;
    }
  }

  &.next-arrow {
    right: 0;

    @include respond-to(wide-handhelds) {
      right: 15px;
    }
  }
}

.page-overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 57, 57, 0.5);
  display: none;
}

.fancybox-content {
  .btn+.btn {
    margin-left: 15px;
  }

  .btn--dark {
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    text-transform: uppercase;
  }
}

.content-section {
  a:not(.btn) {

    &:hover,
    &:focus {
      color: $secondary-color;
    }
  }
}

.info-modal {
  text-align: center;
  font-size: 16px;
  display: block;
  max-width: 1550px;

  &.fancybox-content {
    padding: 15px 30px 30px;

    @include respond-to(phones) {
      padding: 15px 22px;
    }
  }
}

.delivery-popup {
  width: 550px;

  @include respond-to(phones) {
    width: 308px;
  }
}

.error-modal {
  position: fixed;
  right: 0;
  top: 0;
  transform: translate3d(0, -30px, 0);
  max-width: 350px;
  width: 100%;
  padding: 15px;
  background: rgba($secondary-color, 0.9);
  color: #fff;
  border-bottom-left-radius: 4px;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.4s ease;

  &.show {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

.error-content {
  display: flex;
  align-items: flex-start;

  svg {
    width: 36px;
    height: 36px;
    fill: #fff;
  }

  p {
    margin: 0 0 7px;
  }
}

.error__text {
  padding: 0 15px;

  h2 {
    line-height: 1;
    margin-bottom: 7px;
  }
}

.description {
  white-space: pre-line;
}

.description-blog {
  white-space: unset;
  margin: 20px;
  font-family: 'Freigeist-WideRegular';
}

.g-recaptcha {
  margin: 10px 37px;
}

.special-list-item {
  color: $secondary-color !important;
  text-decoration: none !important;
}

.guestSpecials {
  color: $secondary-color !important;
}

.seller-discount-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

.seller-message-stub {
  padding-top: 60px;
}

.special-filter {
  font-size: 14px !important;
  font-weight: 700 !important;
}

.pointer {
  cursor: pointer;
}

.delivery-flag {
  height: 32px;
  width: 32px;
}

.wine-filter {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.top-filter {
  margin-bottom: 5px;
}

.delivery-country-label {
  width: 150px;
  display: flex;
  align-items: center;
  height: 58px;
  cursor: pointer;
  padding-left: 10px;

  @include respond-to(phones) {
    padding-left: 0px;
  }
}

.delivery-info {
  margin-top: 20px;
  max-width: 408px;
}

.sing-up-link:focus {
  outline: none;
  box-shadow: none;
}

.fancybox-content {
  font-family: 'Freigeist-WideRegular';
}

.inline-cont {
  display: inline-flex;
}