.footer {
  padding-top: 50px;
  padding-bottom: 25px;
  color: #211e4a;
  background: #fffdfa;
  font-family: 'Freigeist-WideRegular';
}

.bottom-wrapper {
  display: inline-block;
}

.bottom-wrapper a {
  text-decoration: none;
}

.bottom-wrapper--about {
  padding-top: 18px;
}

.bottom-wrapper--lang {
  margin-left: 135px;

  @include respond-to(phones) {
    margin-left: 25vw;
  }
}

.bottom-wrapper--locations {
  padding-bottom: 20px;
}

.bottom-wrapper--lang ul {
  list-style-type: none;
}

.bottom-wrapper--left {
  display: flex;
  text-transform: capitalize;
}

.bottom-wrapper--left ul {
  list-style-type: none;
  line-height: 1.6;
}

.bottom-wrapper--right {
  float: right;
  margin-right: 7vw;
  margin-bottom: 10px;
  @include respond-to(phones) {
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }

  a {
    text-decoration: none;
  }
}

.credit-cards {
  margin-top: 20px;
}

.credit-cards ul {
  list-style-type: none;
  display: flex;
  padding-left: 0;
  @include respond-to(phones) {
    padding-left: 9px;
    justify-content: space-evenly;
  }
}

.credit-cards li {
  padding-right: 10px;

  &:last-child {
    padding: 0;
  }
}

.terms {
  margin-top: 20px;

  ul {
    line-height: 1.6;
  }
}

.terms li {
  padding-right: 10px;

  &:last-child {
    padding: 0;
  }
}

.terms-list {
  display: flex;
  margin: 0 auto;
  @include respond-to(phones) {
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }
}

.terms ul {
  list-style-type: none;
  padding-left: 0;
}

.footer-logo {
  text-align: center;
  margin-bottom: 150px;

  img {
    height: 27px;
  }
}

.footer-navigation {
  text-align: left;
  @include respond-to(wide-handhelds) {
    text-align: center;
    margin-bottom: 25px;
  }

  ul {
    font-size: 0;
    @include reset(ul);

    @include respond-to(desktop) {
      display: flex;
      justify-content: center;
    }

    & > li {
      text-align: center;
      margin-bottom: 20px;

      @include respond-to(desktop) {
        margin-bottom: 0;
      }

      & > a {
        padding: 0 20px;
        text-transform: uppercase;
        color: #4d4d4d;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.05em;
        text-decoration: none;
      }
    }
  }
}
.btn-holder--footer a {
  display: block;
  padding: 0;
  text-transform: uppercase;
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-decoration: none;

  @include respond-to(wide-handhelds) {
    padding: 0 20px;
  }
}
.footer-bottom {
  padding-top: 25px;
  h6 {
    color: #494949;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
  p {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 300;
  }
}
.message-bottom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba($default-color, 0.8);
  color: #fff;
  z-index: 100;

  .btn {
    background: #333;
    padding: 0 15px;
  }
}
