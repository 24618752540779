.header-searchbox {
  position: fixed;
  left: 0;
  top: 46px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  background: white;
  border-bottom: 1px solid #c8c8c8;
  z-index: 100;
  display: none;

  @include respond-to(tablets) {
    top: 71px;
  }

  .main-search__form {
    width: 90%;

    @include respond-to(tablets) {
      width: 60%;
      margin: 0 auto;
    }

    .input-field {
      margin: 0;
    }
  }

  .icon-close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;

    svg {
      fill: $secondary-color;
    }
  }
}
