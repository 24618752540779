.categories-content {
  padding: 30px 0;

  .main-category-list {
    padding-top: 0;
  }
}

.category__title {
  font-size: 35px;
  letter-spacing: 5px;
  font-weight: $font-light;
  text-align: center;
}

.category__subtitle {
  font-size: 18px;
  font-weight: $font-light;
  color: $default-light;
  text-align: center;
}

.categories-list-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 30px 0 0;

  @include respond-to(handhelds) {
    flex-direction: row;
    padding: 60px 0 0;
  }

  .btn {
    text-transform: uppercase;
    height: 40px;
    line-height: 40px;
  }
}

.category-select {
  @include reset(ul);
  display: flex;
  margin: 0 0 15px;

  @include respond-to(handhelds) {
    margin: 0;
  }

  @include respond-to(tablets) {
    display: none;
  }
}

.category-select__item {
  position: relative;

  &:first-of-type {
    margin-right: 15px;
  }

  .dropdown-toggle {
    border: 1px solid $default-light;
    padding: 0 30px 0 15px;
    border-radius: 5px;
    min-width: 150px;

    &:after {
      right: 10px;
    }
  }

  a,
  span {
    display: block;
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    text-decoration: none;
  }

  .dropdown {
    left: -30%;

    @include respond-to(handhelds) {
      left: 0;
    }

    .dropdown__item {
      a {
        padding: 0 10px;
      }
    }
  }
}

.categories__list {
  display: none;

  @include respond-to(tablets) {
    @include reset(ul);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 0 0 -15px;
  }
}

.category__item {
  &+& {
    &:before {
      content: '|';
      color: $default-light;
    }
  }

  a,
  span {
    display: inline-block;
    padding: 0 15px;
    font-size: 14px;
    line-height: 2;
    text-decoration: none;
    text-transform: uppercase;
    color: $default-light;
    border-bottom: 5px solid transparent;
    user-select: none;
  }

  a {

    &:hover,
    &:focus {
      border-bottom-color: $secondary-color;
    }
  }

  &.active {
    span {
      border-bottom-color: $secondary-color;
    }
  }
}

.categories-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0 0;
}

.categories-filters {
  flex: 0 0 100%;

  @include respond-to(tablets) {
    flex: 0 0 40%;
    padding: 0 30px 10px 0;
    border-right: 1px solid $default-light;
  }

  .btn-holder {
    flex-direction: column;

    @include respond-to(handhelds) {
      flex-direction: row;
    }
  }

  .btn {
    height: 40px;
    line-height: 40px;
    text-transform: uppercase;
    border-radius: 20px;
    min-width: 150px;

    &+.btn {
      margin: 15px 0 0;

      @include respond-to(handhelds) {
        margin: 0 0 0 15px;
      }
    }
  }
}

.category-sort {
  border-radius: 20px;
  font-family: 'Freigeist-WideRegular';

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.categories-filters__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  h4 {
    font-size: 22px;
    text-transform: uppercase;
  }
}

.categories-filters__preview {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.delivery-countries-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
  width: 100%;


  // @include respond-to(phones) {
  //   margin-left: 0px;
  //   width: 300px;
}

.delivery-countries-header {
  width: 100%;
  margin-bottom: 0px;
}

.delivery-countries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.launching-soon-countries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-content: center;
}

.country-with-flag {
  height: 36px;
  cursor: pointer;
  margin-right: 16px;

  @include respond-to(phones) {
    margin-right: 8px;
  }
}

.delivery-popup-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.country-flag {
  display: flex;
  align-items: center;

  .flag-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .country-name {
    text-align: start;
  }
}

#prefinery_popup {
  top: 25% !important;
}

.filter-tag {
  position: relative;
  margin: 0 7px 7px 0;
  font-size: 12px;
  border: 1px solid $secondary-color;
  border-radius: 25px;
  padding: 5px 30px 5px 10px;
  text-transform: uppercase;

  .icon-remove {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-image: url('../assets/images/mobile_close.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
  }
}

.categories-filters__item {
  margin-bottom: 15px;

  @include respond-to(tablets) {
    margin-bottom: 20px;
  }

  .label {
    display: block;
    text-transform: uppercase;
  }

  .irs--flat {
    max-width: 85%;
    margin: 0 auto;

    @include respond-to(handhelds) {
      max-width: 100%;
    }

    .irs-line {
      height: 3px;
    }

    .irs-max,
    .irs-min {
      background-color: transparent;
      font-size: 12px;
      color: $default-color;
    }

    .irs-bar {
      height: 3px;
      background-color: $secondary-color;
    }

    .irs-handle {
      &>i:first-child {
        background-color: $secondary-color;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        top: -5px;
        cursor: pointer;
      }
    }

    .irs-from,
    .irs-to {
      margin: -3px 0 0 8px;
      background-color: $secondary-color;

      &:before {
        border-top-color: $secondary-color;
      }
    }
  }

  &:first-of-type {
    .categories-filters__label {
      &:after {
        display: none;
      }
    }
  }
}

.categories-filters__inner {
  display: flex;
  flex-wrap: wrap;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.2s ease, opacity 0.4s ease;

  &.active {
    opacity: 1;
    max-height: 100%;
  }

  .checkbox {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 3px;
    padding-right: 5px;

    @include respond-to(handhelds) {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
}

.categories-filters__label,
.group-filter {
  display: inline-block;
  margin: 10px 0 5px;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  user-select: none;

  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin: -3px 0 0 10px;
    background-image: url('../assets/images/filter-plus.svg');
    background-position: 100% 50%;
    background-repeat: no-repeat;
    @include transition;
  }

  &.opened {
    &:after {
      background-image: url('../assets/images/filter-minus.svg');
    }
  }
}

.primary-filter {
  text-transform: uppercase;
  font-weight: 700;
}

.wines-list {
  max-width: 100vw;
}

.categories-items-list {
  flex: 0 0 100%;
  padding: 30px 0 0;

  @include respond-to(tablets) {
    flex: 0 0 60%;
    padding: 0 0 0 30px;
  }

  .col {
    margin: 0 0 30px;
  }

  .col-xl-2 {
    @include respond-to(desktop) {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  .product__item {
    border: 1px solid $default-lighter;
    padding: 10px;

    @include respond-to(tablets) {
      border: 0;
      padding: 0;
      margin: 0;
    }
  }

  .product__item-image {
    @include respond-to(tablets) {
      border: 1px solid $default-lighter;
      height: 190px;
    }
  }
}

.filter-applied__title {
  font-size: 22px;
}

.filter-heading {
  padding-top: 24px;
  padding-bottom: 30px;
}

.rating-filter {
  display: flex;
  flex-direction: column;
}