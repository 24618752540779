.main {
  background: #fffdfa;
  font-family: 'Freigeist-WideRegular';
}

.main-slider-container {
  position: relative;

  .slick-arrow {
    svg {
      fill: #fff;
    }
  }
}

.main-slider {
  height: 400px;
  overflow: hidden;

  @include respond-to(wide-handhelds) {
    height: 100vh;
  }

  .slick-list,
  .slick-track {
    height: 100%;
  }
}

.main-slider__item {
  position: relative;

  &:before {
    @include backdrop;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:nth-child(2),
  &:nth-child(8) {
    .main-slider__title {
      letter-spacing: 0;
      font-size: 40px;

      @include respond-to(handhelds) {
        font-size: 80px;
        letter-spacing: 20px;
      }
    }
  }
}

.main-slider__content {
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate3d(-50%, 0, 0);
  width: 100%;
  padding: 0 50px;
  text-align: center;
  color: #fff;

  @include respond-to(wide-handhelds) {
    width: 90%;
    padding: 0;
  }

  @include respond-to(desktop) {
    top: 40%;
    width: 75%;
  }
}

.main-slider__title {
  font-weight: $font-bold;
  margin-bottom: 20px;
  font-size: 25px;
  line-height: 1;
  letter-spacing: normal;

  @include respond-to(handhelds) {
    font-size: 40px;
    letter-spacing: 0.05em;
  }

  @include respond-to(tablets) {
    font-size: 60px;
  }

  @include respond-to(desktop) {
    line-height: 1.285714286;
  }
}

.main-slider__subtitle {
  font-size: 22px;
}

.main-search-box {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate3d(-50%, 0, 0);
  max-width: 80%;
  width: 100%;

  @include respond-to(wide-handhelds) {
    max-width: 50%;
    top: 60%;
  }

  @include respond-to(desktop) {
    max-width: 30%;
  }

  ul {
    display: none;
    list-style: none;
    padding: 0;
    text-transform: capitalize;
    position: absolute;
    top: 50px;
    right: 0;
    color: #000;
    z-index: 3000;
    max-height: 402px;
    min-width: 250px;
    width: 100%;
    overflow: auto;
    box-shadow: 4px 8px 15px 0px rgba(0, 1, 1, 0.13);

    li {
      padding: 15px 10px;
      width: 100%;
      line-height: 30px;
      font-size: 15px;
      border-bottom: 1px solid #e3e3e3;
      background-color: #fcfcfc;

      &:hover {
        cursor: pointer;
        background: #fff;
        color: #000;
      }

      a {
        height: auto;
        width: 100%;
        display: block;
        text-decoration: none;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.main-search__form {
  position: relative;

  .input-field {
    input {
      padding-right: 60px;
      font-size: 14px;
      border-radius: 100px;
      font-weight: $font-light;

      &:focus {
        outline: none;
      }

      @include placeholder {
        font-weight: $font-light;
        font-size: 14px;
      }

      @include respond-to(wide-handhelds) {
        height: 60px;
        line-height: 60px;
        padding-right: 80px;
      }
    }
  }
}

.search-result-header {
  padding-left: 225px;

  li {
    list-style: none;
    text-decoration: none;
    text-transform: capitalize;
    padding: 7px 5px;

    &:hover {
      cursor: pointer;
    }

    a {
      text-decoration: none;
      border-bottom: 1px solid;
    }
  }
}

.main-search-icon {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 100%;
  align-items: center;
  background: transparent;
  border: 0;
  padding: 0 20px;
}

.content-section {
  padding: 15px 0;

  @include respond-to(tablets) {
    padding: 30px 0;
  }

  @include respond-to(desktop) {
    padding: 50px 0 60px;
  }
}

.info-block,
.main-regions-section.skew-items-section.info-block,
.content-section.info-block {
  padding: 0 0 25px 0;
}

.content-section.info-block--home {
  padding-top: 0;
  padding-bottom: 15px;
}

.content-section.info-block--home {
  @include respond-to(tablets) {
    padding: 180px 0 80px;
  }
}

.top-padding,
.content-section.info-block.top-padding {
  padding-top: 25px;
}

.content-section.info-block--home.top-padding {
  padding-top: 46px;
  padding-bottom: 15px;

  @include respond-to(tablets) {
    padding-top: 71px;
  }
}

.content-section.info-block.top-padding.last-info-block {
  padding-bottom: 0;
}

.content__title {
  text-align: center;
  font-size: 32px;
  font-weight: $font-bold;

  @include respond-to(wide-handhelds) {
    margin: 0 0 30px;
    font-size: 34px;
  }
}

.content__subtitle {
  text-align: center;
  font-size: 22px;
  font-weight: $font-bold;
  margin: 0 0 30px;

  @include respond-to(wide-handhelds) {
    font-size: 26px;
  }
}

.text-content {
  font-size: 24px;
}

.banner-content-section {
  padding: 90px 0 70px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;

  &:before {
    @include backdrop;
  }
}

.banner-blog-main-page {
  display: flex;
  height: 468px;
  margin-bottom: 25px;
  padding: 0;
}

.content-section.info-block .banner-main-page__block-link,
.banner-blog-main-page .banner-main-page__block-link {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: none;

  h2,
  h5 {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }

  h2 {
    margin-bottom: 5px;
  }

  h3 {
    color: #000;
  }

  &:hover {
    h3 {
      color: #000;
    }
  }
}

div.banner-content-section {
  padding: 90px 0 70px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 65%;
  position: relative;
  height: 400px;

  @include respond-to(wide-handhelds) {
    height: 600px;
  }

  &:before {
    @include backdrop;
  }
}

.banner-content__inner {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;

  @include respond-to(wide-handhelds) {
    max-width: 70%;
  }

  p {
    margin: 0 0 30px;
    font-size: 22px;
  }
}

.banner-content__title {
  font-size: 34px;
  font-weight: $font-bold;
  margin: 0 0 50px;
}

.banner-content__subtitle {
  font-size: 24px;
  font-weight: $font-bold;
  margin: 0;
}

.main-category-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px;
  padding: 50px 0 0;
}

.main-category__item {
  flex: 0 0 100%;
  padding: 0 7px;
  margin-bottom: 14px;
  position: relative;

  @include respond-to(wide-handhelds) {
    flex: 0 0 50%;
  }

  @include respond-to(tablets) {
    flex: 0 0 25%;
  }

  .flag {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: 35%;
  }

  .checkbox {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    label {
      min-height: 100%;
      padding: 0;

      &:before {
        top: 10px;
        left: auto;
        right: 10px;
        transform: none;
        border-color: transparent;
      }
    }

    [type='checkbox'] {
      &:checked {
        &+label {
          border: 1px solid $secondary-color;

          &:after {
            top: 13px;
            left: auto;
            right: 15px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}

.main-category__item-inner {
  position: relative;
  display: flex;
  min-height: 165px;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #fff;
  text-decoration: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  &:before {
    @include backdrop;
  }

  &:hover,
  &:focus {
    color: #fff !important;
  }

  @include respond-to(handhelds) {
    min-height: 300px;
  }

  @include respond-to(wide-handhelds) {
    min-height: 220px;
  }

  @include respond-to(tablets) {
    min-height: 150px;
  }
}

.main-category__name {
  position: relative;
}

.content-items-list {
  @include reset(ul);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 25px;
}

.content__item {
  flex: 0 0 100%;

  @include respond-to(wide-handhelds) {
    flex: 0 0 33.3333333%;
  }

  &+& {
    margin-top: 10px;

    @include respond-to(wide-handhelds) {
      margin-top: 0;
    }
  }

  p {
    color: rgba($default-color, 0.5);
    font-size: 16px;
  }
}

.content__item-inner {
  display: block;
  max-width: 80%;
  margin: 0 auto;
  text-align: center;
  text-decoration: none;
}

.content__item-image {
  width: 135px;
  height: 135px;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto 25px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content__item-title {
  min-height: 0;

  @include respond-to(wide-handhelds) {
    font-size: 20px;
    min-height: 50px;
  }

  @include respond-to(tablets) {
    min-height: 60px;
    font-size: 24px;
  }
}

.main-products-section {
  padding: 25px 0;
}

.main-title {
  font-size: 46px;
  font-weight: $font-bold;
  text-align: center;
  margin: 0 0 25px;
}

.main-subtitle {
  font-size: 22px;
  text-align: center;
  color: rgba($default-color, 0.5);
  margin: 0 0 25px;
}

.product__item {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  text-decoration: none;
}

.product__item-image {
  overflow: hidden;
  background: #fff;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.product__item-details {
  padding: 10px 0;
  margin-top: auto;
}

.product__item-price {
  display: block;
  font-size: 16px;
  color: $default-lighter;
  text-transform: uppercase;
  margin-bottom: 5px;
  font-family: initial;
}

span.product__item-name {
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block;
}

span.product__estate-name {
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block;
}

.main-regions-section {
  padding: 50px 0;

  .skew-items-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    @include respond-to(wide-handhelds) {
      flex-direction: row;
      align-items: stretch;
    }
  }

  .skew__item {
    padding: 0 15px;
    margin: 0 0 30px;

    @include respond-to(wide-handhelds) {
      flex: 0 0 50%;
    }

    @include respond-to(desktop) {
      flex: 0 0 25%;
    }

    &+.skew__item {
      margin-top: 0;
    }
  }

  .skew__item-photo {
    max-height: 250px;

    @include respond-to(tablets) {
      flex: 0 0 55%;
    }

    @include respond-to(desktop) {
      max-height: 217px;
      flex: 0 0 55%;
    }

    @include respond-to(desktop-xl) {
      flex: 0 0 55%;
    }
  }

  .skew__item-info {
    padding: 15px;
    align-items: center;

    @include respond-to(wide-handhelds) {
      padding: 15px 15px 15px 0;
      align-items: flex-end;
    }

    @include respond-to(tablets) {
      flex: 0 0 45%;
    }

    @include respond-to(desktop) {
      flex: 0 0 45%;
    }

    @include respond-to(desktop-xl) {
      flex: 0 0 45%;
    }

    &:before {
      @include respond-to(wide-handhelds) {
        width: 75%;
        left: -37%;
      }

      @include respond-to(desktop) {
        left: -27.5%;
        width: 55%;
      }

      @include respond-to(desktop-xl) {
        width: 75%;
        left: -37%;
      }
    }

    .title {
      @include respond-to(wide-handhelds) {
        font-size: 22px;
      }

      @include respond-to(tablets) {
        font-size: 28px;
        margin: 0 0 15px;
      }
    }

    .btn--sm {
      @include respond-to(wide-handhelds) {
        font-size: 11px;
      }

      @include respond-to(tablets) {
        font-size: 13px;
      }

      @include respond-to(desktop) {
        font-size: 11px;
      }

      @include respond-to(desktop-xl) {
        font-size: 12px;
      }
    }
  }

  img.flag {
    max-width: 100px;

    @include respond-to(wide-handhelds) {
      max-width: 60%;
    }
  }
}

.regions-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -15px;
}

.region__item {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 0 15px;

  @include respond-to(wide-handhelds) {
    flex: 0 0 50%;
  }

  @include respond-to(tablets) {
    flex: 0 0 25%;
  }
}

.region__inner {
  position: relative;
  display: flex;
  min-height: 165px;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: #fff;
  text-decoration: none;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;

  &:before {
    @include backdrop;
  }

  @include respond-to(handhelds) {
    min-height: 300px;
  }

  @include respond-to(wide-handhelds) {
    min-height: 220px;
  }

  @include respond-to(tablets) {
    min-height: 150px;
    font-size: 36px;
  }
}

.region__title {
  position: relative;
}

.regions__list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  @include reset(ul);
  border: 1px solid $border-color;
  border-top: 0;
  background: #fff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;

  li {
    flex-grow: 1;
    display: flex;

    a {
      flex-grow: 1;
      display: flex;
      align-items: center;
      position: relative;
      padding: 10px 30px 10px 10px;
      text-decoration: none;
      font-size: 15px;
      @include transition;

      &:hover,
      &:focus {
        color: $secondary-color;

        .icon-arrow {
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, -50%, 0);
        }
      }

      .icon-arrow {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate3d(-5px, -50%, 0);
        opacity: 0;
        visibility: hidden;
        @include transition;
      }
    }

    &+li {
      a {
        border-top: 1px solid $border-color;
      }
    }
  }
}

.blog-container {
  padding: 30px 2.5%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.blog-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include respond-to(tablets) {
    align-items: flex-start;
  }
}

.blog-content__item {
  background: #fff;
  border-radius: 6px;
  padding: 15px 10px 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  @include respond-to(desktop) {
    &:nth-of-type(2n) {
      margin-top: 10%;
    }
  }

  @include respond-to(handhelds) {
    max-width: 47.5%;
  }

  @include respond-to(tablets) {
    max-width: 23.5%;
  }

  @include respond-to(desktop-xl) {
    max-width: 320px;
  }
}

.blog__item-header {
  margin-bottom: 10px;

  p {
    margin: 0;
  }
}

.blog-tags-list {
  @include reset(ul);
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  li {
    margin-right: 5px;
  }

  a {
    color: #0077cc;
  }
}

.blog__item-image {
  max-width: 100%;
}

.footer-shop-name-social {
  display: flex;
  justify-content: center;
  align-items: center;

  h6 {
    margin-right: 10px;
  }
}

.info-mail {
  text-decoration: none;
}

.footer-social-link {
  display: inline-block;
  width: 20px;
  height: 20px;
  opacity: 0.7;
}

.footer-social-link--facebook {
  margin-right: 10px;
  background: transparent url('../assets/images/facebook.svg') 50% no-repeat;
  background-size: contain;
}

.footer-social-link--instagram {
  background: transparent url('../assets/images/instagram.svg') 50% no-repeat;
  background-size: contain;
}

.block-two-images {
  position: relative;
  padding: 0 15px;

  .link--two-images {
    position: relative;
    display: block;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    @include respond-to(tablets) {
      left: 50%;
      width: 50%;
      max-width: 950px;
      margin-bottom: 0;
    }
  }

  @include respond-to(tablets) {
    .link--two-images-shop {
      transform: translateX(-90%);
    }

    .link--two-images-visit {
      transform: translate(-10%, -33%);
    }
  }

  .img-two-images-img {
    vertical-align: top;
  }

  .label-shop {
    top: 10%;
    left: 10%;
    color: white;
    font-size: 42px;
    position: absolute;
  }

  .label-visit {
    top: 10%;
    left: 10%;
    color: white;
    font-size: 42px;
    position: absolute;

    @include respond-to(tablets) {
      left: auto;
      right: 10%;
    }
  }
}

.main-block {
  position: relative;
  background-size: cover;

  @include respond-to(desktop) {
    display: flex;
    align-items: flex-end;
    min-height: 98vh;
    background-image: url(/assets/images/main-bg.jpeg);
  }

  .main-text-sub {
    display: flex;
    padding: 24px 15px;
    flex-direction: column;
    justify-content: center;
    background-image: url(/assets/images/main-sub-block.jpeg);
    background-size: cover;

    @include respond-to(tablets) {
      padding: 70px 80px;
    }

    @include respond-to(desktop) {
      min-width: 638px;
      min-height: 336px;
      padding: 24px;
      margin-left: 7%;
      margin-bottom: 5%;
    }

    .main-text-inner {
      margin-top: 0;
      margin-bottom: 24px;
      color: white;
      white-space: pre-line;
      font-size: 22px;

      @include respond-to(desktop) {
        font-size: 20px;
        text-align: center;
      }
    }

    .main-text-inner:last-child {
      margin-bottom: 0;
    }
  }
}

.video {
  width: 100%;

  @include respond-to(phones) {
    display: none;
  }

  @include respond-to(handhelds) {
    width: 90%;
  }

  @include respond-to(wide-handhelds) {
    width: 90%;
  }

  @include respond-to(tablets) {
    width: 90%;
  }
}

.video-0 {
  width: 100%;
}

.video-1 {
  width: 100%;
  border-radius: 15px;
}

.shop-button {
  height: 34px;
  background: #e8bf1d !important;
  margin-top: 86px;
}

.shop-button-text {
  line-height: 34px !important;
  color: black;
}

.shop-button-link {
  padding: 0 40px !important;
}

.label::before {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 1.5em;
}

.images-section {
  margin-bottom: 30px;

  @include respond-to(phones) {
    margin-top: 2rem;
  }
}

.accordion {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #211e4a;
  max-width: 90%;
  margin: 0 auto;
  padding: 50px;
  color: white;
  border-radius: 10px;
  margin-block-end: 34px;

  h1 {
    text-align: center;
    font-weight: 100;
    padding-bottom: 7rem;
  }
}

.acc-item {
  margin-bottom: 20px;

  h2 {
    font-size: 21px;
  }
}

.acc-title {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 100;
}

.acc-title::after {
  content: '+';
  color: #e8bf1d;
  font-size: 40px;
  margin-left: auto;
}

.active-header::after {
  transform: rotate(45deg);
  transition: ease 500ms;
}

.acc-answer {
  max-height: 0;
  overflow: hidden;
  transition: 500ms ease max-height;

  @include respond-to(phones) {
    overflow: scroll;
  }
}

.acc-answer p {
  margin-bottom: 10px;
}

.acc-answer.active-header {
  max-height: 40rem;
  transition: 500ms ease;
}

.top__section {
  background: #fffdfa;

  @include respond-to(desktop) {
    margin-top: -82px !important;
  }

  @include respond-to(desktop-xl) {
    margin-top: -82px !important;
  }

  @include respond-to(tablets) {
    width: 90%;
    margin: 0 auto;
  }

  @include respond-to(wide-handhelds) {
    width: 90%;
    margin: 0 auto;
    margin-top: -82px !important;
  }

  @include respond-to(handhelds) {
    width: 90%;
    margin: 0 auto;
    margin-top: 0;
  }

  @include respond-to(phones) {
    margin-top: 0;
    background-image: url('../assets/images/hand.png');
    background-size: cover;
    height: 110vh;
    color: white;
  }
}

.top__section h1 {
  color: #211e4a;
  padding-top: 14px;
  font-size: 21px;
  margin-top: 45px;

  @include respond-to(phones) {
    margin-top: 0;
  }
}

.top__section h2 {
  color: #211e4a;
  padding-top: 14px;
  font-size: 21px;

  @include respond-to(phones) {
    margin-top: 0;
  }
}

.top__section-bottom {
  margin-top: 200px;
}

.top__section-title {
  text-align: center;
  padding-top: 50px;

  .top__section-title1 {
    @include respond-to(phones) {
      color: white;
      text-align: left;
      padding-left: 20px;
      padding-top: 52vh;
    }
  }

  .top__section-title2 {
    display: none;

    @include respond-to(phones) {
      display: block;
      color: white;
      text-align: left;
      padding-left: 20px;
    }
  }
}

.top__section-ul {
  color: #211e4a;
  display: flex;
  flex-direction: row;
  list-style-type: none;
  justify-content: space-between;
  padding-left: 0;
}

.top__section-li---img {
  margin-top: 17px;
}

.top__section-li--text {
  font-size: 25px;
  font-weight: 100;
  margin: 0;
}

.top__section-li--text-sm {
  margin-bottom: 0;
  margin-top: 86px;
  font-weight: 400;
}

.pictures {
  display: flex;

  @include respond-to(desktop-xl) {
    width: 100% !important;
    margin-top: 90px;
    color: #211e4a;
    flex-direction: row !important;
  }

  @include respond-to(desktop) {
    width: 100% !important;
    margin-top: 90px !important;
    color: #211e4a;
    flex-direction: row !important;
  }

  @include respond-to(wide-handhelds) {
    width: 90%;
    margin: 0px auto;
    flex-direction: row !important;
  }

  @include respond-to(handhelds) {
    width: 90%;
    margin: 0px auto;
    flex-direction: column;
  }

  @include respond-to(phones) {
    flex-direction: column;
  }

  img {
    object-fit: cover;

    @include respond-to(desktop-xl) {
      width: 100%;
      object-fit: cover;
    }
  }
}

.content {
  display: flex;

  @include respond-to(phones) {
    padding-bottom: 10px;
  }
}

.content {
  justify-content: space-between;
  position: absolute;
  top: 50%;
  right: 50%;
  translate: 50%;
  z-index: 10;
  width: 100%;
  text-align: center;
  color: white;

}

.content-right {
  justify-content: space-between;
  position: absolute;
  top: 50%;
  right: 50%;
  translate: 50%;
  z-index: 10;
  width: 100%;
  text-align: center;
  color: white;

}

.content-img--left {
  position: relative;
}

.content-img--right {
  position: relative;
}

.content-img {
  height: 825px;
  border-radius: 10px;
  max-height: 826px;
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;

  @include respond-to(phones) {
    height: 300px;
    width: 100%;
  }

  @include respond-to(handhelds) {
    height: 400px;
    width: 100%;
  }

  @include respond-to(desktop) {
    height: 825px;
    width: 100%;
  }

  @include respond-to(desktop-xl) {
    height: 825px;
    width: 100%;
  }
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgba(232, 196, 29, 0.7);
  border-radius: 10px;
}

.overlay-right {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background: rgba(116, 4, 43, 0.7);
  background-blend-mode: multiply;
  border-radius: 10px;
}

.content-img--left:hover .content-img {
  opacity: 0.8;
  box-shadow: 23px 12px 52px black;
}

.content-img--left:hover .overlay {
  opacity: 1;
}

.content-img--right:hover .content-img {
  opacity: 0.8;
  box-shadow: 23px 12px 52px black;
}

.content-img--right:hover .overlay-right {
  opacity: 1;
}

.left-img {
  margin-right: 15px;

  @include respond-to(phones) {
    width: 90%;
    margin: 0 auto;
    padding-bottom: 2rem;
  }

  @include respond-to(handhelds) {
    width: 100%;
  }

  @include respond-to(wide-handhelds) {
    width: 50% !important;
  }

  @include respond-to(desktop) {
    width: 50% !important;
  }

  @include respond-to(desktop-xl) {
    width: 50% !important;
  }
}

.left-img {
  position: relative;
}

.img--title {
  font-size: 50px;
  font-weight: 900;

  @media screen and (max-width: 1110px) and (min-width: 768px) {
    display: flex;
    align-items: center;
    font-size: unset;
  }

  @include respond-to(phones) {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
    padding-bottom: 2rem;
  }

  a {
    text-decoration: none;
  }
}

.header__logo {

  @include respond-to(desktop) {
    padding-top: 100px;
    transition-delay: 200ms;
    transition-property: padding-top;
  }
}

.header__logo img {

  @include respond-to(desktop) {
    height: 100px;
    transition-delay: 200ms;
    transition-property: height;
  }
}

.header {
  @include respond-to(desktop) {
    margin-top: -50px;
    transition-delay: 200ms;
    transition-property: margin-top;
  }
}

.img-title-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-shadow: 2px 2px 4px #000000;
}

.left-img--text {
  margin: auto;
  font-size: 25px;
  font-weight: 600;
  padding-block-start: 12px;

  a {
    text-decoration: none;
  }

  @media screen and (max-width: 1110px) and (min-width: 768px) {
    max-width: 25vw;
  }

  @media screen and (max-width: 767px) and (min-width: 576px) {
    max-width: 50vw;
  }

  @media screen and (max-width: 1410px) and (min-width: 1111px) {
    max-width: 30vw;
  }

  @include respond-to(phones) {
    display: none;
  }

  @include respond-to(handhelds) {
    font-size: 12px;
  }

  @include respond-to(desktop) {
    display: initial !important;
    margin: auto;
    font-size: 15px;
  }

  @include respond-to(desktop-xl) {
    display: initial !important;
    font-size: 16px;
  }
}

.right-img--text {
  margin: auto;
  font-size: 25px;
  font-weight: 600;
  padding-block-start: 12px;

  a {
    text-decoration: none;
  }

  @include respond-to(phones) {
    display: none;
  }

  @media screen and (max-width: 1110px) and (min-width: 768px) {
    max-width: 16vw;
  }

  @include respond-to(handhelds) {
    font-size: 12px;
  }

  @include respond-to(desktop) {
    display: initial !important;
    font-size: 15px;
  }

  @include respond-to(desktop-xl) {
    display: initial !important;
    font-size: 16px;
  }
}

.content-icon {
  height: 106px;
  width: 99px;
  background-repeat: no-repeat;
  text-decoration: none;

  @include respond-to(phones) {
    margin-left: auto;
  }
}

.content-icon--left {
  background-image: url('/assets/images/green-sticker.png');
  background-size: 93px;

  &>p {
    text-align: center;
    max-width: 90px;
    font-size: 11px;
    padding: 16px 5px;
  }

  @include respond-to(handhelds) {
    margin-left: auto;
  }

  @include respond-to(wide-handhelds) {
    margin-left: auto;
  }
}

.content-icon--right {
  background-image: url('/assets/images/handmade.png');
  background-size: 83px;
  text-align: center;

  &>p {
    font-size: 11px;
    padding: 21px 13px 0 0;
  }
}

.right-img {
  width: 40%;

  @include respond-to(phones) {
    width: 90%;
    margin: 0 auto;
  }

  @include respond-to(handhelds) {
    width: 100%;
  }

  @include respond-to(wide-handhelds) {
    width: 50% !important;
  }

  @include respond-to(desktop) {
    width: 50% !important;
  }

  @include respond-to(desktop-xl) {
    width: 50% !important;
  }
}

.right-img {
  position: relative;
}

.inside {
  color: white;
  padding: 10px 40px 10px 40px;
}

.inside h2 {
  text-align: center;
}

.question {
  display: flex;
  margin-top: 20px;
}

.question p {
  font-size: 48px;
  font-style: normal;
  font-weight: 100;
  margin-left: 40px;
  margin-top: 0px;
}

.question-num {
  font-size: 65px;
  font-style: normal;
  font-weight: 100;
}

.plus {
  font-size: 46px;
  font-weight: 100;
  margin-left: 469px;
  color: #e8bf1d;
}

.top__section-content {
  @include respond-to(desktop) {
    display: flex;
    justify-content: space-between;
    padding-top: 10rem;
    color: #211e4a;
  }

  @include respond-to(tablets) {
    display: flex;
    justify-content: space-between;
    padding-top: 10rem;
    color: #211e4a;
  }

  @include respond-to(wide-handhelds) {
    display: flex;
    justify-content: space-between;
    padding-top: 10rem;
    color: #211e4a;
  }

  @include respond-to(phones) {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
  }
}

.top__section-content img {
  @include respond-to(phones) {
    display: none;
  }
}

.hand-made {
  position: relative;

  @include respond-to(handhelds) {
    display: none;
  }

  @include respond-to(phones) {
    display: none;
  }

  @include respond-to(wide-handhelds) {
    display: initial !important;
  }

  @include respond-to(desktop) {
    display: initial !important;
  }

  @include respond-to(desktop-xl) {
    display: initial !important;
  }
}

.hand-made-img {
  height: 91px;
  width: 93px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 88px;
  position: absolute;
  bottom: 0;
  background-image: url('../assets/images/wine-trips.png');

  @include respond-to(phones) {
    top: 2px;
  }

  &>p {
    text-align: center;
    font-size: 11px;
    margin-right: 4px;
    margin-top: 30px;
  }
}

.wine-text {
  &>p {
    margin-bottom: 0;
    margin-top: 2px;
  }

  @include respond-to(phones) {
    p {
      margin-top: revert;
    }
  }

  @include respond-to(desktop-xl) {
    margin-left: -10% !important;
    font-size: 21px !important;
    font-weight: 100;
    max-width: 400px !important;
  }

  @include respond-to(desktop) {
    margin-left: -10% !important;
    font-size: 21px;
    font-weight: 100;
    max-width: 400px;
  }

  @include respond-to(wide-handhelds) {
    margin-left: -10%;
    max-width: 296px;
    font-size: 18px;
  }

  @include respond-to(handhelds) {
    max-width: 296px;
    font-size: 18px;
  }

  @include respond-to(phones) {
    text-align: left;
    padding-bottom: 2rem;
  }
}

.wine-text--sm {
  position: relative;

  @include respond-to(phones) {
    display: none;
  }

  @include respond-to(handhelds) {
    display: none;
  }

  @include respond-to(wide-handhelds) {
    display: initial;
  }

  @include respond-to(desktop) {
    display: initial;
  }

  @include respond-to(desktop-xl) {
    display: initial;
  }
}

.wine-text--sm p {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  width: 278px;

  @media screen and (max-width: 1360px) and (min-width: 1200px) {
    margin-right: -4vw;
  }

  @include respond-to(wide-handhelds) {
    right: -86px;
    width: 318px;
    font-size: 13px;
  }

  @include respond-to(handhelds) {
    right: 43px;
    width: 100px;
    font-size: 13px;
  }

  @include respond-to(desktop) {
    right: -62px;
    width: 390px;
    font-size: 14px;
  }

  a {
    text-decoration: none;
  }
}

.shop {
  display: none;

  @include respond-to(phones) {
    display: flex;
    padding: 20px 0 0 20px;
  }
}

.shop-btn {
  background-color: #e8bf1d;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 174px;
  height: 37px;
  color: #211e4a;
  cursor: pointer;
  border-radius: 20px;
  font-size: 13px;

  @include respond-to(phones) {
    position: initial;
  }
}

.about {
  margin-top: 50px;
  text-align: center;
}

.about-btn {
  background-color: #e8bf1d;
  cursor: pointer;
  border-radius: 20px;
  color: #211e4a;
}

.pad {
  padding: 0;

  @include respond-to(phones) {
    position: relative;
  }
}

.container-purple {
  background: #211e4a;
  border-radius: 10px;
  padding: 0;
}

.auth-block {
  max-width: 576px;
  margin: 0 auto;

  @include respond-to(tablets) {
    display: flex;
    max-width: none;
    margin: 0;
  }

  .auth-sub-block {
    margin: 0 0 15px;
    flex-grow: 1;
    text-align: center;
    padding-bottom: 24px;
    border-radius: 10px;
    background: #fff;

    @include respond-to(tablets) {
      flex-basis: 33%;
      margin-right: 15px;
      margin-bottom: 0;
    }

    &.bordered {
      border: 1.5px solid rgba(0, 0, 0, 0.1);
    }

    .auth-img {
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: center;
      background-position-y: bottom;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;

      &.estate {
        background-image: url('/assets/images/wine-estates.jpg');
      }

      &.jumper {
        background-image: url('/assets/images/winejumpers.jpg');
      }
    }

    .auth-block-content {
      margin-top: 32px;
      margin-bottom: 32px;

      .auth-col-title {
        font-size: 16px;
        font-weight: 700;
      }

      .auth-col-description {
        font-size: 16px;
        font-weight: 400;
      }
    }

    .auth-block-button {
      background-color: #b01515;
      padding: 18px 64px 18px 64px;
      text-decoration: none;
      color: white;
      text-align: center;
    }
  }
}

.login-section--home {
  margin-bottom: 60px;

  @include respond-to(desktop) {
    display: none;
  }
}

.stars-label {
  padding-left: 0 !important;
  display: inline;
  max-width: 110px;

  .stars {
    cursor: pointer;
    margin-left: 25px;
  }
}

.star-checkbox {
  display: flex;
  flex-direction: column;

  input[type='checkbox'] {
    width: 0px;
    opacity: 0;
  }
}

.stars-stub {
  height: 20px;
}

.simple-list {
  list-style-type: none;
}